<div class="row dvb-kinder-ort-schliesstage">
    <div class="col-md-7">
        <h2 data-translate="KINDERORT.SCHLIESSTAGE.ZUGEWIESENE"></h2>

        <p ng-if="vm.kinderOrtSchliesstage.length === 0"
           class="normal-abstand-bottom"
           data-translate="KINDERORT.SCHLIESSTAGE.KEINE_ZUWEISUNG">
        </p>

        <dvb-kinder-ort-schliesstage-verlauf kinder-ort-schliesstage="vm.kinderOrtSchliesstage"
                                             on-remove="vm.showConfirmDeleteSchliesstage(kinderOrtSchliesstage)"
                                             on-revert="vm.revertSchliesstage(kinderOrtSchliesstage)">
        </dvb-kinder-ort-schliesstage-verlauf>

        <div class="big-abstand-top" ng-if="vm.showNewForm">
            <h2 data-translate="KINDERORT.SCHLIESSTAGE.NEUE_ZUWEISUNG"></h2>
            <dvb-kinder-ort-schliesstage-form kinder-ort-schliesstage="vm.kinderOrtSchliesstageNeu"
                                              show-gueltig-ab="true"
                                              is-loading="vm.isLoading"
                                              on-submit="vm.saveSchliesstage(kinderOrtSchliesstage)"
                                              on-cancel="vm.hideForm()">
            </dvb-kinder-ort-schliesstage-form>
        </div>

        <div class="big-abstand-top"
             dvb-auth
             require-permission="{{'shared:schliesstage_manage:' + vm.kitaId}}"
             require-condition="!vm.showNewForm">
            <ul class="list-inline">
                <li>
                    <button type="button"
                            class="btn btn-primary"
                            ng-click="vm.neueSchliesstageDefinieren()"
                            data-translate="KINDERORT.SCHLIESSTAGE.NEUE_ZUWEISUNG">
                    </button>
                </li>
                <li ng-if="vm.kinderOrtSchliesstage.length > 0">
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.terminateSchliesstage()"
                            data-translate="KINDERORT.SCHLIESSTAGE.ZUWEISUNG_BEENDEN">
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
