/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kontingente} from '@dv/kitadmin/models';
import {Firma, FirmenKontingent, FirmenKontingentValue, ZeitraumFeld} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {BEGIN_OF_TIME, checkPresent, DayOfWeek, zeitraumComparator} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import {ZeitraumFeldClickModifier} from '../../../common/directive/dvb-wochenplan/ZeitraumFeldClickModifier';
import {WochenplanUtil} from '../../../common/service/wochenplanUtil';
import type {KibonExchangeModul} from '../../../kibon/models/KibonExchangeModul';
import {KibonExchangeModulIntervall} from '../../../kibon/models/KibonExchangeModul';
import {KibonExchangeModulAuswahl} from '../../../kibon/models/KibonExchangeModulAuswahl';
import type {KibonExchangeTagesschuleAnmeldung} from '../../../kibon/models/KibonExchangeTagesschuleAnmeldung';
import {KibonExchangeTagesschuleConfirmation} from '../../../kibon/models/KibonExchangeTagesschuleConfirmation';
import type {KibonExchangeTagesschulModule} from '../../../kibon/models/KibonExchangeTagesschulModule';
import type {TagesschuleAnmeldungService} from '../../../kibon/service/tagesschuleAnmeldungService';
import {ZuweisungPopoverHelper} from '../zuweisung-popover-helper';
import ClickEvent = JQuery.ClickEvent;

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anmeldung: '<',
        readOnly: '<?',
    },
    template: require('./dvb-tagesschul-anmeldung-confirmation.html'),
    controllerAs: 'vm',
};

export class DvbTagesschulAnmeldungConfirmation implements angular.IController {
    public static $inject: readonly string[] = [
        'tagesschuleAnmeldungService',
        'dialogService',
        '$document',
        '$scope',
        'errorService',
        '$timeout',
        '$translate',
    ];

    public anmeldung!: KibonExchangeTagesschuleAnmeldung;
    public readOnly?: boolean;

    public tagesschulModule: KibonExchangeTagesschulModule | null = null;
    public sortedModule: KibonExchangeModul[] = [];

    public weekDays: DayOfWeek[] = Object.values(DayOfWeek);
    public zeitraumFelder: ZeitraumFeld[] = [];
    public editedZeitraumFelder: ZeitraumFeld[] = [];

    public weeklyKontingent: FirmenKontingent;
    public alternatingKontingent: FirmenKontingent;

    public workingCopy: KibonExchangeTagesschuleConfirmation | null = null;

    public isLoading: boolean = false;
    public editing = false;
    public confirmed: boolean = false;

    private popoverHelper!: ZuweisungPopoverHelper;
    private lastSelectedIntervall: KibonExchangeModulIntervall | null = null;

    private moduleInitPromise?: angular.IPromise<unknown>;

    public constructor(
        private readonly tagesschuleAnmeldungService: TagesschuleAnmeldungService,
        private readonly dialogService: DialogService,
        private readonly $document: angular.IDocumentService,
        private readonly $scope: angular.IScope,
        private readonly errorService: ErrorService,
        private readonly $timeout: angular.ITimeoutService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
        this.weeklyKontingent = this.initFirmenKontingent(
            'KIBON.TAGESSCHULE_ANMELDUNG.INTERVALL',
            'KIBON.TAGESSCHULE_ANMELDUNG.WEEKLY',
            '');
        this.alternatingKontingent = this.initFirmenKontingent(
            'KIBON.TAGESSCHULE_ANMELDUNG.INTERVALL',
            'KIBON.TAGESSCHULE_ANMELDUNG.ALTERNATING_MODULE',
            '2W');
    }

    public $onInit(): void {
        this.moduleInitPromise = this.$timeout(() => this.initModule().then(() => {
            this.popoverHelper = new ZuweisungPopoverHelper(this.$document, this.$scope, 'confirmationPopover');
        }));
    }

    public $onDestroy(): void {
        if (this.moduleInitPromise) {
            this.$timeout.cancel(this.moduleInitPromise);
        }
        this.popoverHelper?.destroy();
    }

    public onFeldClicked(
        zeitraumFeld: ZeitraumFeld<KibonExchangeModul>,
        event: ClickEvent,
        modifier: ZeitraumFeldClickModifier,
    ): void {
        if (modifier === ZeitraumFeldClickModifier.LEFT) {
            this.onFeldLeftClicked(zeitraumFeld, event);
        } else {
            this.onFeldRightClicked(zeitraumFeld, event);
        }
    }

    public setKontingent(kontingent: FirmenKontingent, zeitraumFeld?: ZeitraumFeld): void {
        const selectedFeld = this.selectFeldAndClosePopover(kontingent, zeitraumFeld);
        WochenplanUtil.setFirma(selectedFeld, kontingent);
    }

    public confirmAnmeldung(): void {

        this.dialogService.openConfirmDialog({
            title: 'KIBON.TAGESSCHULE_ANMELDUNG.CONFIRM',
            confirmActionText: 'KIBON.TAGESSCHULE_ANMELDUNG.BESTAETIGEN',
            confirm: () => (this.editing ? this.confirmModified$() : this.confirmUnmodified$())
                .pipe(tap(() => {
                    this.errorService.handleSuccess('KIBON.TAGESSCHULE_ANMELDUNG.CONFIRM_SUCCESS');
                    this.confirmed = true;
                })),
        });
    }

    private confirmModified$(): Observable<unknown> {
        const workingCopy = checkPresent(this.workingCopy);
        workingCopy.modulAuswahl = [];
        this.editedZeitraumFelder.filter(zf => zf.selected)
            .forEach(zf => {
                const interval = zf.kontingent === this.alternatingKontingent ?
                    KibonExchangeModulIntervall.ALLE_ZWEI_WOCHEN :
                    KibonExchangeModulIntervall.WOECHENTLICH;

                const modulAuswahl = new KibonExchangeModulAuswahl(
                    zf.zeitraum.id!,
                    null,
                    zf.dayOfWeek,
                    interval);

                workingCopy.modulAuswahl.push(modulAuswahl);
            });

        return from(this.tagesschuleAnmeldungService.confirm(workingCopy)).pipe(take(1));
    }

    private confirmUnmodified$(): Observable<unknown> {
        // only send the required values
        const confirmation = new KibonExchangeTagesschuleConfirmation(this.anmeldung.id, this.anmeldung.refNr);
        confirmation.unmodified = true;

        return from(this.tagesschuleAnmeldungService.confirm(confirmation)).pipe(take(1));
    }

    private initModule(): angular.IPromise<void> {
        this.workingCopy = KibonExchangeTagesschuleConfirmation.fromAnmeldung(this.anmeldung);
        this.workingCopy.unmodified = false;

        const params = {gueltigAb: this.anmeldung.gueltigAb};

        return this.tagesschuleAnmeldungService.getModule(this.anmeldung.institution.externalId!, params)
            .then(result => {
                this.tagesschulModule = result;
                this.initZeitraumFelder();
            });
    }

    private initZeitraumFelder(): void {
        this.sortedModule = checkPresent(this.tagesschulModule).module;
        this.sortedModule.sort(zeitraumComparator);
        this.zeitraumFelder = this.weekDays.flatMap(day => {
            return this.sortedModule.filter(modul => modul.wochentage.includes(day))
                .map(modul => new ZeitraumFeld(modul, day));
        });

        this.anmeldung.modulAuswahl.forEach(auswahl => {
            this.zeitraumFelder.filter(zf => auswahl.wochentag === zf.dayOfWeek && auswahl.modulId === zf.zeitraum.id!)
                .forEach(zf => {
                    zf.selected = true;
                    if (KibonExchangeModulIntervall.ALLE_ZWEI_WOCHEN === auswahl.intervall) {
                        WochenplanUtil.setFirma(zf, this.alternatingKontingent);
                    }
                });
        });

        // create copies of the zeitraum felder to work on
        this.editedZeitraumFelder = this.zeitraumFelder.map(zf => {
            const copy = new ZeitraumFeld(zf.zeitraum, zf.dayOfWeek);
            copy.selected = zf.selected;
            copy.kontingent = zf.kontingent;
            copy.value = zf.value;

            return copy;
        });
    }

    private initFirmenKontingent(name: string, kontingent: string, icon: string): FirmenKontingent {
        const alternatingKontingent = new FirmenKontingent();
        const alternatingFirma = new Firma();
        alternatingFirma.symbol = icon;
        alternatingFirma.name = this.$translate.instant(name);
        alternatingKontingent.firma = alternatingFirma;
        alternatingKontingent.name = this.$translate.instant(kontingent);

        // value with kitaId is needed by popoverHelper
        const kontingentValue = new FirmenKontingentValue();
        kontingentValue.gueltigAb = BEGIN_OF_TIME;
        kontingentValue.kitasIds = ['1'];
        alternatingKontingent.values.push(kontingentValue);

        return alternatingKontingent;
    }

    private onFeldLeftClicked(zeitraumFeld: ZeitraumFeld<KibonExchangeModul>, event: ClickEvent): void {
        event.preventDefault();
        // remove selection
        if (!zeitraumFeld.selected) {
            zeitraumFeld.active = false;
            zeitraumFeld.value = null;

            return;
        }

        if (this.setKontingentBasedOnOnlyOption(zeitraumFeld)) {
            return;
        }

        // multiple options -> use popover
        zeitraumFeld.selected = !zeitraumFeld.selected;
        event.stopPropagation();

        this.popoverHelper.popoverZuweisungContent.zeitraumFeld = zeitraumFeld;

        const parentElement = angular.element(event.target).closest('.feld');
        this.popoverHelper.open(parentElement);
    }

    private onFeldRightClicked(zeitraumFeld: ZeitraumFeld<KibonExchangeModul>, event: ClickEvent): void {
        event.preventDefault();
        if (!zeitraumFeld.selected) {
            zeitraumFeld.active = false;
            zeitraumFeld.value = null;

            return;
        }

        // cancel automatic selection
        zeitraumFeld.selected = !zeitraumFeld.selected;

        if (this.setKontingentBasedOnOnlyOption(zeitraumFeld)) {
            return;
        }

        this.popoverHelper.popoverZuweisungContent.zeitraumFeld = zeitraumFeld;
        if (this.lastSelectedIntervall
            && zeitraumFeld.zeitraum.erlaubteIntervalle.includes(this.lastSelectedIntervall)) {
            this.setKontingentBasedOnIntervall(this.lastSelectedIntervall, zeitraumFeld);
        } else {
            throw new Error('There are intervall types that this implementation is not aware of.');
        }
    }

    /**
     * Close the popover, store and mark the field as selected.
     */
    private selectFeldAndClosePopover(kontingent: Kontingente | null, zeitraumFeld?: ZeitraumFeld): ZeitraumFeld {
        this.lastSelectedIntervall = kontingent === this.weeklyKontingent ?
            KibonExchangeModulIntervall.WOECHENTLICH :
            KibonExchangeModulIntervall.ALLE_ZWEI_WOCHEN;
        this.popoverHelper.cancelPopover();

        const selectedFeld = zeitraumFeld ?? checkPresent(this.popoverHelper.popoverZuweisungContent.zeitraumFeld);
        selectedFeld.backup = angular.copy(zeitraumFeld);
        selectedFeld.selected = true;

        return selectedFeld;
    }

    /**
     * @return If there is a single option, selects that and returns true. false otherwise.
     */
    private setKontingentBasedOnOnlyOption(zeitraumFeld: ZeitraumFeld<KibonExchangeModul>): boolean {

        const intervalle = zeitraumFeld.zeitraum.erlaubteIntervalle;
        if (intervalle.length === 1) {
            this.setKontingentBasedOnIntervall(intervalle[0], zeitraumFeld);

            return true;
        }

        return false;
    }

    private setKontingentBasedOnIntervall(intervall: KibonExchangeModulIntervall, zeitraumFeld: ZeitraumFeld): void {
        switch (intervall) {
            case KibonExchangeModulIntervall.WOECHENTLICH:
                this.setKontingent(this.weeklyKontingent, zeitraumFeld);
                break;
            case KibonExchangeModulIntervall.ALLE_ZWEI_WOCHEN:
                this.setKontingent(this.alternatingKontingent, zeitraumFeld);
                break;
            default:
                throw new Error('Intervall not implemented');
        }
    }
}

componentConfig.controller = DvbTagesschulAnmeldungConfirmation;
angular.module('kitAdmin').component('dvbTagesschulAnmeldungConfirmation', componentConfig);
