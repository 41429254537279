/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import type {TarifParameter} from '@dv/kitadmin/models';
import {DisplayNamePipe, SortListComponent, SortListTemplateDirective} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TarifParameterBadge} from './tarif-parameter-badge.pipe';

@Component({
    selector: 'dv-tarif-parameter-sort',
    templateUrl: './tarif-parameter-sort.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SortListComponent,
        TranslocoModule,
        TooltipModule,
        DisplayNamePipe,
        TarifParameterBadge,
        SortListTemplateDirective,
    ],
})
export class TarifParameterSortComponent {

    @Input() public items: TarifParameter[] = [];
    @Output() public readonly itemsChange = new EventEmitter<TarifParameter[]>();
}
