/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {BaseRechnungsPosition, MandantCustomPosition, MandantRechnungConfigPosition} from '@dv/kitadmin/models';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {DvbUtil, FormContext} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {FormPosition} from './form-position';

export type SupportedTypes = 'mandantRechnungConfigPosition' | 'mandantCustomPosition';
const constructorByType: Record<SupportedTypes, () => BaseRechnungsPosition> = {
    mandantRechnungConfigPosition: () => new MandantRechnungConfigPosition(),
    mandantCustomPosition: () => new MandantCustomPosition(),
};

@Component({
    selector: 'dv-administration-custom-rechnung-position-form',
    imports: [
        FormsModule,
        TranslocoModule,
        TooltipModule,
        SubmitCancelButtonsComponent,
        DecimalPipe,
    ],
    templateUrl: './administration-custom-rechnung-position-form.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationCustomRechnungPositionFormComponent {

    @Input({required: true, alias: 'formContext'})
    public set _formContext(value: FormContext) {
        this.formContext.set(value);
    }

    @Input({required: true, alias: 'existingCustomPositionen'})
    public set _existingCustomPositionen(value: BaseRechnungsPosition[]) {
        this.customPositions = value.map(e => new FormPosition(e));
    }

    @Input({required: true}) public positionType!: SupportedTypes;

    public formContext = signal<FormContext>(new FormContext());

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public readonly onUpdate = new EventEmitter<{
        customPositionen: FormPosition[];
        formContext: FormContext;
    }>();

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public readonly onCancel = new EventEmitter<void>();

    private readonly errorService = inject(ErrorService);

    public customPositions: FormPosition[] = [];

    public submit(form: NgForm): void {
        const isValid = this.customPositions.every(p => p.isValid())
            && !!form.valid;

        this.errorService.handleValidationError(isValid, 'ADMINISTRATION.ERR_INVALID_CUSTOM_POSITION');

        if (!isValid) {
            return;
        }

        this.onUpdate.emit({customPositionen: this.customPositions, formContext: this.formContext()});
    }

    public removePosition(position: BaseRechnungsPosition): void {
        DvbUtil.removeFromArray(position, this.customPositions);
    }

    public addPosition(): void {
        const position = new FormPosition(constructorByType[this.positionType]());
        this.customPositions.push(position);
    }
}
