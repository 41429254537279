/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, input, output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SearchListComponent} from '@dv/kitadmin/search';
import {WorkTimeModelDisplayNamePipe} from '@dv/kitadmin/work-time-controlling/mandant';
import {DvMinutesFormatPipe, formViewProvider, RequirePermissionStructuralDirective} from '@dv/shared/angular';
import {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import {DvbDateUtil, isNullish, isPresent, nextId} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AnstellungStandortFormModel} from '../../../models/anstellung-form-model';
import {
    AngestellteAnstellungExpectedTimeComponent,
} from '../angestellte-anstellung-expected-time/angestellte-anstellung-expected-time.component';
import {
    AngestellteAnstellungZeitenComponent,
} from '../angestellte-anstellung-zeiten/angestellte-anstellung-zeiten.component';

const ONE_HUNDRED = 100;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-anstellung-standort-form',
    imports: [
        TooltipModule,
        FormsModule,
        SearchListComponent,
        TranslocoModule,
        AngestellteAnstellungZeitenComponent,
        RequirePermissionStructuralDirective,
        AngestellteAnstellungExpectedTimeComponent,
        DvMinutesFormatPipe,
        WorkTimeModelDisplayNamePipe,
    ],
    templateUrl: './anstellung-standort-form.component.html',
    viewProviders: [formViewProvider],
})
export class AnstellungStandortFormComponent {
    public anstellungStandort = input.required<AnstellungStandortFormModel>();
    public workTimeModels = input.required<JaxReadWorkTimeModel[]>();

    public readonly autofillExpectedTime = output<number | null>();

    public readonly today = DvbDateUtil.today();
    public readonly workTimeModelUiId = nextId();
    public readonly pensumProzentUiId = nextId();

    private selectedWorkTimeModel = computed(() => this.anstellungStandort().workTimeModel());

    public expectedTotalWeeklyMinutes = computed(() => {
        const selectedWorkTimeModel = this.selectedWorkTimeModel();
        const arbeitspensumProzent = this.anstellungStandort().arbeitspensumProzent();

        if (isNullish(selectedWorkTimeModel) || isNullish(arbeitspensumProzent)) {
            return null;
        }

        return (selectedWorkTimeModel.weeklyMinutes * arbeitspensumProzent) / ONE_HUNDRED;
    });

    public showExpectedMinutes = computed(() => {
        const selectedWorkTimeModel = this.selectedWorkTimeModel();

        return isNullish(selectedWorkTimeModel) || !selectedWorkTimeModel.stundenLohn;
    });

    public requirePensumProzent = computed(() => {
        const workTimeModel = this.selectedWorkTimeModel();

        return isPresent(workTimeModel) && !workTimeModel.stundenLohn;
    });
}
