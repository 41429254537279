/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BankStatementEntry} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import {TranslocoModule} from '@jsverse/transloco';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

export interface BankStatementEntryZuweisungLoeschenDialogModel
    extends CompletableDialogModel<BankStatementEntryStatus> {
    entry: BankStatementEntry;
}

@Component({
    selector: 'dv-bank-statement-entry-zuweisung-loeschen',
    templateUrl: './bank-statement-entry-zuweisung-loeschen.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // approximates height of dropdwown menu
    styles: ':host {display: block; padding: 100px 0;}',
    imports: [
        CommonModule,
        TranslocoModule,
        BsDropdownModule,
        LoadingButtonComponent,
        ButtonListComponent,
    ],
})
export class BankStatementEntryZuweisungLoeschenComponent extends CompletableDialogBase<BankStatementEntryStatus>
    implements BankStatementEntryZuweisungLoeschenDialogModel {

    @Input() public entry!: BankStatementEntry;

    public bankStatementEntryStatus: typeof BankStatementEntryStatus = BankStatementEntryStatus;
}
