<div class="timeline-event"
     [ngClass]="cssClasses()"
     [ngStyle]="{background: event.backgroundColor, 'outline': event.outline}"
     *transloco="let t">
    <span class="resize-left"
          #resizeLeft
          #resizeLeftTooltip="bs-tooltip"
          [style.pointer-events]="pointerEvents()"
          [tooltip]="tooltipLeft"
          triggers=""
          placement="top"
          [container]="'body'"
          [adaptivePosition]="false"
          (mousedown)="resizeLeft.classList.add('active')"
          (mouseup)="resizeLeft.classList.remove('active')">
    </span>
    <ng-template #tooltipLeft>{{ event.von | dvTimeFormat }}</ng-template>
    <div class="content truncate"
         #moveItem
         dvOverlay
         [overlayOpen]="editOverlayOpened"
         [overlayTemplate]="editOverlayTemplate"
         (closeOverlay)="closeEditOverlay.emit()"
         [style.cursor]="moveHandleCursor()"
         [tooltip]="tooltipEnabled() ? tooltipMove : undefined">
        <span class="event-title" [ngStyle]="{color: event.textColor}">{{ event | displayName }}</span>
        <div class="hover-menu" [ngClass]="{'high-luminance': event.hasHighLuminance}">
            @if (editable()) {
                <button
                    dvlibButton="bare"
                    icon="edit"
                    [attr.aria-label]="t('COMMON.EDIT')"
                    (click)="editEvent.emit({element: moveItem, event})">
                </button>
            }
            @if (deletable()) {
                <button
                    dvlibButton="bare"
                    icon="trash"
                    [attr.aria-label]="t('COMMON.DELETE')"
                    (click)="deleteEvent.emit(event)">
                </button>
            }
        </div>
    </div>
    <ng-template #tooltipMove>{{ event.tooltip || '' }}</ng-template>

    <span class="resize-right pull-right"
          #resizeRight
          #resizeRightTooltip="bs-tooltip"
          [style.pointer-events]="pointerEvents()"
          [tooltip]="tooltipRight"
          triggers=""
          placement="top"
          [container]="'body'"
          [adaptivePosition]="false"
          (mousedown)="resizeRight.classList.add('active')"
          (mouseup)="resizeRight.classList.remove('active')">
    </span>
    <ng-template #tooltipRight>{{ event.bis | dvTimeFormat }}</ng-template>
</div>
