/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {FakturaMassenaktionError} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import {UIRouterModule} from '@uirouter/angular';

export interface KitaFakturaMassenaktionErrorDialogModel extends CompletableDialogModel<null> {
    errors: FakturaMassenaktionError[];
}

@Component({
    selector: 'dv-kita-faktura-massenaktion-error',
    templateUrl: './kita-faktura-massenaktion-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslocoModule,
        UIRouterModule,
        ButtonListComponent,
    ],
})
export class KitaFakturaMassenaktionErrorComponent extends CompletableDialogBase<null>
    implements KitaFakturaMassenaktionErrorDialogModel {

    @Input() public errors!: FakturaMassenaktionError[];
}
