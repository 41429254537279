import type {JaxPersonalSortOrder} from '@dv/shared/backend/model/jax-personal-sort-order';
import {displayableComparator, isNullish} from '@dv/shared/code';
import type {AngestellteZuweisungen} from '../../../../../personal/model/AngestellteZuweisungen';

export function sortAngestellte(
    a: AngestellteZuweisungen,
    b: AngestellteZuweisungen,
    sortOrder: JaxPersonalSortOrder[],
): number {

    if (isNullish(sortOrder) || sortOrder.length === 0) {
        return displayableComparator(a, b);
    }

    const orderA = findOrderValue(a, sortOrder);
    const orderB = findOrderValue(b, sortOrder);

    if (isNullish(orderA) && isNullish(orderB)) {
        return displayableComparator(a, b);
    }
    if (isNullish(orderA)) {
        return 1;
    }
    if (isNullish(orderB)) {
        return -1;
    }

    const result = orderA - orderB;

    return result === 0 ? displayableComparator(a, b) : result;
}

function findOrderValue(zuweisungen: AngestellteZuweisungen, sortOrder: JaxPersonalSortOrder[]): number | undefined {
    return sortOrder.find(order => order.angestellteId === zuweisungen.angestellte.id)?.orderValue;
}
