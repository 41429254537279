/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ITimeRange, Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, isPresent, TimeRangeUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {Dienst} from '../../../../personal/konfiguration/Dienst';
import type {ZuweisungPause} from '../../../../personal/model/ZuweisungPause';
import type {ZuweisungZeit} from '../../../../personal/model/ZuweisungZeit';

interface ComparablePause {
    duration: number;
    paid: boolean;
}

function pauseComparator(a: ComparablePause, b: ComparablePause): number {
    const durationComparison = a.duration - b.duration;
    const paidComparison = a.paid === b.paid ? 0 : a.paid ? 1 : -1;

    return durationComparison === 0 ? paidComparison : durationComparison;
}

export function findMatchingDienst(
    dienste: Persisted<Dienst>[],
    range: ITimeRange,
    zuweisungPausen: ZuweisungPause[],
): Persisted<Dienst> | undefined {
    const pausen = zuweisungPausen.filter(pause => TimeRangeUtil.contains(range, pause));

    return dienste.find(dienst => TimeRangeUtil.isSame(dienst, range) && matchesPausen(dienst, pausen));
}

function matchesPausen(dienst: Persisted<Dienst>, pausen: ZuweisungPause[]): boolean {
    const dienstPausen = dienst.pausen;

    if (dienstPausen.length !== pausen.length) {
        return false;
    }

    if (dienstPausen.length === 0 && pausen.length === 0) {
        return true;
    }

    const compPausen = toComparablePausen(pausen);
    const compDienstPausen = toComparablePausen(dienstPausen);
    for (let i = 0; i < pausen.length; i++) {
        if (pauseComparator(compPausen[i], compDienstPausen[i]) !== 0) {
            return false;
        }
    }

    return true;
}

function toComparablePausen(pausen: {
    von: moment.Moment | null;
    bis: moment.Moment | null;
    paid: boolean;
}[]): ComparablePause[] {
    return pausen.map(p => {
        return {
            duration: DvbDateUtil.getTimeDiff(checkPresent(p.von), checkPresent(p.bis)),
            paid: p.paid,
        };
    }).sort(pauseComparator);
}

export function findDienst(
    zuweisungZeit: ZuweisungZeit,
    zuweisungPausen: ZuweisungPause[],
    dienste: Persisted<Dienst>[],
    otherFraktionZeiten: ITimeRange[],
    otherFraktionPausen: ZuweisungPause[],
): Persisted<Dienst> | undefined {
    const dienst = findMatchingDienst(dienste, zuweisungZeit, zuweisungPausen);
    if (isPresent(dienst)) {
        return dienst;
    }

    // attempt to use dienst from otherFraktionZeiten
    const fraktionZeit = otherFraktionZeiten.find(zeit => TimeRangeUtil.contains(zeit, zuweisungZeit));

    return isPresent(fraktionZeit) ? findMatchingDienst(dienste, fraktionZeit, otherFraktionPausen) : undefined;
}
