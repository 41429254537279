/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {NonNullableFormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SearchEntityComponent} from '@dv/kitadmin/search';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import {JaxKindCheckInEvent} from '@dv/shared/backend/model/jax-kind-check-in-event';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

export type TagesinfoSaveEvent = {
    time: BackendLocalTimeHHMM;
    kinderOrt?: SearchResultEntry;
};

@Component({
    selector: 'dv-tagesinfo-form',
    imports: [
        ReactiveFormsModule,
        SubmitCancelButtonsComponent,
        TranslocoModule,
        SearchEntityComponent,
        TooltipModule,
    ],
    templateUrl: './tagesinfo-form.component.html',
    styles: ':host {display: block;}',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagesinfoFormComponent implements OnChanges {
    private errorService = inject(ErrorService);
    private fb = inject(NonNullableFormBuilder);

    @Input() public event?: JaxKindCheckInEvent;
    @Input() public title?: string;
    @Input() public isLoading?: boolean;

    @Output() public readonly save: EventEmitter<TagesinfoSaveEvent> = new EventEmitter();
    @Output() public readonly cancelEdit: EventEmitter<void> = new EventEmitter();

    public form = this.fb.group({
        kinderOrt: this.fb.control<SearchResultEntry | undefined>(undefined, Validators.required),
        time: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
    });

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.event) {
            return;
        }

        const event: JaxKindCheckInEvent = changes.event.currentValue;
        const time = DvbRestUtil.momentTolocaleHHMMTime(
            DvbRestUtil.localDateTimeToMoment(event.eventTimestamp),
        );
        this.form.controls.kinderOrt.removeValidators(Validators.required);
        this.form.patchValue({time});
    }

    public submitForm(): void {
        this.errorService.clearAll();
        if (!this.form.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const {time, kinderOrt} = this.form.value;

        this.save.emit({
            time: time!,
            kinderOrt,
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancelEdit.emit();
    }
}
