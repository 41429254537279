/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    BetreuungsfaktorRegel,
    CustomField,
    IRechnungsKonfiguration,
    KinderOrt,
    KitaRechnungsKonfiguration,
    Mandant,
    Tarif,
    Wochenplan,
} from '@dv/kitadmin/models';
import {RechnungsStatus} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';
import {NamedEntityType} from '@dv/shared/code';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition, UIRouter} from '@uirouter/core';
import type {IPromise} from 'angular';
import angular from 'angular';
import type moment from 'moment';
import {trans} from '../common/i18n/trans';
import type {CustomFieldService} from '../common/service/rest/customFieldService';
import type {KinderOrtService} from '../common/service/rest/kinderort/kinderOrtService';
import type {KitaFakturaService} from '../common/service/rest/kinderort/kitaFakturaService';
import type {MandantService} from '../common/service/rest/mandantService';
import type {TarifService} from '../common/service/rest/tarifService';
import type {WochenplanService} from '../common/service/rest/wochenplanService';
import {RechnungenFilter} from '../filter/RechnungenFilter';
import type {Schliesstage} from '../schliesstage/models/Schliesstage';
import type {SchliesstageService} from '../schliesstage/service/schliesstageService';
import {KINDERORT_EXTERNAL_ANMELDUNG_STATES} from './kinderort-external-anmeldung-states';
import {KINDERORT_PERSONAL_STATES} from './kinderort-personal-states';
import {KINDERORT_SETTING_STATES} from './kinderort-settings-states';

angular.module('kitAdmin').config(kitaConfig);

/* eslint-disable max-len */

export * from './partial/kita-main/kita-main';
export * from './partial/kita-tarife/kita-tarife';

export * from './directive/dvb-kinder-liste/dvb-kinder-liste';
export * from './directive/tarife/dvb-kita-tarife-auswahl/dvb-kita-tarife-auswahl';
export * from './directive/tarife/dvb-kita-tarife-verlauf-content/dvb-kita-tarife-verlauf-content';
export * from './directive/tarife/dvb-kita-tarife-verlauf-header/dvb-kita-tarife-verlauf-header';
export * from './directive/tarife/dvb-kita-tarife-verlauf/dvb-kita-tarife-verlauf';

export *
    from './component/betreuungsfaktor-regel/dvb-kita-betreuungsfaktor-regel-auswahl/dvb-kita-betreuungsfaktor-regel-auswahl';
export *
    from './component/betreuungsfaktor-regel/dvb-kita-betreuungsfaktor-regel-content/dvb-kita-betreuungsfaktor-regel-content';
export *
    from './component/betreuungsfaktor-regel/dvb-kita-betreuungsfaktor-regel-form/dvb-kita-betreuungsfaktor-regel-form';
export *
    from './component/betreuungsfaktor-regel/dvb-kita-betreuungsfaktor-regel-heading/dvb-kita-betreuungsfaktor-regel-heading';
export *
    from './component/betreuungsfaktor-regel/dvb-kita-betreuungsfaktor-regel-verlauf/dvb-kita-betreuungsfaktor-regel-verlauf';
export * from './component/betreuungsfaktor-regel/dvb-kita-betreuungsfaktor-regeln/dvb-kita-betreuungsfaktor-regeln';
export * from './component/dvb-belegungsplan-filter/dvb-belegungsplan-filter';
export * from './component/dvb-filter-main/dvb-filter-main';
export * from './component/dvb-kinder-ort-external-anmeldungen/dvb-kinder-ort-external-anmeldungen';
export * from './component/dvb-kinder-ort-lohn/dvb-kinder-ort-lohn';
export * from './component/personal/dvb-kinderort-personal-konfiguration/dvb-kinderort-personal-konfiguration';
export *
    from './component/dvb-kita-betreuungs-vereinbarungs-konfiguration/dvb-kita-betreuungs-vereinbarungs-konfiguration';
export *
    from './component/dvb-kita-betreuungs-vereinbarungs-konfiguration-creation/dvb-kita-betreuungs-vereinbarungs-konfiguration-creation';
export * from './component/dvb-kita-kinder/BelegungStrategy';
export * from './component/dvb-kita-kinder/BewerbungStrategy';
export * from './component/dvb-kita-kinder/dvb-kita-kinder';
export * from './component/dvb-kita-vereinbarungs-konfigurationen/dvb-kita-vereinbarungs-konfigurationen';
export * from './component/dvb-stunden-kontingent-content/dvb-stunden-kontingent-content';
export * from './component/dvb-stunden-kontingent-form/dvb-stunden-kontingent-form';
export * from './component/dvb-stunden-kontingent-history/dvb-stunden-kontingent-history';
export * from './component/dvb-stunden-kontingent-title/dvb-stunden-kontingent-title';
export * from './component/eroeffnung/dvb-kinderort-eroeffnen/dvb-kinderort-eroeffnen';
export * from './component/eroeffnung/dvb-kita-eroeffnen/dvb-kita-eroeffnen';
export * from './component/eroeffnung/dvb-tageseltern-eroeffnen/dvb-tageseltern-eroeffnen';
export * from './component/faktura/dvb-email-versand-form/dvb-email-versand-form';
export * from './component/faktura/dvb-kita-faktura-header/dvb-kita-faktura-header';
export * from './component/faktura/dvb-kita-faktura-massenaktionen/dvb-kita-faktura-massenaktionen';
export * from './component/faktura/dvb-kita-faktura-rechnungen-frist-filter/dvb-kita-faktura-rechnungen-frist-filter';
export * from './component/faktura/dvb-kita-faktura-rechnungen-status-filter/dvb-kita-faktura-rechnungen-status-filter';
export * from './component/faktura/dvb-kita-faktura/dvb-kita-faktura';
export * from './component/faktura/dvb-kita-offene-posten/dvb-kita-offene-posten';
export * from './component/faktura/konfiguration/dvb-kita-faktura-dvb-content/dvb-kita-faktura-dvb-content';
export *
    from './component/faktura/konfiguration/dvb-kita-faktura-dvb-konfiguration-form/dvb-kita-faktura-dvb-konfiguration-form';
export *
    from './component/faktura/konfiguration/dvb-kita-faktura-konfiguration-content/dvb-kita-faktura-konfiguration-content';
export *
    from './component/faktura/konfiguration/dvb-kita-faktura-konfiguration-form/dvb-kita-faktura-konfiguration-form';
export *
    from './component/faktura/konfiguration/dvb-kita-faktura-konfiguration-heading/dvb-kita-faktura-konfiguration-heading';
export * from './component/faktura/konfiguration/dvb-kita-faktura-konfigurationen/dvb-kita-faktura-konfigurationen';
export *
    from './component/faktura/konfiguration/dvb-kita-faktura-small-invoice-content/dvb-kita-faktura-small-invoice-content';
export *
    from './component/faktura/konfiguration/dvb-kita-faktura-small-invoice-konfiguration-form/dvb-kita-faktura-small-invoice-konfiguration-form';
export * from './component/faktura/konfiguration/dvb-kita-faktura-texte-content/dvb-kita-faktura-texte-content';
export * from './component/faktura/konfiguration/dvb-kita-faktura-texte-form/dvb-kita-faktura-texte-form';
export * from './component/faktura/mahnlauf/dvb-kita-faktura-mahnlauf/dvb-kita-faktura-mahnlauf';
export * from './component/faktura/mahnlauf/dvb-kita-mahnlauf/dvb-kita-mahnlauf';
export * from './component/faktura/rechnungslauf/dvb-kita-faktura-rechnungslauf/dvb-kita-faktura-rechnungslauf';
export * from './component/faktura/rechnungslauf/dvb-kita-rechnungslauf-erstellen/dvb-kita-rechnungslauf-erstellen';
export *
    from './component/faktura/rechnungslauf/dvb-kita-rechnungslauf-finish-entry/dvb-kita-rechnungslauf-finish-entry';
export *
    from './component/faktura/rechnungslauf/dvb-kita-rechnungslauf-finish-kontaktperson-entry/dvb-kita-rechnungslauf-finish-kontaktperson-entry';
export * from './component/faktura/rechnungslauf/dvb-kita-rechnungslauf-finish/dvb-kita-rechnungslauf-finish';
export * from './component/faktura/rechnungslauf/dvb-kita-rechnungslauf/dvb-kita-rechnungslauf';
export * from './component/profil/dvb-kinderort-anwesenheit-settings/dvb-kinderort-anwesenheit-settings';
export * from './component/profil/dvb-kinderort-firmen/dvb-kinderort-firmen';
export * from './component/profil/dvb-kinderort-kapazitaeten/dvb-kinderort-kapazitaeten';
export * from './component/profil/dvb-kinderort-kontakt/dvb-kinderort-kontakt';
export * from './component/profil/dvb-kinderort-profil/dvb-kinderort-profil';
export * from './component/profil/dvb-plaetze-verlauf/dvb-plaetze-verlauf';
export * from './component/schliesstage/dvb-kinder-ort-schliesstage/dvb-kinder-ort-schliesstage';
export * from './component/schliesstage/dvb-kinder-ort-schliesstage-content/dvb-kinder-ort-schliesstage-content';
export * from './component/schliesstage/dvb-kinder-ort-schliesstage-form/dvb-kinder-ort-schliesstage-form';
export * from './component/schliesstage/dvb-kinder-ort-schliesstage-heading/dvb-kinder-ort-schliesstage-heading';
export * from './component/schliesstage/dvb-kinder-ort-schliesstage-verlauf/dvb-kinder-ort-schliesstage-verlauf';
export * from './component/tarife/dvb-kita-tarif-zuweisen/dvb-kita-tarif-zuweisen';
export * from './component/uebersicht/dvb-display-controlling-change/dvb-display-controlling-change';
export * from './component/uebersicht/dvb-kita-chart/dvb-kita-chart';
export * from './component/uebersicht/dvb-kita-chart-personal/dvb-kita-chart-personal';
export * from './component/uebersicht/dvb-kita-chart-stunden/dvb-kita-chart-stunden';
export * from './component/uebersicht/dvb-kita-kinder-aenderungen/dvb-kita-kinder-aenderungen';
export * from './component/uebersicht/dvb-kita-uebersicht/dvb-kita-uebersicht';
export * from './component/uebersicht/dvb-kita-uebersicht-betreung/dvb-kita-uebersicht-betreung';
export * from './component/uebersicht/dvb-kita-uebersicht-personal/dvb-kita-uebersicht-personal';
export * from './component/uebersicht/dvb-kita-uebersicht-stunden/dvb-kita-uebersicht-stunden';
// Add New Component JS Above

kitaConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function kitaConfig($stateProvider: StateProvider, $uiRouterProvider: UIRouter): void {

    $stateProvider.state('base.kinderort-erstellen', {
        url: '/neuer-kinderort',
        template: '<dvb-kinderort-eroeffnen ' +
            'has-betreuungsfaktor-regeln="$resolve.hasMandantBetreuungsfaktorRegeln" ' +
            'has-tarife="$resolve.hasTarife"></dvb-kinderort-eroeffnen>',
        resolve: {
            hasMandantBetreuungsfaktorRegeln: [
                'mandantService', (mandantService: MandantService): angular.IPromise<boolean> =>
                    mandantService.getAllBetreuungsfaktorRegeln()
                        .then((regeln: BetreuungsfaktorRegel[]): boolean => regeln.length > 0),
            ],
            hasTarife: [
                'tarifService', (tarifService: TarifService): angular.IPromise<boolean> =>
                    tarifService.getAllTarife().then((tarife: Tarif[]): boolean => tarife.length > 0),
            ],
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): IPromise<string> =>
                    trans($translate, 'COMMON.KINDERORT.ERSTELLEN'),
            ],
        },
        data: {
            permission: PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN,
        },
    });

    $stateProvider.state('base.kinderort', {
        abstract: true,
        url: '/kinderort/{id:[0-9]+}',
        template: require('./partial/kita-main/kita-main.html'),
        controller: 'KitaMainCtrl',
        resolve: {
            kita: [
                'kinderOrtService', '$transition$', (
                    kinderOrtService: KinderOrtService,
                    $transition$: Transition,
                ): angular.IPromise<KinderOrt> =>
                    kinderOrtService.get($transition$.params().id, {
                        includes: kinderOrtService.getIncludesDeep(),
                        cache: true,
                    }),
            ],
            kinderOrt: [
                'kita', (kita: KinderOrt): KinderOrt => kita,
            ],
            hasMandantBetreuungsfaktorRegeln: [
                'mandantService', 'authStore', '$transition$',
                (mandantService: MandantService, authStore: AuthStore,
                 $transition$: Transition,
                ): angular.IPromise<boolean> | boolean => {
                    if (!authStore.hasPermission(PERMISSION.KITA.VIEW + String($transition$.params().id))) {
                        return false;
                    }

                    return mandantService.getAllBetreuungsfaktorRegeln()
                        .then((regeln: BetreuungsfaktorRegel[]) => regeln.length > 0);
                },
            ],
            hasMandantSchliesstage: [
                'schliesstageService', 'authStore', '$transition$',
                (
                    schliesstageService: SchliesstageService,
                    authStore: AuthStore,
                    $transition$: Transition,
                ): angular.IPromise<boolean> | boolean => {
                    const kinderOrtId = String($transition$.params().id);
                    const isPermitted = authStore.isPermitted([
                        {oneOf: [PERMISSION.MODULE.ANWESENHEIT, PERMISSION.MODULE.WORK_TIME_CONTROLLING]},
                        PERMISSION.SHARED.SCHLIESSTAGE_VIEW + kinderOrtId,
                    ]);

                    if (!isPermitted) {
                        return false;
                    }

                    return schliesstageService.getAll()
                        .then((schliesstage: Schliesstage[]) => schliesstage.length > 0);
                },
            ],
            wartelisteCount: [
                'kinderOrtService', 'authStore', '$transition$', '$q',
                (
                    kinderOrtService: KinderOrtService,
                    authStore: AuthStore,
                    $transition$: Transition,
                    $q: angular.IQService,
                ): angular.IPromise<number> => {
                    const kinderOrtId = $transition$.params().id;

                    if (!authStore.hasPermission(PERMISSION.KITA.CONTROL + String(kinderOrtId))) {
                        return $q.resolve(0);
                    }

                    return kinderOrtService.getOffeneBewerbungenCount(kinderOrtId);
                },
            ],
            $title: ['kita', (kita: KinderOrt): string => kita.getDisplayName()],
        },
        data: {
            permission(toParams: { id: string }): RequiredPermission {
                return {
                    oneOf: [
                        PERMISSION.KITA.VIEW + toParams.id,
                        PERMISSION.PERSONAL.VIEW + toParams.id,
                        PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_VIEW + toParams.id,
                    ],
                };
            },
        },
    });
    $stateProvider.state('base.kinderort.uebersicht', {
        url: '/uebersicht',
        template: '<dvb-kita-uebersicht ' +
            'kita="$resolve.kita" ' +
            'first-of-week="firstOfWeek" ' +
            'last-of-week="lastOfWeek" ' +
            'on-set-week="setWeek(newFirstOfWeek)"></dvb-kita-uebersicht>',
    });
    $stateProvider.state('base.kinderort.profil', {
        url: '/profil',
        template: '<dvb-kinderort-profil kinder-ort="$resolve.kita"></dvb-kinderort-profil>',
        data: {
            subtitle: 'COMMON.PROFIL',
        },
    });

    createKitaKinderStates($stateProvider);
    createKitaSettingsStates($stateProvider);
    createKitaFakturaStates($stateProvider);
    createPersonalStates($stateProvider);
    createKitaExternalAnmeldungStates($stateProvider);

    /* Add New States Above */

    // Redirect to the default child state
    $uiRouterProvider.urlService.rules.when('/kinderort/{id:[0-9]+}', '/kinderort/{id:[0-9]+}/uebersicht');
}

function createKitaSettingsStates($stateProvider: StateProvider): void {
    Object.values(KINDERORT_SETTING_STATES).forEach(state => $stateProvider.state(state));
}

function createKitaFakturaStates($stateProvider: StateProvider): void {
    $stateProvider.state('base.kinderort.faktura', {
        url: '/faktura',
        abstract: true,
        template: '<div ui-view></div>',
        data: {
            permission(toParams: { id: string }): string[] {
                return [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL + toParams.id];
            },
        },
    });
    $stateProvider.state('base.kinderort.faktura.overview', {
        url: '?:filter',
        component: 'dvbKitaFaktura',
        resolve: {
            kitaKonfigurationen: [
                'kitaFakturaService', '$transition$',
                (
                    kitaFakturaService: KitaFakturaService,
                    $transition$: Transition,
                ): angular.IPromise<KitaRechnungsKonfiguration<IRechnungsKonfiguration>[]> =>
                    kitaFakturaService.getRechnungsKonfigurationen($transition$.params().id),
            ],
            filter: [
                '$transition$',
                ($transition$: Transition): RechnungenFilter => $transition$.params().filter
                    && RechnungenFilter.fromUriParam($transition$.params().filter),
            ],
        },
    });
    $stateProvider.state('base.kinderort.faktura.rechnungslauf', {
        url: '/rechnungslauf',
        template: '<dvb-kita-rechnungslauf ' +
            'kita="kita" ' +
            'mandant="$resolve.mandant" ' +
            'periode="$resolve.periode"></dvb-kita-rechnungslauf>',
        params: {
            periode: null,
        },
        resolve: {
            mandant: [
                'mandantService',
                (mandantService: MandantService): angular.IPromise<Mandant | null> => mandantService.getOwnMandant(),
            ],
            periode: [
                '$transition$',
                ($transition$: Transition): moment.Moment | null => $transition$.params().periode,
            ],
        },
        data: {
            permission(toParams: { id: string }): string {
                return PERMISSION.KITA.MANAGE + toParams.id;
            },
        },
    });
    $stateProvider.state('base.kinderort.faktura.mahnlauf', {
        url: '/mahnlauf?:status:filter',
        component: 'dvbKitaMahnlauf',
        resolve: {
            status: [
                '$transition$',
                ($transition$: Transition): RechnungsStatus => RechnungsStatus.apiResponseTransformer($transition$.params().status),
            ],
            filter: [
                '$transition$',
                ($transition$: Transition): RechnungenFilter => RechnungenFilter.fromUriParam($transition$.params().filter),
            ],
        },
        data: {
            permission(toParams: { id: string }): string {
                return PERMISSION.KITA.MANAGE + toParams.id;
            },
        },
    });
    $stateProvider.state('base.kinderort.offeneposten', {
        url: '/offeneposten',
        template: '<dvb-kita-offene-posten kita="$resolve.kita"></dvb-kita-offene-posten>',
        data: {
            permission(toParams: { id: string }): string {
                return PERMISSION.KITA.CONTROL + toParams.id;
            },
        },
    });
}

function createKitaKinderStates($stateProvider: StateProvider): void {
    $stateProvider.state('base.kinderort.kinder', {
        abstract: true,
        template: '<dvb-belegungsplan-filter ' +
            'kita-id="kita.id" ' +
            'firmen-kontingente="kita.firmenKontingente" ' +
            'subventioniertes-kontingent="kita.subventioniertesKontingent" ' +
            'first-of-week="firstOfWeek" ' +
            'gruppen="kita.gruppen" ' +
            'custom-fields="$resolve.customFields"></dvb-belegungsplan-filter><ui-view>',
        resolve: {
            wochenplanBewerbung: [
                'wochenplanService',
                (wochenplanService: WochenplanService): angular.IPromise<Wochenplan | null> =>
                    wochenplanService.getWochenplanAnmeldungDeep({cache: true}),
            ],
            customFields: [
                'customFieldService',
                (customFieldService: CustomFieldService): angular.IPromise<CustomField[] | null> =>
                    customFieldService.getAll(NamedEntityType.BELEGUNG, {cache: true}),
            ],
        },
        data: {
            permission(toParams: { id: string }): string {
                return PERMISSION.KITA.VIEW + toParams.id;
            },
        },
    });
    $stateProvider.state('base.kinderort.kinder.warteliste', {
        url: '/warteliste',
        template: '<dvb-kita-kinder kita="$resolve.kita" ' +
            'wochenplan-bewerbung="$resolve.wochenplanBewerbung" ' +
            'first-of-week="firstOfWeek" ' +
            'last-of-week="lastOfWeek" ' +
            'on-date-selected="setWeek(date)"></dvb-kita-kinder>',
        data: {
            permission(toParams: { id: string }): string {
                return PERMISSION.KITA.CONTROL + toParams.id;
            },
        },
    });
    $stateProvider.state('base.kinderort.kinder.belegung', {
        url: '/belegung',
        template: '<dvb-kita-kinder kita="$resolve.kita" ' +
            'wochenplan-bewerbung="$resolve.wochenplanBewerbung" ' +
            'first-of-week="firstOfWeek" ' +
            'last-of-week="lastOfWeek" ' +
            'on-date-selected="setWeek(date)"></dvb-kita-kinder>',
    });
}

function createPersonalStates($stateProvider: StateProvider): void {
    Object.values(KINDERORT_PERSONAL_STATES).forEach(state => {
        $stateProvider.state(state);
    });
}

function createKitaExternalAnmeldungStates($stateProvider: StateProvider): void {
    Object.values(KINDERORT_EXTERNAL_ANMELDUNG_STATES).forEach(state => $stateProvider.state(state));
}
