<div class="dvb-termine row">

    <div class="col-md-7 big-abstand-bottom">
        <div class="row">
            <div class="col-md-12">
                <h2 data-translate="PERSONAL.TERMIN.TITLE"></h2>

                <dvlib-date-switcher class="normal-abstand-left"
                                     mode="year"
                                     [(date)]="vm.year"
                                     (debounced-date-change)="vm.loadTermine()">
                </dvlib-date-switcher>
            </div>
        </div>

        <dvlib-spinner class="centered-spinner" [show]="vm.isLoading"></dvlib-spinner>

        <div ng-if="vm.termine.length === 0 && !vm.isLoading">
            <p data-translate="PERSONAL.TERMIN.NONE"></p>
        </div>

        <div ng-repeat="termin in vm.termine" ng-init="workingCopy = (termin | copy)">
            <dvb-collapsible-panel is-first="$first">
                <panel-header class="full-width-block">
                    <dvb-termin-header termin="termin" angestellte="vm.kinderOrtAngestellte"></dvb-termin-header>
                </panel-header>
                <panel-content>
                    <div ng-if="!workingCopy.editMode">
                        <dvb-termin-header termin="termin" angestellte="vm.kinderOrtAngestellte"></dvb-termin-header>

                        <div class="normal-abstand-top" ng-if="termin.wiederkehrend">
                            <span ng-bind="termin.rruleText"></span>
                        </div>

                        <ul class="list-inline normal-abstand-top"
                            dvb-auth
                            require-permission="personal:manage:general">
                            <li ng-if="vm.isEditable(termin)">
                                <button type="button"
                                        class="btn btn-link"
                                        ng-click="vm.openTerminDialog(workingCopy)"
                                        data-translate="COMMON.BEARBEITEN">
                                </button>
                            </li>
                            <li ng-if="vm.isEditable(termin)">
                                <button type="button"
                                        class="btn btn-link"
                                        ng-click="vm.remove(termin)"
                                        data-translate="COMMON.LOESCHEN">
                                </button>
                            </li>
                        </ul>
                    </div>
                </panel-content>
            </dvb-collapsible-panel>
        </div>
    </div>
    <div class="col-md-4 col-md-offset-1">
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <div dvb-auth
             require-permission="personal:manage:general">
            <ul ng-if="!vm.newTerminFormVisible" class="list-inline">
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.openTerminDialog(vm.newTermin)"
                            data-translate="PERSONAL.TERMIN.ERSTELLEN">
                    </button>
                </li>
            </ul>
        </div>
    </div>

</div>
<dv-termin-form-dialog
    [dialog-open]="vm.openDialog"
    [termin]="vm.editTermin"
    [termin-types]="vm.terminTypes"
    (save)="vm.save($event.termin, $event.context)"
    (cancelEdit)="vm.openDialog = false">
</dv-termin-form-dialog>
