/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {CrudAccordionComponent, CrudAccordionTemplateDirective} from '@dv/kitadmin/ui';
import {ButtonListComponent, RequirePermissionStructuralDirective, SpinnerComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import {TerminTypeFormComponent} from '../termin-type-form/termin-type-form.component';
import {TerminTypesStore} from './termin-types.store';

@Component({
    selector: 'dv-termin-types',
    imports: [
        ButtonListComponent,
        CrudAccordionComponent,
        CrudAccordionTemplateDirective,
        SpinnerComponent,
        TranslocoModule,
        TerminTypeFormComponent,
        RequirePermissionStructuralDirective,
    ],
    providers: [TerminTypesStore],
    templateUrl: './termin-types.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminTypesComponent {
    public readonly store = inject(TerminTypesStore);
}
