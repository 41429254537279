/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {EingewoehnungPosition, TarifrechnungPosition} from '@dv/kitadmin/models';
import {ButtonComponent, DvLocalDateFormatPipe} from '@dv/shared/angular';
import {Gueltigkeit} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {LeistungspositionComponent} from '../../leistungsposition/leistungsposition.component';

@Component({
    selector: 'dv-eingewoehnungposition',
    imports: [
        CommonModule,
        LeistungspositionComponent,
        DvLocalDateFormatPipe,
        ButtonComponent,
        TranslocoModule,
    ],
    templateUrl: './eingewoehnungposition.component.html',
    styleUrl: './eingewoehnungposition.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EingewoehnungpositionComponent {
    @Input({required: true})
    public set eingewoehnungPosition(value: EingewoehnungPosition) {
        if (!value) {
            return;
        }

        this.leistungsPosition = value.leistungsPosition ?? new TarifrechnungPosition();
        this.gueltigkeit = new Gueltigkeit(value.gueltigAb, value.gueltigBis);
    }

    @Input({required: true}) public kitaName: string = '';

    @Output() public readonly deleteEvent: EventEmitter<void> = new EventEmitter();

    public leistungsPosition: TarifrechnungPosition = new TarifrechnungPosition();
    public gueltigkeit: Gueltigkeit = new Gueltigkeit();
}
