import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonComponent, ButtonListComponent, ElasticTextInputDirective} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {CalendarEditDayInfoEvent} from '../../model/CalendarEditDayInfoEvent';
import {CalendarTranslation} from '../../model/CalendarTranslation';
import {GridEntry} from '../../model/GridEntry';

@Component({
    selector: 'dv-timeline-calendar-day-info',
    imports: [
        CommonModule,
        OverlayModule,
        ElasticTextInputDirective,
        TranslocoModule,
        TooltipModule,
        FormsModule,
        ButtonComponent,
        ButtonListComponent,
    ],
    templateUrl: './timeline-calendar-day-info.component.html',
    styleUrl: './timeline-calendar-day-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineCalendarDayInfoComponent {

    @Input({required: true}) public gridEntry!: GridEntry;
    @Input({required: true}) public calendarTranslation!: CalendarTranslation;
    @Input() public readonly: boolean = false;

    @Output() public readonly editDayInfoEvent: EventEmitter<CalendarEditDayInfoEvent> = new EventEmitter();

    public dayInfoOverlayContent?: string;

    @ViewChild('tagesinfoInput')
    public tagesinfoInput!: ElementRef;

    public toggleOverlay(): void {
        this.gridEntry.overlayOpen = !this.gridEntry.overlayOpen;
        if (this.gridEntry.overlayOpen) {
            setTimeout(() => {
                this.tagesinfoInput?.nativeElement.focus();
            });
        }
    }

    public editDayInfo(): void {
        if (this.gridEntry.dayInfo?.content !== this.dayInfoOverlayContent) {
            this.editDayInfoEvent.emit({date: this.gridEntry.time, content: this.dayInfoOverlayContent ?? ''});
        }
        this.gridEntry.overlayOpen = false;
    }
}
