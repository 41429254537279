import {ChangeDetectionStrategy, Component, ElementRef, inject, Input, signal, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SpinnerComponent} from '@dv/shared/angular';
import {Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {debounceTime, fromEvent, merge, take, tap} from 'rxjs';
import {
    TimelineCalendarComponent,
} from '../../../../calendar/timeline/component/timeline-calendar/timeline-calendar.component';
import type {CalendarTranslation} from '../../../../calendar/timeline/model/CalendarTranslation';
import {
    PersonalplanungFilterComponent,
} from '../../../../kinderort/component/personal/personalplanung/personalplanung-filter/personalplanung-filter.component';
import {
    ZuweisbareDiensteComponent,
} from '../../../../kinderort/component/personal/personalplanung/zuweisbare-dienste/zuweisbare-dienste.component';
import {PersonalTimelineStore} from '../../../../kinderort/component/personal/service/personal-timeline.store';
import {Angestellte} from '../../models/Angestellte';
import {AngestellteZuweisungStore} from './angestellte-zuweisung.store';

const RESIZE_DEBOUNCE_TIME = 20;
const MAIN_NAV_HEIGHT = 56;

@Component({
    selector: 'dv-angestellte-zuweisung',
    imports: [
        TranslocoModule,
        TimelineCalendarComponent,
        PersonalplanungFilterComponent,
        SpinnerComponent,
        ZuweisbareDiensteComponent,
    ],
    providers: [AngestellteZuweisungStore],
    templateUrl: './angestellte-zuweisung.component.html',
    styleUrl: './angestellte-zuweisung.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AngestellteZuweisungComponent {

    @Input({required: true})
    public set angestellte(angestellte: Persisted<Angestellte>) {
        this.store.angestellte.set(angestellte);
    }

    @ViewChild('header')
    public headerElem!: ElementRef;

    public readonly store = inject(AngestellteZuweisungStore);
    public readonly timelineStore = inject(PersonalTimelineStore);

    public calendarTranslations: CalendarTranslation = {
        groupAddResourceButton: '',
        resourceSearchPlaceholder: '',
        dayInfo: '',
    };

    private windowResize$ = merge(
        fromEvent(window, 'resize'),
        fromEvent(window, 'scroll').pipe(take(1)),
    ).pipe(
        takeUntilDestroyed(),
        debounceTime(RESIZE_DEBOUNCE_TIME),
        tap(() => this.setupCalendarStickyValues()),
    ).subscribe();
    public calendarScrollBeforeSticky = signal(0);
    public calendarStickyTopPos = signal(0);

    private setupCalendarStickyValues(): void {
        this.calendarScrollBeforeSticky.set(this.headerElem?.nativeElement.offsetTop || 0);
        this.calendarStickyTopPos.set(MAIN_NAV_HEIGHT + (this.headerElem?.nativeElement.offsetHeight || 0));
    }
}
