/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BelegungsEinheit, Tagesplan, Wochenplan, ZeitraumFeld} from '@dv/kitadmin/models';
import {
    BelegteEinheit,
    bewerbungsplanError,
    errUnknownWochentag,
    INVALID_BELEGUNGS_EINHEIT,
    ZeitraumUtil,
} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {checkPresent, isNullish} from '@dv/shared/code';

export class DvbBewerbungsplanService {
    public static $inject: readonly string[] = [];

    private static newBelegteEinheit(
        belegungsEinheitId: string | null,
        dayOfWeek: DayOfWeek,
    ): BelegteEinheit {
        const be = new BelegteEinheit();
        be.wochentag = dayOfWeek;
        be.belegungsEinheitId = belegungsEinheitId;

        return be;
    }

    /**
     * Sets 'selected = true' for each ZeitraumFeld that is referenced by a Zeitraum in belegteEinheiten
     */
    public setSelectionToZeitraumFelder(
        wochenplan: Wochenplan,
        belegteEinheiten: BelegteEinheit[],
        zeitraumFelder: ZeitraumFeld[],
    ): void {

        if (!Array.isArray(belegteEinheiten) ||
            belegteEinheiten.length === 0 ||
            !wochenplan ||
            !Array.isArray(wochenplan.tagesplaene)) {

            return;
        }

        // Alle ZeitraumFelder welche in einer BelegungsEinheit (durch eine BelegungsEinheit) referenziert werden
        // sind "selected".
        belegteEinheiten.forEach(belegteEinheit => {
            const zeitraumFelderOfDay = zeitraumFelder.filter(feld => feld.dayOfWeek === belegteEinheit.wochentag);

            if (isNullish(zeitraumFelderOfDay)) {
                return;
            }

            const belegungsEinheit = this.findBelegungsEinheit(
                checkPresent(belegteEinheit.wochentag),
                checkPresent(belegteEinheit.belegungsEinheitId),
                wochenplan);

            if (belegungsEinheit === null) {
                return;
            }

            belegungsEinheit.zeitraumIds.forEach(zeitraumId => {
                zeitraumFelderOfDay.filter(zf => zf.zeitraum.id === zeitraumId)
                    .forEach(zf => {
                        zf.selected = true;
                    });
            });
        });
    }

    public findBelegteEinheit(belegteEinheiten: BelegteEinheit[], dayOfWeek: DayOfWeek): BelegteEinheit | null {
        const filtered = belegteEinheiten.filter(einheit => einheit.wochentag === dayOfWeek);

        if (filtered.length === 1) {
            return filtered[0];
        }

        if (filtered.length > 1) {
            throw new Error(`There are multiple BelegteEinheiten with the same dayOfWeek ${dayOfWeek}`);
        }

        return null;
    }

    /**
     * @throws BewerbungsplanErrors
     */
    public handleToggledZeitraumFeld(zeitraumFeld: ZeitraumFeld, params: {
        zeitraumFelder: ZeitraumFeld[];
        wochenplan: Wochenplan;
    }): BelegteEinheit {
        const dayOfWeek = zeitraumFeld.dayOfWeek;

        const selectedZeitraumFelderOfDay = params.zeitraumFelder.filter(
            feld => feld.dayOfWeek === dayOfWeek && feld.selected);

        if (selectedZeitraumFelderOfDay.length === 0) {
            return DvbBewerbungsplanService.newBelegteEinheit(null, dayOfWeek);
        }

        const tagesplan = this.findTagesplan(dayOfWeek, params.wochenplan);

        if (tagesplan) {
            const foundBelegungsEinheit = ZeitraumUtil.findBelegungsEinheitFromZeitraumFelder(
                tagesplan.belegungsEinheiten, selectedZeitraumFelderOfDay);

            if (foundBelegungsEinheit) {
                return DvbBewerbungsplanService.newBelegteEinheit(
                    checkPresent(foundBelegungsEinheit.id),
                    dayOfWeek);
            }

            throw bewerbungsplanError(INVALID_BELEGUNGS_EINHEIT, dayOfWeek);
        }

        throw bewerbungsplanError(errUnknownWochentag, dayOfWeek);
    }

    /**
     * @throws BewerbungsplanErrors
     */
    public belegteEinheitenFromZeitraumFelder(
        wochenplan: Wochenplan,
        zeitraumFelder: ZeitraumFeld[],
    ): BelegteEinheit[] | null {
        if (!Array.isArray(zeitraumFelder) ||
            zeitraumFelder.length === 0 ||
            !wochenplan ||
            !Array.isArray(wochenplan.tagesplaene)) {

            return null;
        }

        const belegteEinheiten: BelegteEinheit[] = [];
        wochenplan.tagesplaene.forEach(tagesplan => {
            const selectedZeitraumFelderOfDay = zeitraumFelder.filter(
                feld => feld.dayOfWeek === tagesplan.wochentag && feld.selected);

            const foundBelegungsEinheit = ZeitraumUtil.findBelegungsEinheitFromZeitraumFelder(
                tagesplan.belegungsEinheiten, selectedZeitraumFelderOfDay);

            if (foundBelegungsEinheit) {
                const belegteEinheit = DvbBewerbungsplanService.newBelegteEinheit(
                    checkPresent(foundBelegungsEinheit.id),
                    tagesplan.wochentag);

                belegteEinheiten.push(belegteEinheit);
            } else if (selectedZeitraumFelderOfDay.length > 0) {
                console.error('Etwas ungültiges wurde im GUI gewählt');
                throw bewerbungsplanError(INVALID_BELEGUNGS_EINHEIT, tagesplan.wochentag);
            }
        });

        return belegteEinheiten;
    }

    private findTagesplan(dayOfWeek: DayOfWeek, wochenplan: Wochenplan): Tagesplan | null {
        const filtered = wochenplan.tagesplaene.filter(t => t.wochentag === dayOfWeek);

        if (filtered.length > 0) {
            return filtered[0];
        }

        return null;
    }

    private findBelegungsEinheit(
        dayOfWeek: DayOfWeek,
        belegungsEinheitId: string,
        wochenplan: Wochenplan,
    ): BelegungsEinheit | null {
        const tagesplan = this.findTagesplan(dayOfWeek, wochenplan);

        if (tagesplan) {
            const filtered = tagesplan.belegungsEinheiten.filter(be => be.id === belegungsEinheitId);

            if (filtered.length > 0) {
                return filtered[0];
            }
        }

        return null;
    }
}
