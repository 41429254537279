import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MinutesInputDirective} from '@dv/kitadmin/ui';
import {DayOfWeekFormComponent, DvDayOfWeekFormatPipe, DvMinutesFormatPipe, formViewProvider} from '@dv/shared/angular';
import {DayOfWeek, isPresent, nextId} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DayOfWeekAnstellungen} from '../../../models/anstellung-form-model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-angestellte-anstellung-expected-time',
    imports: [
        FormsModule,
        TranslocoModule,
        TooltipModule,
        DayOfWeekFormComponent,
        DvDayOfWeekFormatPipe,
        MinutesInputDirective,
        DvMinutesFormatPipe,
    ],
    templateUrl: './angestellte-anstellung-expected-time.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    viewProviders: [formViewProvider],
})
export class AngestellteAnstellungExpectedTimeComponent {
    public dailyAnstellungen = input.required<{ [day: string]: DayOfWeekAnstellungen }>();

    protected expectedMinutes = computed(() => {
        const availableExpectedMinutes = Object.values(this.dailyAnstellungen())
            .map(dailyAnstellung => dailyAnstellung.expectedMinutes())
            .filter(isPresent);

        if (availableExpectedMinutes.length < 1) {
            return null;
        }

        return availableExpectedMinutes.reduce((sum, minutes) => sum + minutes, 0);
    });

    protected idsByDayOfWeek = Object.values(DayOfWeek)
        .map((dayOfWeek): { [day: string]: string } => ({[dayOfWeek]: nextId()}))
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
        .reduce<{ [day: string]: string }>((agg, cur) => ({...agg, ...cur}), {} as any);

    protected idsSpaceSeparated = Object.values(this.idsByDayOfWeek).join(' ');
}
