/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';
import {CustomHttpParameterCodec} from '../encoder';

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxTempBlob} from '@dv/shared/backend/model/jax-temp-blob';
import type {JaxWorkTimeViolationReportParams} from '@dv/shared/backend/model/jax-work-time-violation-report-params';
import type {ReportingBetriebResourceGetAdresslisteKinderKontakteKinderkontakteParameter} from '@dv/shared/backend/model/reporting-betrieb-resource-get-adressliste-kinder-kontakte-kinderkontakte-parameter';
import type {ReportingPersonalResourceGetFraktionPersonalplanungReportMonthlyMonthlyParameter} from '@dv/shared/backend/model/reporting-personal-resource-get-fraktion-personalplanung-report-monthly-monthly-parameter';
import type {ReportingPersonalResourceGetKinderOrtFraktionPersonalplanungReportFraktionIdMatrixParameter} from '@dv/shared/backend/model/reporting-personal-resource-get-kinder-ort-fraktion-personalplanung-report-fraktion-id-matrix-parameter';
import type {ReportingPersonalResourceGetKinderOrtPersonalplanungReportKinderOrtIdMatrixParameter} from '@dv/shared/backend/model/reporting-personal-resource-get-kinder-ort-personalplanung-report-kinder-ort-id-matrix-parameter';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';

export interface ReportingPersonalServiceGetFraktionPersonalplanungReportMonthlyRequestParams {
    fraktionId: EntityId;
    monthly: ReportingPersonalResourceGetFraktionPersonalplanungReportMonthlyMonthlyParameter;
    userAgent?: string;
}

export interface ReportingPersonalServiceGetKinderOrtFraktionPersonalplanungReportRequestParams {
    fraktionId: EntityId;
    fraktionIdMatrix: ReportingPersonalResourceGetKinderOrtFraktionPersonalplanungReportFraktionIdMatrixParameter;
    userAgent?: string;
}

export interface ReportingPersonalServiceGetKinderOrtPersonalplanungReportRequestParams {
    kinderOrtId: EntityId;
    kinderOrtIdMatrix: ReportingPersonalResourceGetKinderOrtPersonalplanungReportKinderOrtIdMatrixParameter;
    userAgent?: string;
}

export interface ReportingPersonalServiceGetKinderOrtPersonalplanungReportMonthlyRequestParams {
    kinderOrtId: EntityId;
    monthly: ReportingPersonalResourceGetFraktionPersonalplanungReportMonthlyMonthlyParameter;
    userAgent?: string;
}

export interface ReportingPersonalServiceGetWorkTimeViolationsRequestParams {
    violations: ReportingBetriebResourceGetAdresslisteKinderKontakteKinderkontakteParameter;
    userAgent?: string;
    jaxWorkTimeViolationReportParams?: JaxWorkTimeViolationReportParams;
}

@Injectable({
  providedIn: 'root'
})
export class ReportingPersonalService {

    protected basePath = '/kitadmin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && !(value instanceof Date)) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // noinspection AssignmentResultUsedJS
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                // noinspection AssignmentResultUsedJS
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getFraktionPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetFraktionPersonalplanungReportMonthlyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxTempBlob>;
    public getFraktionPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetFraktionPersonalplanungReportMonthlyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxTempBlob>>;
    public getFraktionPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetFraktionPersonalplanungReportMonthlyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxTempBlob>>;
    public getFraktionPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetFraktionPersonalplanungReportMonthlyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const fraktionId = requestParameters.fraktionId;
        if (fraktionId === null || fraktionId === undefined) {
            throw new Error('Required parameter fraktionId was null or undefined when calling getFraktionPersonalplanungReportMonthly$.');
        }
        const monthly = requestParameters.monthly;
        if (monthly === null || monthly === undefined) {
            throw new Error('Required parameter monthly was null or undefined when calling getFraktionPersonalplanungReportMonthly$.');
        }
        const userAgent = requestParameters.userAgent;

        let localVarHeaders = this.defaultHeaders;
        if (userAgent !== undefined && userAgent !== null) {
            localVarHeaders = localVarHeaders.set('User-Agent', String(userAgent));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/reporting/personal/excel/personalplanung/fraktion/${this.configuration.encodeParam({name: "fraktionId", value: fraktionId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/monthly${this.configuration.encodeParam({name: "monthly", value: monthly, in: "path", style: "matrix", explode: true, dataType: "ReportingPersonalResourceGetFraktionPersonalplanungReportMonthlyMonthlyParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxTempBlob>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderOrtFraktionPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtFraktionPersonalplanungReportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxTempBlob>;
    public getKinderOrtFraktionPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtFraktionPersonalplanungReportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxTempBlob>>;
    public getKinderOrtFraktionPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtFraktionPersonalplanungReportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxTempBlob>>;
    public getKinderOrtFraktionPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtFraktionPersonalplanungReportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const fraktionId = requestParameters.fraktionId;
        if (fraktionId === null || fraktionId === undefined) {
            throw new Error('Required parameter fraktionId was null or undefined when calling getKinderOrtFraktionPersonalplanungReport$.');
        }
        const fraktionIdMatrix = requestParameters.fraktionIdMatrix;
        if (fraktionIdMatrix === null || fraktionIdMatrix === undefined) {
            throw new Error('Required parameter fraktionIdMatrix was null or undefined when calling getKinderOrtFraktionPersonalplanungReport$.');
        }
        const userAgent = requestParameters.userAgent;

        let localVarHeaders = this.defaultHeaders;
        if (userAgent !== undefined && userAgent !== null) {
            localVarHeaders = localVarHeaders.set('User-Agent', String(userAgent));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/reporting/personal/excel/personalplanung/fraktion/${this.configuration.encodeParam({name: "fraktionId", value: fraktionId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}${this.configuration.encodeParam({name: "fraktionIdMatrix", value: fraktionIdMatrix, in: "path", style: "matrix", explode: true, dataType: "ReportingPersonalResourceGetKinderOrtFraktionPersonalplanungReportFraktionIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxTempBlob>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderOrtPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxTempBlob>;
    public getKinderOrtPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxTempBlob>>;
    public getKinderOrtPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxTempBlob>>;
    public getKinderOrtPersonalplanungReport$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderOrtPersonalplanungReport$.');
        }
        const kinderOrtIdMatrix = requestParameters.kinderOrtIdMatrix;
        if (kinderOrtIdMatrix === null || kinderOrtIdMatrix === undefined) {
            throw new Error('Required parameter kinderOrtIdMatrix was null or undefined when calling getKinderOrtPersonalplanungReport$.');
        }
        const userAgent = requestParameters.userAgent;

        let localVarHeaders = this.defaultHeaders;
        if (userAgent !== undefined && userAgent !== null) {
            localVarHeaders = localVarHeaders.set('User-Agent', String(userAgent));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/reporting/personal/excel/personalplanung/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}${this.configuration.encodeParam({name: "kinderOrtIdMatrix", value: kinderOrtIdMatrix, in: "path", style: "matrix", explode: true, dataType: "ReportingPersonalResourceGetKinderOrtPersonalplanungReportKinderOrtIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxTempBlob>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderOrtPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportMonthlyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxTempBlob>;
    public getKinderOrtPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportMonthlyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxTempBlob>>;
    public getKinderOrtPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportMonthlyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxTempBlob>>;
    public getKinderOrtPersonalplanungReportMonthly$(requestParameters: ReportingPersonalServiceGetKinderOrtPersonalplanungReportMonthlyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderOrtPersonalplanungReportMonthly$.');
        }
        const monthly = requestParameters.monthly;
        if (monthly === null || monthly === undefined) {
            throw new Error('Required parameter monthly was null or undefined when calling getKinderOrtPersonalplanungReportMonthly$.');
        }
        const userAgent = requestParameters.userAgent;

        let localVarHeaders = this.defaultHeaders;
        if (userAgent !== undefined && userAgent !== null) {
            localVarHeaders = localVarHeaders.set('User-Agent', String(userAgent));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/reporting/personal/excel/personalplanung/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/monthly${this.configuration.encodeParam({name: "monthly", value: monthly, in: "path", style: "matrix", explode: true, dataType: "ReportingPersonalResourceGetFraktionPersonalplanungReportMonthlyMonthlyParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxTempBlob>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getWorkTimeViolations$(requestParameters: ReportingPersonalServiceGetWorkTimeViolationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxTempBlob>;
    public getWorkTimeViolations$(requestParameters: ReportingPersonalServiceGetWorkTimeViolationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxTempBlob>>;
    public getWorkTimeViolations$(requestParameters: ReportingPersonalServiceGetWorkTimeViolationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxTempBlob>>;
    public getWorkTimeViolations$(requestParameters: ReportingPersonalServiceGetWorkTimeViolationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const violations = requestParameters.violations;
        if (violations === null || violations === undefined) {
            throw new Error('Required parameter violations was null or undefined when calling getWorkTimeViolations$.');
        }
        const userAgent = requestParameters.userAgent;
        const jaxWorkTimeViolationReportParams = requestParameters.jaxWorkTimeViolationReportParams;

        let localVarHeaders = this.defaultHeaders;
        if (userAgent !== undefined && userAgent !== null) {
            localVarHeaders = localVarHeaders.set('User-Agent', String(userAgent));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/reporting/personal/excel/worktime/violations${this.configuration.encodeParam({name: "violations", value: violations, in: "path", style: "matrix", explode: true, dataType: "ReportingBetriebResourceGetAdresslisteKinderKontakteKinderkontakteParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxTempBlob>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxWorkTimeViolationReportParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

}
