/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Signal, WritableSignal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output, signal} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DateSwitcherComponent} from '@dv/shared/angular';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {FilterListDirective} from '../../../../../commonupgrade/directive/filter-list.directive';
import {MultiSelectFilter} from '../../../../../filter/set/MultiSelectFilter';
import {MultiSelectFilterController} from '../../../../../filter/set/MultiSelectFilterController';
import type {Filter} from '../../../../../filter/shared/Filter';
import type {OnChangeListener} from '../../../../../filter/shared/FilterModelChangeHandler';
import type {FilterOption} from '../../../../../filter/shared/FilterOption';
import {DisplayMode} from '../personalplanung-display-mode';

@Component({
    selector: 'dv-personalplanung-filter',
    imports: [
        DateSwitcherComponent,
        FormsModule,
        ButtonsModule,
        TranslocoModule,
        FilterListDirective,
    ],
    templateUrl: './personalplanung-filter.component.html',
    styleUrl: './personalplanung-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalplanungFilterComponent {

    @Input({required: true}) public displayMode!: DisplayMode;
    @Output() public readonly displayModeChange: EventEmitter<DisplayMode> = new EventEmitter();

    @Input({required: true}) public selectedDate!: moment.Moment;
    @Output() public readonly selectedDateChange: EventEmitter<moment.Moment> = new EventEmitter();

    @Input({required: true})
    public set fraktionFilter(filterOptions: FilterOption[]) {
        this._fraktionFilter.set(initFraktionFilter(filterOptions, this.fraktionFilterCtrl));
    }

    @Output() public readonly filteredFraktionenChange: EventEmitter<EntityId[]> = new EventEmitter();

    @Input() public showTerminFilter = true;
    @Input({required: true}) public filterBedarfsRelevanteTermine!: boolean;
    @Output() public readonly filterBedarfsRelevanteTermineChange: EventEmitter<boolean> = new EventEmitter();

    private readonly fraktionFilterCtrl = this.initFraktionFilterController();

    private readonly _fraktionFilter: WritableSignal<MultiSelectFilter<EntityId> | undefined> = signal(undefined);

    public readonly filterList: Signal<Filter[]> = computed(() => {
        const fraktionFilter = this._fraktionFilter();

        return fraktionFilter ? [fraktionFilter] : [];
    });

    public showFilterList = computed(() => {
        const fraktionOptions = this._fraktionFilter()?.options;

        return fraktionOptions ? fraktionOptions.length > 0 : 0;
    });

    private initFraktionFilterController(): MultiSelectFilterController<EntityId> {
        const ctrl = new MultiSelectFilterController(new Set<EntityId>());
        const setRelevantFraktionIds: OnChangeListener = {
            onFilterChanged: () => this.filteredFraktionenChange.emit([...ctrl.getRelevantSelectedValues()]),
        };
        ctrl.addChangeListener(setRelevantFraktionIds);

        return ctrl;
    }
}

function initFraktionFilter(
    filterOptions: FilterOption[],
    ctrl: MultiSelectFilterController<EntityId>,
): MultiSelectFilter<EntityId> {
    const multiSelectFilter = new MultiSelectFilter<EntityId>(filterOptions, 'KINDERORT.FILTER_GRUPPEN', ctrl);
    ctrl.setRelevantValues(filterOptions.map(o => o.id!));
    multiSelectFilter.init();

    return multiSelectFilter;
}
