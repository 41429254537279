<form class="dialog-content" (ngSubmit)="submitForm()" novalidate>
    <h1>{{ title() }}</h1>

    @if (angestellte$ | async; as angestellte) {
        @if (subtitle().length > 0) {
            <div [ngClass]="{'self-start': angestellte.length}">
                <h2 class="small-abstand-bottom">{{ subtitle() }}</h2>
                @if (angestellte.length) {
                    <ul>
                        @for (a of angestellte; track a.id) {
                            <li>
                                <p>
                                    <a [uiSref]="angestellteState.name" [uiParams]="{id: a.id}">
                                        {{ a.getDisplayName() }}
                                    </a>
                                </p>
                            </li>
                        }
                    </ul>
                }
            </div>
        }

        @if (kinderOrtCount() > 1) {
            <h2 class="normal-abstand-top small-abstand-bottom"
                [ngClass]="{'self-start': angestellte.length}">
                 <span [transloco]="'PERSONAL.TERMIN.DELETE_IN_KITA_MF'"
                       [translocoParams]="{count: kinderOrtCount(), entity: 'PERSONAL.TERMIN.SINGULAR' | transloco}"> </span>
            </h2>
        }

        @if (allKinderOrte()) {
            <h2 class="normal-abstand-top small-abstand-bottom"
                [ngClass]="{'self-start': angestellte.length}">
        <span [transloco]="'PERSONAL.TERMIN.DELETE_IN_ALL_KITAS'"
              [translocoParams]="{entity:'PERSONAL.TERMIN.SINGULAR' | transloco}"> </span>
            </h2>
        }

        @if (termin()?.wiederkehrend) {
            <div [ngClass]="{'self-start': angestellte.length}">
                <h2 class="normal-abstand-top small-abstand-bottom">
                    {{ 'PERSONAL.TERMIN.DELETE_UPDATE_MODE' | transloco }}
                </h2>
                <ul>
                    @for (mode of modes; track $index) {
                        <li class="radio">
                            <label>
                                <input type="radio"
                                       [name]="mode"
                                       [value]="mode"
                                       [(ngModel)]="updateMode"
                                       required/>
                                <span [transloco]="'PERSONAL.TERMIN.UPDATE_MODE.' + mode"></span>
                            </label>
                        </li>
                    }
                </ul>
            </div>
        }
    } @else {
        <dvlib-spinner/>
    }


    <dv-submit-cancel-buttons class="big-abstand-top"
                              [submitLabel]="'COMMON.LOESCHEN'"
                              (cancelEvent)="cancelDelete.emit()"/>
</form>
