/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {REPORT_STATES} from './report-states';

angular.module('kitAdmin').config(reportConfig);

export * from './component/dvb-kita-or-fraktion-report/dvb-kita-or-fraktion-report';
export * from './component/dvb-kita-or-fraktionen-report/dvb-kita-or-fraktionen-report';
export * from './component/dvb-corona-rueckerstattung-report/dvb-corona-rueckerstattung-report';
export * from './component/dvb-custom-field-configuration/dvb-custom-field-configuration';
export * from './component/dvb-custom-field-configuration-selector/dvb-custom-field-configuration-selector';
export * from './component/dvb-frueh-spaet-dienst-report/dvb-frueh-spaet-dienst-report';
export * from './component/dvb-geburtstage-report/dvb-geburtstage-report';
export * from './component/dvb-kennzahlen-report/dvb-kennzahlen-report';
export * from './component/dvb-kinderkontaktliste-report/dvb-kinderkontaktliste-report';
export * from './component/dvb-kita-report/dvb-kita-report';
export * from './component/dvb-kitas-report/dvb-kitas-report';
export * from './component/dvb-kitas-with-gueltigkeit-report/dvb-kitas-with-gueltigkeit-report';
export * from './component/dvb-personalplanung-report/dvb-personalplanung-report';
export * from './component/dvb-report-aktuelle-offene-posten/dvb-report-aktuelle-offene-posten';
export * from './component/dvb-report-belegungsplan/dvb-report-belegungsplan';
export * from './component/dvb-report-belegungsplan-day-based/dvb-report-belegungsplan-day-based';
export * from './component/dvb-report-depots/dvb-report-depots';
export * from './component/dvb-report-download/dvb-report-download';
export * from './component/dvb-report-kindergartenkinder/dvb-report-kindergartenkinder';
export * from './component/dvb-report-kontaktperson-zahlungen/dvb-report-kontaktperson-zahlungen';
export * from './component/dvb-report-main-category/dvb-report-main-category';
export * from './component/dvb-report-main/dvb-report-main';
export * from './component/dvb-report-offene-posten/dvb-report-offene-posten';
export * from './component/dvb-report-steuerbescheinigung/dvb-report-steuerbescheinigung';
export * from './component/dvb-report-template-konfiguration/dvb-report-template-konfiguration';
export * from './component/dvb-report-template-outdated-icon/dvb-report-template-outdated-icon';
export * from './component/dvb-report-template-selector/dvb-report-template-selector';
export * from './component/dvb-report-steuerbescheinigung-structure/dvb-report-steuerbescheinigung-structure';
export * from './component/dvb-stundenvergleich-soll-ist-report/dvb-stundenvergleich-soll-ist-report';
export * from './component/dvb-report-external-anmeldungen/dvb-report-external-anmeldungen';
export * from './component/dvb-work-time-violations-report/dvb-work-time-violations-report';
// Add New Component JS Above

reportConfig.$inject = ['$stateProvider'];

function reportConfig($stateProvider: StateProvider): void {
    Object.values(REPORT_STATES).forEach(state => $stateProvider.state(state));
}
