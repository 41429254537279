<form name="personalSortForm" (ngSubmit)="submitOrder.emit(sorted)" novalidate>
    <div class="modal-body">
        <h1>{{ 'PERSONAL.SORT.TITLE' | transloco }}</h1>

        @if (!hasSortableItems()) {
            <p>{{ 'PERSONAL.SORT.NO_SORTABLE_ITEMS' | transloco }}</p>
        } @else {
            <div class="row">
                <div class="col-md-6">
                    <h3>{{ 'PERSONAL.SORT.UNSORTED' | transloco }}</h3>
                    <div class="sort-list"
                         cdkDropList
                         #unsortedList="cdkDropList"
                         [cdkDropListData]="unsorted"
                         [cdkDropListConnectedTo]="[sortedList]"
                         (cdkDropListDropped)="drop($event)">
                        @for (item of unsorted; track item) {
                            <div cdkDrag class="draggable">
                                <span>{{ item.getDisplayName() }}</span>
                            </div>
                        }
                    </div>
                </div>
                <div class="col-md-6">
                    <h3>{{ 'PERSONAL.SORT.SORTED' | transloco }}</h3>
                    <div class="sort-list"
                         cdkDropList
                         #sortedList="cdkDropList"
                         [cdkDropListData]="sorted"
                         [cdkDropListConnectedTo]="[unsortedList]"
                         (cdkDropListDropped)="drop($event)">
                        @for (item of sorted; track item) {
                            <div cdkDrag class="draggable">
                                <span>{{ item.getDisplayName() }}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>

    <div class="modal-footer big-abstand-top">
        @if (hasSortableItems()) {
            <dv-submit-cancel-buttons (cancelEvent)="cancelSort.emit()"/>
        } @else {
            <button type="button"
                    class="btn btn-link"
                    [transloco]="'COMMON.ABBRECHEN'"
                    (click)="cancelSort.emit()">
            </button>
        }
    </div>
</form>
