/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass, NgStyle} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    Signal,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {DvLocalDateTimeFormatPipe} from '@dv/shared/angular';
import type {IDisplayable, IPersistable} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {UIRouterModule} from '@uirouter/angular';
import moment from 'moment';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {CalendarDayInfo} from '../../model/CalendarDayInfo';
import type {CalendarEditDayInfoEvent} from '../../model/CalendarEditDayInfoEvent';
import type {CalendarEvent} from '../../model/CalendarEvent';
import type {CalendarGroup} from '../../model/CalendarGroup';
import type {CalendarDeleteResourceEvent} from '../../model/CalendarGroupDeleteEvent';
import type {CalendarGroupDropEvent} from '../../model/CalendarGroupDropEvent';
import type {CalendarGroupResizeCompleteEvent} from '../../model/CalendarGroupResizeCompleteEvent';
import type {CalendarGroupResizeEvent} from '../../model/CalendarGroupResizeEvent';
import type {CalendarGroupResourceAddEvent} from '../../model/CalendarGroupResourceAddEvent';
import type {CalendarGroupResourceRemoveEvent} from '../../model/CalendarGroupResourceRemoveEvent';
import type {CalendarResizeEvent} from '../../model/CalendarResizeEvent';
import type {CalendarResource} from '../../model/CalendarResource';
import {CalendarTranslation} from '../../model/CalendarTranslation';
import {LayerConfigByLayerIndex} from '../../model/LayerConfig';
import {ROW_HEIGHT, TimelineCalendarService} from '../../service/timeline-calendar.service';
import {TimelineCalendarDayInfoComponent} from '../timeline-calendar-day-info/timeline-calendar-day-info.component';
import {
    TimelineCalendarGroupHeaderComponent,
} from '../timeline-calendar-group-header/timeline-calendar-group-header.component';
import {TimelineCalendarResourceComponent} from '../timeline-calendar-resource/timeline-calendar-resource.component';
import {TimelineGridComponent} from '../timeline-grid/timeline-grid.component';

// gap (padding) above the header, in which the header dates / times are placed
const HEADER_GAP = 55;
// min-width of a grid entry in px
const GRID_ENTRY_WIDTH = 85;

@Component({
    selector: 'dv-timeline-calendar',
    imports: [
        NgStyle,
        NgClass,
        TooltipModule,
        TimelineGridComponent,
        TranslocoModule,
        TimelineCalendarResourceComponent,
        DvLocalDateTimeFormatPipe,
        UIRouterModule,
        TimelineCalendarGroupHeaderComponent,
        TimelineCalendarDayInfoComponent,
    ],
    templateUrl: './timeline-calendar.component.html',
    styleUrl: './timeline-calendar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineCalendarComponent implements AfterViewInit, OnDestroy {

    @Input()
    public set startDate(value: moment.Moment) {
        this.timelineCalendarService.startDate.set(value);
    }

    @Input()
    public set endDate(value: moment.Moment) {
        this.timelineCalendarService.endDate.set(value);
    }

    @Input()
    public set startHour(value: number) {
        this.timelineCalendarService.startHour.set(value);
    }

    @Input()
    public set endHour(value: number) {
        this.timelineCalendarService.endHour.set(value);
    }

    @Input()
    public set selectedDate(value: moment.Moment) {
        this.timelineCalendarService.selectedDate.set(value);
    }

    @Input() public readonly: boolean = false;

    @Input()
    public set resizeSteps(value: number) {
        this.timelineCalendarService.resizeSteps.set(value);
    }

    @Input({required: true})
    public set groups(value: Signal<CalendarGroup[]>) {
        this.timelineCalendarService.groups = value;
    }

    @Input() public dragMode: boolean = false;
    @Input() public availableResources: (IPersistable & IDisplayable)[] = [];
    @Input({required: true}) public calendarTranslation!: CalendarTranslation;

    @Input()
    public set layerConfig(value: LayerConfigByLayerIndex) {
        this.timelineCalendarService.layerConfig.set(value);
    }

    @Input() public showDayInfo: boolean = false;

    @Input()
    public set dayInfo(value: CalendarDayInfo[]) {
        this.timelineCalendarService.dayInfo.set(value);
    }

    /**
     * how far in px from the top of the page the header should stay sticky.
     */
    @Input() public stickyHeaderPos: number = 0;
    /**
     * Amount of vertical scrolling in pixels before the header becomes sticky.
     */
    @Input() public scrollBeforeSticky: number = 0;
    @Input() public calendarEventWithOpenedEditOverlay?: CalendarEvent;
    @Input() public calendarEventEditOverlay?: TemplateRef<Element>;
    @Output() public readonly closeCalendarEventEditOverlay: EventEmitter<void> = new EventEmitter();
    @Output() public readonly sortGroup: EventEmitter<CalendarGroup> = new EventEmitter();
    @Output() public readonly resourceDrop: EventEmitter<CalendarGroupDropEvent> = new EventEmitter();
    @Output() public readonly resizeEvent: EventEmitter<CalendarGroupResizeEvent> = new EventEmitter();
    @Output() public readonly resizeComplete: EventEmitter<CalendarGroupResizeCompleteEvent> = new EventEmitter();
    @Output() public readonly resourceRemove: EventEmitter<CalendarGroupResourceRemoveEvent> = new EventEmitter();
    @Output() public readonly resourceAdd: EventEmitter<CalendarGroupResourceAddEvent> = new EventEmitter();
    @Output() public readonly deleteEvent: EventEmitter<CalendarDeleteResourceEvent> = new EventEmitter();
    @Output() public readonly editEvent: EventEmitter<{
        element: Element;
        event: CalendarEvent;
    }> = new EventEmitter();
    @Output() public readonly editDayInfoEvent: EventEmitter<CalendarEditDayInfoEvent> = new EventEmitter();
    @Output() public readonly selectDate: EventEmitter<moment.Moment> = new EventEmitter();

    @ViewChild('headerElem')
    public headerElem!: ElementRef;
    @ViewChild('timelineElem')
    public timelineElem!: ElementRef;

    public readonly timelineCalendarService = inject(TimelineCalendarService);
    private readonly zone = inject(NgZone);
    private readonly renderer = inject(Renderer2);

    public readonly hourFormat: string = 'HH:mm';
    public readonly dayFormat: string = 'dd D.M.';
    public readonly idFormat: string = 'MM-DD';
    public readonly displayFormat = computed(() => {
        return this.timelineCalendarService.fullDayBlocks() ? this.dayFormat : this.hourFormat;
    });

    public rowHeight = ROW_HEIGHT;
    public gridEntryWidth = GRID_ENTRY_WIDTH;

    // noinspection JSUnusedLocalSymbols
    private scrollToSelectedDateEffect = effect(() => {
        this.timelineCalendarService.grid();
        this.scrollToSelectedDate();
    });

    private unlistenScroll: (() => void) | undefined;

    public ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.unlistenScroll = this.renderer.listen('window', 'scroll', event => {
                const scrollTop = event.target.scrollingElement.scrollTop;
                const topPos = Math.max(0, scrollTop - this.scrollBeforeSticky + HEADER_GAP);
                this.headerElem.nativeElement.style.top = `${topPos}px`;
            });
        });
    }

    public ngOnDestroy(): void {
        this.unlistenScroll?.();
    }

    public dropOnResource(
        event: DragEvent,
        group: CalendarGroup,
        resource: CalendarResource,
    ): void {
        event.preventDefault();
        const dropDate = this.timelineCalendarService.timelineCalculationStrategy().calculateDropDate(
            event,
            this.timelineCalendarService,
            this.timelineElem,
            this.headerElem);
        const data = checkPresent(event.dataTransfer?.getData('text/plain'));

        this.resourceDrop.emit({group, resource, date: dropDate, data});
    }

    public handleResize(event: CalendarResizeEvent, resource: CalendarResource): void {
        const minutes = Math.floor(event.resizeEvent.pixels / this.determineMinuteWidth());
        const resizeApplied = this.timelineCalendarService.resize(event, minutes);

        if (resizeApplied) {
            this.zone.run(() => this.resizeEvent.emit({event, resource}));
        }
    }

    public handleResizeComplete(event: CalendarEvent, resource: CalendarResource): void {
        const originalEvent = this.timelineCalendarService.getOriginalEvent();
        this.timelineCalendarService.resizeComplete();
        this.resizeComplete.emit({event, originalEvent, resource});
    }

    public isToday(time: moment.Moment): boolean {
        if (this.timelineCalendarService.fullDayBlocks()) {
            return time.isSame(DvbDateUtil.today(), 'day');
        }

        return false;
    }

    private determineMinuteWidth(): number {
        const headerWidth = this.headerElem.nativeElement.scrollWidth;

        return headerWidth / this.timelineCalendarService.durationMinutes();
    }

    private scrollToSelectedDate(): void {
        const selectedDate = this.timelineCalendarService.selectedDate().format(this.idFormat);
        const foundElement = document.getElementById(selectedDate);
        if (foundElement) {
            foundElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    }
}
