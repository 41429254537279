/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, Kontaktperson} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';
import {DvbDateUtil, Gueltigkeit, SearchResultEntry} from '@dv/shared/code';
import {GENERAL_SUFFIX} from '@dv/shared/roles';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import moment from 'moment';
import type {AuthService} from '../authentication/service/authService';
import {trans} from '../common/i18n/trans';
import {ParameterKinderOrte} from './models';
import {ReportState} from './models/ReportState';

/* eslint-disable max-lines */

const $transitionInjectionToken = '$transition$';
const uiView = '<ui-view></ui-view>';

function getYear($transition$: Transition): number | null {
    if ($transition$.params().year) {
        return $transition$.params().year;
    }
    if (moment.isMoment($transition$.params().stichtag)) {
        return $transition$.params().stichtag.year();
    }

    return null;
}

function getParameterKinderOrte($transition$: Transition, year: number | null): ParameterKinderOrte {
    return new ParameterKinderOrte(
        $transition$.params().alleKinderOrte,
        $transition$.params().kinderOrte,
        $transition$.params().stichtag,
        year,
    );
}

const resolves = {
    parameterKinderOrte: [$transitionInjectionToken, 'year', getParameterKinderOrte],
    year: [$transitionInjectionToken, getYear],
    kinderOrt: [
        $transitionInjectionToken, ($transition$: Transition): SearchResultEntry | null =>
            $transition$.params().kinderOrte ? $transition$.params().kinderOrte[0] : null,
    ],
    preSelectableKinderOrt: [
        'authService',
        (authService: AuthService): SearchResultEntry | null => {
            const preSelectable = authService.getPrincipal().preSelectableKinderOrt;

            return preSelectable ? SearchResultEntry.apiResponseTransformer(preSelectable) : null;
        },
    ],
    stichtag: [
        $transitionInjectionToken, ($transition$: Transition): moment.Moment | null => $transition$.params().stichtag,
    ],
    stichtagAdresse: [
        $transitionInjectionToken,
        ($transition$: Transition): moment.Moment | null => $transition$.params().stichtagAdresse,
    ],
    gueltigAb: [
        $transitionInjectionToken,
        ($transition$: Transition): moment.Moment | null => $transition$.params().gueltigAb,
    ],
    gueltigBis: [
        $transitionInjectionToken, ($transition$: Transition): moment.Moment | null => $transition$.params().gueltigBis,
    ],
};

export const REPORT_BASE_STATE = {
    name: 'base.report',
    abstract: true,
    template: '<dvb-report-main></dvb-report-main>',
    resolve: {
        reportState: [
            (): typeof ReportState => ReportState,
        ],
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'REPORT.TITLE'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const REPORT_CATEGORY_BASE_STATE = {
    name: `${REPORT_BASE_STATE.name}.category`,
    url: '/report',
    abstract: true,
    params: {
        alleKinderOrte: {dynamic: true, value: true},
        gueltigAb: {dynamic: true, value: null},
        gueltigBis: {dynamic: true, value: null},
        kinderOrte: {array: true, dynamic: true, value: []},
        stichtag: {dynamic: true, value: null},
        stichtagAdresse: {dynamic: true, value: null},
        year: {dynamic: true, value: null},
    },
    template: '<dvb-report-main-category></dvb-report-main-category>',
    data: {
        permission: PERMISSION.SHARED.EMPLOYEE_VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;

export const REPORT_CONFIG_STATE = {
    name: `${REPORT_BASE_STATE.name}.konfiguration`,
    url: '/report/konfiguration',
    data: {
        permission: PERMISSION.KITA.ADMINISTRATE_ANY,
    },
    redirectTo: 'base.report.konfiguration.template',
} satisfies Ng1StateDeclaration;

export const REPORT_CONFIG_TEMPLATE_STATE = {
    name: `${REPORT_CONFIG_STATE.name}.template`,
    url: '/template',
    params: {},
    template: '<dvb-report-template-konfiguration></dvb-report-template-konfiguration>',
    data: {
        subtitle: 'REPORT.TEMPLATE.KONFIGURATION',
        permission: {oneOf: [PERMISSION.KITA.ADMINISTRATE_ANY, PERMISSION.PERSONAL.ADMINISTRATE_ANY]},
    },
} satisfies Ng1StateDeclaration;

export const REPORT_CONFIG_CUSTOM_FIELD_STATE = {
    name: `${REPORT_CONFIG_STATE.name}.customfield`,
    url: '/customfield',
    template: '<dvb-custom-field-configuration></dvb-custom-field-configuration>',
    data: {
        subtitle: 'REPORT.CUSTOM_FIELD_CONFIGURATION.TITLE',
        permission: {oneOf: [PERMISSION.KITA.ADMINISTRATE_ANY, PERMISSION.PERSONAL.ADMINISTRATE_ANY]},
    },
} satisfies Ng1StateDeclaration;

//
// betrieb
//
const betriebbasePermission = PERMISSION.KITA.VIEW_ANY;

export const REPORT_BETRIEB_STATE = {
    name: `${REPORT_CATEGORY_BASE_STATE.name}.betrieb`,
    url: '/betrieb',
    template: uiView,
    data: {
        permission: betriebbasePermission,
    },
} satisfies Ng1StateDeclaration;
export const REPORT_ADRESSLISTE_KINDER_KONTAKTE_STATE = {
    name: ReportState.BETRIEB.ADRESSLISTE_KINDER_KONTAKTE.state,
    url: '/adressliste',
    template: `<dvb-kinderkontaktliste-report
        report-state="$resolve.reportState.BETRIEB.ADRESSLISTE_KINDER_KONTAKTE"
        parameter-kinder-orte="$resolve.parameterKinderOrte"
        stichtag-adresse="$resolve.stichtagAdresse"></dvb-kinderkontaktliste-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BELEGUNGSPLAN_STATE = {
    name: ReportState.BETRIEB.BELEGUNGSPLAN.state,
    url: '/belegungsplan',
    template: `<dvb-report-belegungsplan
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        report-state="$resolve.reportState.BETRIEB.BELEGUNGSPLAN"></dvb-report-belegungsplan>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_AKTUELLE_AENDERUNGEN_STATE = {
    name: ReportState.BETRIEB.AKTUELLE_AENDERUNGEN.state,
    url: '/aktuelle-aenderungen',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.BETRIEB.AKTUELLE_AENDERUNGEN"
        show-year="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        year="$resolve.year"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BELEGUNGSPLAN_ANWESENHEITSZEITEN_STATE = {
    name: ReportState.BETRIEB.BELEGUNGSPLAN_ANWESENHEITSZEITEN.state,
    url: '/belegungsplan-anwesenheitszeiten',
    template: `<dvb-report-belegungsplan
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        report-state="$resolve.reportState.BETRIEB.BELEGUNGSPLAN_ANWESENHEITSZEITEN"></dvb-report-belegungsplan>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BELEGUNGSPLAN_DAY_BASED_STATE = {
    name: ReportState.BETRIEB.BELEGUNGSPLAN_DAY_BASED.state,
    url: '/belegungsplan-tagesbasiert',
    template: `<dvb-report-belegungsplan-day-based
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        report-state="$resolve.reportState.BETRIEB.BELEGUNGSPLAN_DAY_BASED"></dvb-report-belegungsplan-day-based>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BELEGUNGSPLAN_MONTHLY_STATE = {
    name: ReportState.CONTROLLING.BELEGUNGSPLAN_MONTHLY.state,
    url: '/belegungsplan-monatsansicht',
    template: `<dvb-report-belegungsplan
        kinder-ort="$resolve.kinderOrt"
        limit-date-range-to-months="true"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        report-state="$resolve.reportState.CONTROLLING.BELEGUNGSPLAN_MONTHLY"></dvb-report-belegungsplan>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_GEBURTSTAGSLISTE_KINDER_STATE = {
    name: ReportState.BETRIEB.GEBURTSTAGSLISTE_KINDER.state,
    url: '/geburtstagsliste',
    template: `<dvb-geburtstage-report
        report-state="$resolve.reportState.BETRIEB.GEBURTSTAGSLISTE_KINDER"
        kinder-ort="$resolve.kinderOrt"
        stichtag="$resolve.stichtag"></dvb-geburtstage-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_TELEFON_EMAIL_ADRESSLISTE_STATE = {
    name: ReportState.BETRIEB.TELEFON_EMAIL_ADRESSLISTE.state,
    url: '/telefonliste',
    template: `<dvb-kinderkontaktliste-report
        report-state="$resolve.reportState.BETRIEB.TELEFON_EMAIL_ADRESSLISTE"
        parameter-kinder-orte="$resolve.parameterKinderOrte"
        stichtag="$resolve.stichtag"
        stichtag-adresse="$resolve.stichtagAdresse"></dvb-kinderkontaktliste-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_KINDERGARTENKINDER_STATE = {
    name: ReportState.BETRIEB.KINDERGARTENKINDER.state,
    url: '/kindergartenkinder',
    template: `<dvb-report-kindergartenkinder
        report-state="$resolve.reportState.BETRIEB.KINDERGARTENKINDER"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-report-kindergartenkinder>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_FRUEH_SPAET_DIENST_STATE = {
    name: ReportState.BETRIEB.FRUEH_SPAET_DIENST.state,
    url: '/fruehspaetdienst',
    template: `<dvb-frueh-spaet-dienst-report
        report-state="$resolve.reportState.BETRIEB.FRUEH_SPAET_DIENST"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        stichtag="$resolve.stichtag"></dvb-frueh-spaet-dienst-report>`,
    resolve: resolves,
    data: {
        permission: [ReportState.BETRIEB.FRUEH_SPAET_DIENST.permission, betriebbasePermission],
    },
} satisfies Ng1StateDeclaration;
export const REPORT_ABWEICHUNGEN_KINDER_STATE = {
    name: ReportState.BETRIEB.ABWEICHUNGEN_KINDER.state,
    url: '/abweichungen',
    template: `<dvb-kita-or-fraktion-report
        report-state="$resolve.reportState.BETRIEB.ABWEICHUNGEN_KINDER"
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-or-fraktion-report>`,
    resolve: resolves,
    data: {
        permission: [PERMISSION.MODULE.DYNAMISCHER_BELEGUNGSPLAN, betriebbasePermission],
    },
} satisfies Ng1StateDeclaration;

//
// controlling
//
const controllingBasePermissions = PERMISSION.KITA.VIEW_ANY;

export const REPORT_CONTROLLING_BASE_STATE = {
    name: `${REPORT_CATEGORY_BASE_STATE.name}.controlling`,
    url: '/controlling',
    template: uiView,
    data: {
        permission: controllingBasePermissions,
    },
} satisfies Ng1StateDeclaration;
export const REPORT_CONTROLLING_STATE = {
    name: ReportState.CONTROLLING.CONTROLLING.state,
    url: '/controlling',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.CONTROLLING"
        show-year="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        year="$resolve.year"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_CONTROLLING_MONTHLY_STATE = {
    name: ReportState.CONTROLLING.CONTROLLING_MONTHLY.state,
    url: '/controlling-monthly',
    template: `<dvb-kitas-report
        report-state="$resolve.reportState.CONTROLLING.CONTROLLING_MONTHLY"
        parameter-kinder-orte="$resolve.parameterKinderOrte"
        show-year="true"></dvb-kitas-report>`,
    resolve: resolves,
    data: {
        permission: PERMISSION.FEATURE.CONTROLLING_REPORT_MONTHLY + GENERAL_SUFFIX,
    },
} satisfies Ng1StateDeclaration;

export const REPORT_STUNDENBASIERTES_CONTROLLING = {
    name: ReportState.CONTROLLING.STUNDENBASIERTES_CONTROLLING.state,
    url: '/stundenbasiertes-controlling',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.STUNDENBASIERTES_CONTROLLING"
        show-year="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        year="$resolve.year"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BELEGUNGSPLAN_PLATZART_STATE = {
    name: ReportState.CONTROLLING.BELEGUNGSPLAN_PLATZART.state,
    url: '/belegungsplan',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.BELEGUNGSPLAN_PLATZART"
        show-date-range="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        show-include-extra-plaetze="true"
        show-custom-field-configuration="true"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_KENNZAHLEN_STATE = {
    name: ReportState.CONTROLLING.KENNZAHLEN.state,
    url: '/kennzahlen',
    template: `<dvb-kennzahlen-report
        report-state="$resolve.reportState.CONTROLLING.KENNZAHLEN"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kennzahlen-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_WARTELISTE_STATE = {
    name: ReportState.CONTROLLING.WARTELISTE.state,
    url: '/warteliste',
    template: `<dvb-kitas-report
        report-state="$resolve.reportState.CONTROLLING.WARTELISTE"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-kitas-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BETREUUNGSGUTSCHEINE_STATE = {
    name: ReportState.CONTROLLING.BETREUUNGSGUTSCHEINE.state,
    url: '/betreuungsgutscheine',
    template: `<dvb-kitas-with-gueltigkeit-report
        report-state="$resolve.reportState.CONTROLLING.BETREUUNGSGUTSCHEINE"
        parameter-kinder-orte="$resolve.parameterKinderOrte"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kitas-with-gueltigkeit-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_KIBON_REPORT_STATE = {
    name: ReportState.CONTROLLING.KIBON_REPORT.state,
    url: '/kibon-report',
    template: `<dvb-kitas-report
        report-state="$resolve.reportState.CONTROLLING.KIBON_REPORT"
        parameter-kinder-orte="$resolve.parameterKinderOrte"
        show-year="true"></dvb-kitas-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_LEISTUNGEN_KALKULIERT_STATE = {
    name: ReportState.CONTROLLING.LEISTUNGEN_KALKULIERT.state,
    url: '/kalkulierte-leistungen',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.LEISTUNGEN_KALKULIERT"
        show-year="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        year="$resolve.year"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_FINANZLASTENAUSGLEICH_STATE = {
    name: ReportState.CONTROLLING.FINANZLASTENAUSGLEICH.state,
    url: '/finanzlastenausgleich',
    template: `<dvb-kitas-report
        report-state="$resolve.reportState.CONTROLLING.FINANZLASTENAUSGLEICH"
        show-year="true"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-kitas-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BELEGTE_PROZENT_PUNKTE_STATE = {
    name: ReportState.CONTROLLING.BELEGTE_PROZENT_PUNKTE.state,
    url: '/belegteprozentpunkte',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.BELEGTE_PROZENT_PUNKTE"
        show-date-range="true"
        init-school-year="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_TARIFRECHNUNG_REPORTING_KALKULIERT_STATE = {
    name: ReportState.CONTROLLING.TARIFRECHNUNG_REPORTING_KALKULIERT.state,
    url: '/kalkuliertes-tarifreporting',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.TARIFRECHNUNG_REPORTING_KALKULIERT"
        show-date-range="true"
        init-school-year="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_STUNDENBLATT_STATE = {
    name: ReportState.CONTROLLING.STUNDENBLATT.state,
    url: '/stundenblatt',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.STUNDENBLATT"
        show-date-range="true"
        kinder-ort="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_MONATSBLATT_AUSZUG_STATE = {
    name: ReportState.CONTROLLING.MONATSBLATT_AUSZUG.state,
    url: '/monatsblaetter',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.MONATSBLATT_AUSZUG"
        show-report-template="false"
        stichtag="$resolve.stichtag"
        show-layout="true"
        show-stichtag="true"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"
        kinder-ort="$resolve.kinderOrt"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BETREUUNGS_STUNDEN_KONTROLLE = {
    name: ReportState.CONTROLLING.BETREUUNGS_STUNDEN_KONTROLLE.state,
    url: '/betreuungsstundenkontrolle',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.BETREUUNGS_STUNDEN_KONTROLLE"
        kinder-ort="$resolve.kinderOrt"
        show-date-range="true"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;

export const REPORT_CORONA_REFUNDS_STATE = {
    name: ReportState.CONTROLLING.CORONA_REFUNDS.state,
    url: '/corona-rueckerstattung',
    template: `<dvb-corona-rueckerstattung-report
        report-state="$resolve.reportState.CONTROLLING.CORONA_REFUNDS"
        kinder-ort-search-result="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"></dvb-corona-rueckerstattung-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_CORONA_REFUNDS_BERN_PRIVAT_STATE = {
    name: ReportState.CONTROLLING.CORONA_REFUNDS_BERN_PRIVAT.state,
    url: '/corona-rueckerstattung-bern',
    template: `<dvb-corona-rueckerstattung-report
        report-state="$resolve.reportState.CONTROLLING.CORONA_REFUNDS_BERN_PRIVAT"
        kinder-ort-search-result="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"></dvb-corona-rueckerstattung-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_CORONA_REFUNDS_LUZERN_STATE = {
    name: ReportState.CONTROLLING.CORONA_REFUNDS_LUZERN.state,
    url: '/corona-rueckerstattung-luzern',
    template: `<dvb-corona-rueckerstattung-report
        report-state="$resolve.reportState.CONTROLLING.CORONA_REFUNDS_LUZERN"
        kinder-ort-search-result="$resolve.kinderOrt"
        pre-selectable-kinder-ort="$resolve.preSelectableKinderOrt"></dvb-corona-rueckerstattung-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BSV_PRAESENZKONTROLLE_STATE = {
    name: ReportState.CONTROLLING.BSV_PRAESENZKONTROLLE.state,
    url: '/bsv-praesenzkontrolle',
    template: `<dvb-kita-or-fraktionen-report
        report-state="$resolve.reportState.CONTROLLING.BSV_PRAESENZKONTROLLE"
        kinder-ort="$resolve.kinderOrt"
        gueltigAb="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        show-hours="true"></dvb-kita-or-fraktionen-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_BSV_PRAESENZKONTROLLE_KG_STATE = {
    name: ReportState.CONTROLLING.BSV_PRAESENZKONTROLLE_KG.state,
    url: '/bsv-praesenzkontrolle-kg',
    template: `<dvb-kita-or-fraktionen-report
        report-state="$resolve.reportState.CONTROLLING.BSV_PRAESENZKONTROLLE_KG"
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-or-fraktionen-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_ANWESENHEITSSOLL = {
    name: ReportState.CONTROLLING.ANWESENHEITSSOLL_VERBRAUCH.state,
    url: 'anwesenheitssoll-verbrauch',
    template: `<dvb-kita-report
        report-state="$resolve.reportState.CONTROLLING.ANWESENHEITSSOLL_VERBRAUCH"
        show-date-range="true"
        limit-date-range-to-months="true"
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-kita-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;

export const REPORT_STUNDENVERGLEICH = {
    name: ReportState.CONTROLLING.STUNDENVERGLEICH_SOLL_IST.state,
    url: 'stundenvergleich_report',
    template: `<dvb-stundenvergleich-soll-ist-report
        report-state="$resolve.reportState.CONTROLLING.STUNDENVERGLEICH_SOLL_IST"
        limit-date-range-to-months="true"
        kind="$resolve.kind"
        gueltig-ab="$resolve.gueltigAb"></dvb-stundenvergleich-soll-ist-report>`,
    params: {
        kind: null,
    },
    resolve: {
        ...resolves,
        kind: [
            $transitionInjectionToken, ($transition$: Transition): Kind | null => {
                if ($transition$.params().kind?.id) {
                    return $transition$.params().kind;
                }

                return null;
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const REPORT_EXTERNAL_ANMELDUNGEN_STATE = {
    name: ReportState.CONTROLLING.EXTERNAL_ANMELDUNGEN.state,
    url: '/externe-anmeldungen',
    template: `<dvb-report-external-anmeldungen
        report-state="$resolve.reportState.CONTROLLING.EXTERNAL_ANMELDUNGEN"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"></dvb-report-external-anmeldungen>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;

//
// faktura
//
const fakturaBasePermissions = [PERMISSION.KITA.CONTROL_ANY, PERMISSION.MODULE.FAKTURA];

export const REPORT_FAKTURA_STATE = {
    name: `${REPORT_CATEGORY_BASE_STATE.name}.faktura`,
    url: '/faktura',
    template: uiView,
    data: {
        permission: fakturaBasePermissions,
    },
} satisfies Ng1StateDeclaration;
export const REPORT_ZAHLUNGS_EINGAENGE_STATE = {
    name: ReportState.FAKTURA.ZAHLUNGS_EINGAENGE.state,
    url: '/zahlungseingaenge',
    template: `<dvb-kitas-with-gueltigkeit-report
        report-state="$resolve.reportState.FAKTURA.ZAHLUNGS_EINGAENGE"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-kitas-with-gueltigkeit-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_LEISTUNGEN_FAKTURIERT_STATE = {
    name: ReportState.FAKTURA.LEISTUNGEN_FAKTURIERT.state,
    url: '/fakturierte-leistungen',
    template: `<dvb-kitas-report
        report-state="$resolve.reportState.FAKTURA.LEISTUNGEN_FAKTURIERT"
        show-year="true"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-kitas-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_KONTAKTPERSONEN_ZAHLUNGEN_STATE = {
    name: ReportState.FAKTURA.KONTAKTPERSONEN_ZAHLUNGEN.state,
    url: '/offene-vorauszahlungen-rueckerstattungen',
    template: `<dvb-report-kontaktperson-zahlungen
        report-state="$resolve.reportState.FAKTURA.KONTAKTPERSONEN_ZAHLUNGEN"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-report-kontaktperson-zahlungen>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_OFFENE_POSTEN_STATE = {
    name: ReportState.FAKTURA.OFFENE_POSTEN.state,
    url: '/offeneposten-kita',
    template: `<dvb-report-offene-posten
        report-state="$resolve.reportState.FAKTURA.OFFENE_POSTEN"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-report-offene-posten>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_AKTUELLE_OFFENE_POSTEN_STATE = {
    name: ReportState.FAKTURA.AKTUELLE_OFFENE_POSTEN.state,
    url: '/aktuelle-offeneposten-kita',
    template: `<dvb-report-aktuelle-offene-posten
        report-state="$resolve.reportState.FAKTURA.OFFENE_POSTEN"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-report-aktuelle-offene-posten>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;
export const REPORT_STEUERBESCHEINIGUNGEN_STATE = {
    name: ReportState.FAKTURA.STEUERBESCHEINIGUNGEN.state,
    url: '/steuerbescheinigungen',
    template: `<dvb-report-steuerbescheinigung
        report-state="$resolve.reportState.FAKTURA.STEUERBESCHEINIGUNGEN"
        kinder-ort="$resolve.kinderOrt"
        kontaktperson="$resolve.kontaktperson"
        year="$resolve.year"></dvb-report-steuerbescheinigung>`,
    params: {
        kontaktperson: null,
    },
    resolve: {
        ...resolves,
        kontaktperson: [
            '$transition$', ($transition$: Transition): Kontaktperson | null => {
                if ($transition$.params().kontaktperson?.id) {
                    return $transition$.params().kontaktperson;
                }

                return null;
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const REPORT_RECHNUNGS_GEBUEHREN_STATE = {
    name: ReportState.FAKTURA.RECHNUNGS_GEBUEHREN.state,
    url: '/rechnungsgebuehren',
    template: `<dvb-kitas-with-gueltigkeit-report
        report-state="$resolve.reportState.FAKTURA.RECHNUNGS_GEBUEHREN"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        parameter-kinder-orte="$resolve.parameterKinderOrte"
        date-format="'MMMM YYYY'"
        date-options="{minMode: 'month'}"></dvb-kitas-with-gueltigkeit-report>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;

export const REPORT_DEPOTS_STATE = {
    name: ReportState.FAKTURA.DEPOTS.state,
    url: '/depots',
    template: `<dvb-report-depots
        report-state="$resolve.reportState.FAKTURA.DEPOTS"
        parameter-kinder-orte="$resolve.parameterKinderOrte"></dvb-report-depots>`,
    resolve: resolves,
} satisfies Ng1StateDeclaration;

//
// personal
//

const personalbasePermission: RequiredPermission = {
    oneOf: [
        PERMISSION.PERSONAL.VIEW_ANY,
        PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_VIOLATIONS_REPORT + GENERAL_SUFFIX,
    ],
};
export const REPORT_PERSONAL_BASE_STATE = {
    name: `${REPORT_CATEGORY_BASE_STATE.name}.personal`,
    url: '/personal',
    template: uiView,
    data: {
        permission: personalbasePermission,
    },
} satisfies Ng1StateDeclaration;

export const REPORT_PERSONALPLANUNG_STATE = {
    name: ReportState.PERSONAL.PERSONALPLANUNG.state,
    url: '/personalplanung',
    template: `<dvb-personalplanung-report
        report-state="$resolve.reportState.PERSONAL.PERSONALPLANUNG"
        kinder-ort="$resolve.kinderOrt"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        default-gueltigkeit="$resolve.defaultGueltigkeit"></dvb-personalplanung-report>`,
    resolve: Object.assign(resolves, {
        defaultGueltigkeit: () => new Gueltigkeit(DvbDateUtil.startOfWeek(), DvbDateUtil.endOfWeek()),
    }),
    data: {
        permission: [PERMISSION.MODULE.PERSONALPLANUNG, personalbasePermission],
    },
} satisfies Ng1StateDeclaration;

export const REPORT_PERSONALPLANUNG_MONTHLY_STATE = {
    name: ReportState.PERSONAL.PERSONALPLANUNG_MONTHLY.state,
    url: '/personalplanung-monatlich',
    template: `<dvb-personalplanung-report
        report-state="$resolve.reportState.PERSONAL.PERSONALPLANUNG_MONTHLY"
        kinder-ort="$resolve.kinderOrt"></dvb-personalplanung-report>`,
    resolve: resolves,
    data: {
        permission: [PERMISSION.MODULE.PERSONALPLANUNG, personalbasePermission],
    },
} satisfies Ng1StateDeclaration;

export const REPORT_WORK_TIME_VIOLATIONS_STATE = {
    name: ReportState.PERSONAL.WORK_TIME_VIOLATIONS.state,
    url: '/worktime-violations',
    template: `<dvb-work-time-violations-report
        report-state="$resolve.reportState.PERSONAL.WORK_TIME_VIOLATIONS"
        gueltig-ab="$resolve.gueltigAb"
        gueltig-bis="$resolve.gueltigBis"
        kinder-ort="$resolve.kinderOrt"></dvb-work-time-violations-report>`,
    resolve: resolves,
    data: {
        permission: [
            PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_VIOLATIONS_REPORT + GENERAL_SUFFIX,
        ],
    },
} satisfies Ng1StateDeclaration;

export const REPORT_STATES = {
    REPORT_BASE_STATE,
    REPORT_CATEGORY_BASE_STATE,
    REPORT_CONFIG_STATE,
    REPORT_CONFIG_TEMPLATE_STATE,
    REPORT_CONFIG_CUSTOM_FIELD_STATE,

    REPORT_BETRIEB_STATE,
    REPORT_ADRESSLISTE_KINDER_KONTAKTE_STATE,
    REPORT_BELEGUNGSPLAN_STATE,
    REPORT_AKTUELLE_AENDERUNGEN_STATE,
    REPORT_BELEGUNGSPLAN_ANWESENHEITSZEITEN_STATE,
    REPORT_BELEGUNGSPLAN_DAY_BASED_STATE,
    REPORT_GEBURTSTAGSLISTE_KINDER_STATE,
    REPORT_TELEFON_EMAIL_ADRESSLISTE_STATE,
    REPORT_KINDERGARTENKINDER_STATE,
    REPORT_FRUEH_SPAET_DIENST_STATE,
    REPORT_ABWEICHUNGEN_KINDER_STATE,

    REPORT_CONTROLLING_BASE_STATE,
    REPORT_CONTROLLING_STATE,
    REPORT_CONTROLLING_MONTHLY_STATE,
    REPORT_STUNDENBASIERTES_CONTROLLING,
    REPORT_BELEGUNGSPLAN_PLATZART_STATE,
    REPORT_BELEGUNGSPLAN_MONTHLY_STATE,
    REPORT_KENNZAHLEN_STATE,
    REPORT_WARTELISTE_STATE,
    REPORT_BETREUUNGSGUTSCHEINE_STATE,
    REPORT_KIBON_REPORT_STATE,
    REPORT_LEISTUNGEN_KALKULIERT_STATE,
    REPORT_FINANZLASTENAUSGLEICH_STATE,
    REPORT_BELEGTE_PROZENT_PUNKTE_STATE,
    REPORT_TARIFRECHNUNG_REPORTING_KALKULIERT_STATE,
    REPORT_STUNDENBLATT_STATE,
    REPORT_MONATSBLATT_AUSZUG_STATE,
    REPORT_CORONA_REFUNDS_STATE,
    REPORT_CORONA_REFUNDS_BERN_PRIVAT_STATE,
    REPORT_CORONA_REFUNDS_LUZERN_STATE,
    REPORT_BSV_PRAESENZKONTROLLE_STATE,
    REPORT_BSV_PRAESENZKONTROLLE_KG_STATE,
    REPORT_ANWESENHEITSSOLL,
    REPORT_STUNDENVERGLEICH,
    REPORT_EXTERNAL_ANMELDUNGEN_STATE,
    REPORT_STUNDENKONTINGENT: REPORT_BETREUUNGS_STUNDEN_KONTROLLE,

    REPORT_FAKTURA_STATE,
    REPORT_ZAHLUNGS_EINGAENGE_STATE,
    REPORT_LEISTUNGEN_FAKTURIERT_STATE,
    REPORT_KONTAKTPERSONEN_ZAHLUNGEN_STATE,
    REPORT_OFFENE_POSTEN_STATE,
    REPORT_AKTUELLE_OFFENE_POSTEN_STATE,
    REPORT_STEUERBESCHEINIGUNGEN_STATE,
    REPORT_RECHNUNGS_GEBUEHREN_STATE,
    REPORT_DEPOTS_STATE,

    REPORT_PERSONAL_BASE_STATE,
    REPORT_PERSONALPLANUNG_STATE,
    REPORT_PERSONALPLANUNG_MONTHLY_STATE,
    REPORT_WORK_TIME_VIOLATIONS_STATE,
};
