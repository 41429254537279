/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, ElementRef, inject, Injector, input, output} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {OptionFilter} from '../../common/component/dvb-filter-list/OptionFilter';
import type {Filter} from '../../filter/shared/Filter';

@Directive({
    selector: 'dv-filter-list',
})
export class FilterListDirective extends UpgradeComponent {

    public filter = input.required<Filter[]>();
    public optionFilter = input<OptionFilter>();
    public readonly onSticky = output<void>();

    public constructor() {
        const elementRef = inject(ElementRef);
        const injector = inject(Injector);

        super('dvbFilterList', elementRef, injector);
    }
}
