/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable} from '@angular/core';
import type {ApplicationRole} from '@dv/kitadmin/models';
import {AuthStore} from '@dv/shared/angular';
import {KitaPermissionSet} from '@dv/shared/backend/model/kita-permission-set';
import {PersonalPermissionSet} from '@dv/shared/backend/model/personal-permission-set';
import {KITA_PERMISISON_SET_HIERARCHY, PERSONAL_PERMISISON_SET_HIERARCHY, UserRole} from '@dv/shared/roles';
import {AuthService} from '../../authentication/service/authService';
import type {Benutzer} from '../../benutzer/model/Benutzer';

@Injectable({
    providedIn: 'root',
})
export class AuthorisationService {
    private readonly authStore = inject(AuthStore);
    private readonly authService = inject(AuthService);

    public hasPermission(permission: string): boolean {
        return this.authStore.hasPermission(permission);
    }

    public isBenutzerPrincipal(benutzer: Benutzer): boolean {
        return this.authService.isBenutzerPrincipal(benutzer);
    }

    public isAuthorised(role: UserRole): boolean {
        return this.authService.isAuthenticated() && this.authStore.hasRole(role);
    }

    public isMandantAdmin(): boolean {
        return this.isAuthorised(UserRole.MANDANT_ADMIN);
    }

    public hasPrivilegedRole(role: ApplicationRole): boolean {
        return !!role.userRole && role.userRole !== UserRole.USER;
    }

    /**
     * Returns true when role is greater or givenRoles is empty.
     */
    public isRoleGreater(roleToCheck: UserRole | null, givenRoles: UserRole[]): boolean {
        const rolesByWeight = Object.keys(UserRole).reverse();

        return this.isPrivilegeGreater(roleToCheck, givenRoles, rolesByWeight);
    }

    /**
     * Returns <code>true</code> if the user that's being edited has a more privileged role than the current user.
     */
    public isRoleGreaterThanPrincipals(roleToCheck: UserRole | null): boolean {
        return this.isRoleGreater(roleToCheck, this.authService.getPrincipal().roles);
    }

    /**
     * Returns true when permission is greater or givenPermissions is empty.
     */
    public isPermissionGreater<T extends KitaPermissionSet | PersonalPermissionSet>(
        permissionToCheck: T | null,
        givenPermissions: T[],
        permissionSet: 'kitaPermissionSet' | 'personalPermissionSet',
    ): boolean {
        const hierarchy = permissionSet === 'kitaPermissionSet' ?
            KITA_PERMISISON_SET_HIERARCHY :
            PERSONAL_PERMISISON_SET_HIERARCHY;
        const permissionsByWeight = hierarchy.reverse();

        return this.isPrivilegeGreater(permissionToCheck, givenPermissions, permissionsByWeight);
    }

    private isPrivilegeGreater<T>(
        privilegeToCheck: T | null,
        givenPrivileges: T[],
        privilegesByWeight: T[],
    ): boolean {
        if (!privilegeToCheck) {
            return false;
        }

        // find the highest privilege in the givenPrivileges array
        const max = givenPrivileges.reduce((previousValue, currentValue) => {
            const index = privilegesByWeight.indexOf(currentValue);

            return index > previousValue ? index : previousValue;
        }, -1);

        // compare the highest privilege with the privilege to check
        return privilegesByWeight.indexOf(privilegeToCheck) > max;
    }
}
