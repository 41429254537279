/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, ITimeRange, Persisted} from '@dv/shared/code';
import {DvbDateUtil, TimeRangeUtil} from '@dv/shared/code';
import type {Translator} from '@dv/shared/translator';
import moment from 'moment';
import type {CalendarEvent} from '../../../../../calendar/timeline/model/CalendarEvent';
import {calendarEventIdGenerator} from '../../../../../calendar/timeline/model/CalendarEvent';
import type {Dienst} from '../../../../../personal/konfiguration/Dienst';
import type {AngestellteZuweisung} from '../../../../../personal/model/AngestellteZuweisung';
import type {ZuweisungZeit} from '../../../../../personal/model/ZuweisungZeit';
import {LayerType} from '../LayerType';
import type {EventGueltigkeitService} from './event-gueltigkeit.service';
import {zuweisungPauseToCalendarSubEvent} from './zuweisung-pause-to-calendar-sub-event';

export function zuweisungZeitToCalendarEvent(
    zuweisung: AngestellteZuweisung,
    zuweisungZeit: ZuweisungZeit,
    dienst: Persisted<Dienst> | undefined,
    eventGueltigkeitService: EventGueltigkeitService,
    translator: Translator,
    selectedDate?: moment.Moment,
): CalendarEvent {
    const eventRange = eventGueltigkeitService.toEventRange(zuweisungZeit, selectedDate);

    const calendarEvent = {
        id: calendarEventIdGenerator(),
        data: {zuweisung},
        ...eventRange.gueltigkeit,
        completeDuration: 0,
        spaceBefore: 0,
        spaceAfter: 0,
        backgroundColor: dienst?.backgroundColor ?? '',
        textColor: dienst?.textColor ?? '',
        hasHighLuminance: dienst?.hasHighLuminance ?? false,
        getDisplayName: () => dienst?.kuerzel ?? eventRange.gueltigkeitTxt,
        tooltip: eventRange.gueltigkeitTxt,
        layer: LayerType.ZUWEISUNG,
    };

    const zuweisungDateTimeRange = toDateTimeRange(zuweisungZeit);

    const subEvents = zuweisung.pausen
        .filter(p => containsOther(zuweisungDateTimeRange, toDateTimeRange(p)))
        .sort((a, b) => TimeRangeUtil.TIME_RANGE_COMPARATOR(a, b))
        .map(p => zuweisungPauseToCalendarSubEvent(p,
            calendarEvent.id,
            eventGueltigkeitService,
            translator,
            selectedDate));

    return {...calendarEvent, subEvents};
}

type DateTimeRange = ILimited & ITimeRange;
type IDateTimeRange = { start: moment.Moment; end: moment.Moment };

function toDateTimeRange(model: DateTimeRange): IDateTimeRange {
    const start = DvbDateUtil.setTime(moment(model.gueltigAb), model.von ?? moment(model.gueltigAb).startOf('day'));
    const end = DvbDateUtil.setTime(moment(model.gueltigBis), model.bis ?? moment(model.gueltigBis).endOf('day'));

    return {start, end};
}

function containsOther(dateTimeRange: IDateTimeRange, other: IDateTimeRange): boolean {
    return dateTimeRange.start.isSameOrBefore(other.start) && dateTimeRange.end.isSameOrAfter(other.end);
}
