<div class="row" *transloco="let t">
    <div class="col-md-6 col-md-offset-3">

        <h2>{{ title }}</h2>

        @if (model(); as model) {
            <form #form="ngForm" (ngSubmit)="submit(form)" class="dvb-form">
                <div class="form-section">

                    <div class="row form-row">
                        <div class="col-md-6">
                            <dvlib-datepicker-textfield
                                [(ngModel)]="model.gueltigAb"
                                inputId="gueltigAb"
                                name="gueltigAb"
                                [placeholder]="t('PERSONAL.ANSTELLUNG.FROM', {date: model.gueltigAb ? model.gueltigAb.format('D.M.YYYY') : ''})"
                                [required]="true"
                                [maxDate]="model.gueltigBis"
                                [disabled]="model.id !== null">
                            </dvlib-datepicker-textfield>
                        </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-md-6">
                            <h3>{{ t('PERSONAL.ANSTELLUNG.EDUCATION') }}</h3>
                            @if (ausbildungenModel().length > 0) {
                                <div class="row form-row">
                                    <div class="col-md-12">
                                        @for (ausbildung of ausbildungenModel(); let first = $first; track ausbildung.id) {
                                            <dv-ausbildung-node-checkbox [ausbildung]="ausbildung"/>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        @if (model.regulations.length > 0) {
                            <div class="col-md-6">
                                <h3>{{ t('PERSONAL.ANSTELLUNG.WORKTIMECONTROLLINGREGULATION') }}</h3>
                                <select class="form-control"
                                        name="worktimeControllingRegulation"
                                        [(ngModel)]="model.regulationId"
                                        [attr.aria-label]="t('PERSONAL.ANSTELLUNG.WORKTIMECONTROLLINGREGULATION')">
									<option [ngValue]="null">
                                        {{ t('PERSONAL.ANSTELLUNG.WORKTIMECONTROLLINGREGULATION_NONE') }}
									</option>
                                    @for (regulation of model.regulations; track regulation.id) {
                                        <option [ngValue]="regulation.id">
                                            {{ regulation.name }}
                                        </option>
                                    }
                                </select>
                            </div>
                        }
                    </div>
                </div>

                <h2>{{ t('PERSONAL.ANSTELLUNG.PENSUM_UND_VERFUEGBARKEIT') }}</h2>
                @if (model.anstellungStandorte(); as anstellungStandorte) {
                    @for (anstellungStandort of anstellungStandorte; let idx = $index; track anstellungStandort.id) {
                        <div [ngModelGroup]="'standort-' + idx">
                            <dv-anstellung-standort-form
                                [workTimeModels]="workTimeModels()"
                                [anstellungStandort]="anstellungStandort"
                                (autofillExpectedTime)="autofillExpectedTimes($event, idx)"/>
                            <button type="button"
                                    class="btn btn-link normal-abstand-bottom"
                                    (click)="removeAnstellungStandort(idx)">
                                {{ t('COMMON.LOESCHEN') }}
                            </button>
                        </div>
                    }
                }

                <div class="row form-row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-link" (click)="addAnstellungStandort()">
                            {{ t('PERSONAL.ANSTELLUNG.ADD_STANDORT') }}
                        </button>
                    </div>
                </div>

                <dv-submit-cancel-buttons [isLoading]="loadingState.isLoading()" on-cancelEvent="handleCancel()"/>
            </form>
        }
    </div>
</div>
