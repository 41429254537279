/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import {
    COLOR_PALETTE,
    DvbRestUtil,
    DvbUtil,
    isNullish,
    isPresent,
    TEXT_COLOR_PALETTE,
    TimeRange,
    TimeRangeUtil,
} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxColorsModule, validColorValidator} from 'ngx-colors';
import {Dienst} from '../../../../personal/konfiguration/Dienst';
import {Pause} from '../../../../personal/konfiguration/Pause';
import {validPause} from '../pause-form.util';

interface DienstForm {
    name: FormControl<string>;
    kuerzel: FormControl<string>;
    backgroundColor: FormControl<string>;
    textColor: FormControl<string>;
    von: FormControl<BackendLocalTimeHHMM | null>;
    bis: FormControl<BackendLocalTimeHHMM | null>;
    pausen: FormArray<FormGroup<{
        pauseVon: FormControl<BackendLocalTimeHHMM | null>;
        pauseBis: FormControl<BackendLocalTimeHHMM | null>;
        paid: FormControl<boolean>;
    }>>;
}

function validatePause(control: AbstractControl): ValidationErrors | null {
    const vonValue = control.get('pauseVon')?.value;
    const bisValue = control.get('pauseBis')?.value;

    if (isNullish(vonValue) || isNullish(bisValue)) {
        return null;
    }

    const pauseVon = DvbRestUtil.localeHHMMTimeToMomentChecked(vonValue);
    const pauseBis = DvbRestUtil.localeHHMMTimeToMomentChecked(bisValue);

    if (pauseVon.isSameOrAfter(pauseBis)) {
        return {invalidTimeRange: true};
    }

    return null;
}

function validatePausen(pausenField: AbstractControl): ValidationErrors | null {
    const timeRanges = pausenField.value
        .filter((pause: any) => isPresent(pause.pauseVon)
            && DvbUtil.isNotEmptyString(pause.pauseVon)
            && isPresent(pause.pauseBis)
            && DvbUtil.isNotEmptyString(pause.pauseBis))
        .map((pause: any) => new TimeRange(
            DvbRestUtil.localeHHMMTimeToMoment(pause.pauseVon),
            DvbRestUtil.localeHHMMTimeToMoment(pause.pauseBis),
        ));

    if (TimeRangeUtil.hasOverlappingTimeRanges(timeRanges)) {
        return {overlappingTimeRanges: true};
    }

    return null;
}

@Component({
    selector: 'dv-dienst-administration-form',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslocoModule,
        TooltipModule,
        SubmitCancelButtonsComponent,
        NgxColorsModule,
    ],
    templateUrl: './dienst-administration-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DienstAdministrationFormComponent implements OnChanges {
    private errorService = inject(ErrorService);
    private fb = inject(NonNullableFormBuilder);

    @Input() public dienst?: Dienst;
    @Input() public isLoading?: boolean;

    @Output() public readonly save: EventEmitter<Dienst> = new EventEmitter();
    @Output() public readonly cancelEdit: EventEmitter<void> = new EventEmitter();

    public form: FormGroup<DienstForm> = this.fb.group({
        name: this.fb.control('', Validators.required),
        kuerzel: this.fb.control('', Validators.required),
        backgroundColor: this.fb.control('', [Validators.required, validColorValidator()]),
        textColor: this.fb.control('', [Validators.required, validColorValidator()]),
        von: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
        bis: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
        pausen: this.fb.array([
            this.fb.group({
                pauseVon: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
                pauseBis: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
                paid: this.fb.control<boolean>(false),
            }, {validators: validatePause}),
        ], {validators: validatePausen}),
    });

    protected readonly colorPalette = COLOR_PALETTE;
    protected readonly textColorPalette = TEXT_COLOR_PALETTE;

    public ngOnChanges(changes: SimpleChanges): void {
        const dienst = changes.dienst?.currentValue;
        if (isPresent(dienst)) {
            this.initializeForm(dienst);
        }
    }

    private initializeForm(dienst: Dienst): void {
        this.form.setControl('pausen', this.fb.array(
            dienst.pausen.map(pause => this.fb.group({
                pauseVon: this.fb.control<BackendLocalTimeHHMM | null>(
                    DvbRestUtil.momentTolocaleHHMMTime(pause.von), Validators.required),
                pauseBis: this.fb.control<BackendLocalTimeHHMM | null>(
                    DvbRestUtil.momentTolocaleHHMMTime(pause.bis), Validators.required),
                paid: this.fb.control<boolean>(pause.paid),
            }, {validators: validatePause})),
            {validators: validatePausen},
        ));

        this.form.setValue({
            name: dienst.name ?? '',
            kuerzel: dienst.kuerzel ?? '',
            backgroundColor: dienst.backgroundColor ?? '',
            textColor: dienst.textColor ?? '',
            von: DvbRestUtil.momentTolocaleHHMMTime(dienst.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(dienst.bis),
            pausen: dienst.pausen.map(pause => ({
                pauseVon: DvbRestUtil.momentTolocaleHHMMTime(pause.von),
                pauseBis: DvbRestUtil.momentTolocaleHHMMTime(pause.bis),
                paid: pause.paid,
            })),
        });

        this.form.updateValueAndValidity();
    }

    public submitForm(): void {
        this.errorService.clearAll();

        if (!this.form.controls.pausen.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INVALID_PAUSE');

            return;
        }

        if (!this.form.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const value = this.form.value;
        const pausen = value.pausen ?? [];
        const filteredPausen = pausen
            .filter(pause => isPresent(pause.pauseVon) && isPresent(pause.pauseBis))
            .map(pause => new Pause(
                DvbRestUtil.localeHHMMTimeToMomentChecked(pause.pauseVon),
                DvbRestUtil.localeHHMMTimeToMomentChecked(pause.pauseBis),
                pause.paid,
            ));

        const zuweisungsZeit = new TimeRange(
            DvbRestUtil.localeHHMMTimeToMomentChecked(value.von),
            DvbRestUtil.localeHHMMTimeToMomentChecked(value.bis),
        );

        if (!validPause(zuweisungsZeit, filteredPausen)) {
            this.errorService.addValidationError('ERRORS.ERR_INVALID_PAUSE');

            return;
        }

        const dienst = new Dienst(
            null,
            DvbRestUtil.localeHHMMTimeToMoment(value.von),
            DvbRestUtil.localeHHMMTimeToMoment(value.bis),
            value.name,
            filteredPausen,
            value.kuerzel,
            value.backgroundColor,
            value.textColor);

        this.save.emit(dienst);
    }

    public addPause(): void {
        this.form.controls.pausen.push(this.fb.group({
            pauseVon: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
            pauseBis: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
            paid: this.fb.control<boolean>(false),
        }, {validators: validatePause}));
    }

    public removePause(index: number): void {
        this.form.controls.pausen.removeAt(index);
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancelEdit.emit();
    }
}
