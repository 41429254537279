<form #form="ngForm" (ngSubmit)="submitForm(form)" class="dvb-form" *transloco="let t">
    <div class="row smaller-abstand-bottom">
        <div class="col-md-12"><b>{{ t('PERSONAL.DIENSTKONFIGURATION.WORK_TIME') }}</b></div>
    </div>
    <div class="row">
        <fieldset>
            <legend class="sr-only">
                {{ t('PERSONAL.DIENSTKONFIGURATION.WORK_TIME') }}
            </legend>
            <div [ngClass]="model.pausen!.length > 0 ? 'col-md-4': 'col-md-6'">
                <input
                    type="time"
                    class="form-control"
                    name="von"
                    [(ngModel)]="model.von"
                    validVon
                    required
                    [timeEnd]="model.bis"
                    [placeholder]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.VON')"
                    [tooltip]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.VON')"
                    [attr.aria-label]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.VON')"/>
            </div>
            <div [ngClass]="model.pausen!.length > 0 ? 'col-md-4': 'col-md-6'">
                <input
                    type="time"
                    class="form-control"
                    name="bis"
                    [(ngModel)]="model.bis"
                    validBis
                    required
                    [timeStart]="model.von"
                    [placeholder]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS')"
                    [tooltip]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS')"
                    [attr.aria-label]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS')"/>
            </div>
        </fieldset>
    </div>
    <div class="row small-abstand-top">
        <div class="col-md-12"><b>{{ t('PERSONAL.DIENSTKONFIGURATION.PAUSE') }}</b></div>
    </div>
    @for (pause of model.pausen; let index = $index; track pause) {
        <fieldset>
            <legend class="sr-only">
                {{ t('PERSONAL.DIENSTKONFIGURATION.PAUSE_LEGEND', {index: index + 1}) }}
            </legend>
            <div class="row smaller-abstand-top">
                <div class="col-md-4">
                    <input type="time"
                           class="form-control"
                           [name]="'pause-von-' + index"
                           [(ngModel)]="pause.von"
                           validVon
                           required
                           [timeEnd]="pause.bis"
                           [placeholder]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_VON')"
                           [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_VON')"
                           [attr.aria-label]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_VON')">
                </div>
                <div class="col-md-4">
                    <input type="time"
                           class="form-control"
                           [name]="'pause-bis-' + index"
                           [(ngModel)]="pause.bis"
                           validBis
                           required
                           [timeStart]="pause.von"
                           [placeholder]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS')"
                           [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS')"
                           [attr.aria-label]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS')">
                </div>
                <div class="col-md-3">
                    <div class="checkbox">
                        <label [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.PAID_TOOLTIP')">
                            <input type="checkbox"
                                   [checked]="pause.paid"
                                   (change)="onPausePaidChanged(pause)"
                                   [name]="'paid-' + index">
                            <span>{{ t('PERSONAL.DIENSTKONFIGURATION.PAID') }}</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-1 form-linker">
                    <button type="button"
                            dvlibButton="link-button"
                            icon="trash"
                            [title]="t('PERSONAL.DIENSTKONFIGURATION.REMOVE_PAUSE')"
                            (click)="removePause(index)">
                    </button>
                </div>
            </div>
        </fieldset>
    }
    <div class="row smaller-abstand-top">
        <div class="col-md-12">
            <button type="button"
                    dvlibButton="link-button"
                    (click)="addPause()">
                {{ t('PERSONAL.DIENSTKONFIGURATION.ADD_PAUSE') }}
            </button>
        </div>
    </div>

    <dvlib-button-list class="normal-abstand-top">
        <button dvlibButton="link-button" type="submit">
            {{ t('COMMON.SPEICHERN') }}
        </button>

        <button dvlibButton="link-button" (click)="cancel()">
            {{ t('COMMON.ABBRECHEN') }}
        </button>
    </dvlib-button-list>
</form>
