/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AttachmentsDownloadComponent} from '../attachments-download/attachments-download.component';
import {ITimelineEntry} from '../ITimelineEntry';
import {ITimelineService} from '../ITimelineService';

@Component({
    selector: 'dv-timeline-detail',
    imports: [TooltipModule, AttachmentsDownloadComponent],
    templateUrl: './timeline-detail.component.html',
    styleUrl: './timeline-detail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDetailComponent implements OnInit {
    @Input({required: true}) public timelineService!: ITimelineService<any>;
    @Input({required: true}) public timelineEntry!: ITimelineEntry;
    @HostBinding('class.full-width-block') private readonly fullWidthStyle = true;

    public timelineDate: string = '';
    public timelineTime: string = '';
    public tootlipText: string = '';

    public ngOnInit(): void {
        this.timelineDate = this.timelineService.getTimelineDate(this.timelineEntry);
        this.timelineTime = this.timelineService.getTimelineTime(this.timelineEntry);
        this.tootlipText = this.timelineService.getTooltipText(this.timelineEntry);
    }
}
