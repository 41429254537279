/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    BelegteEinheit,
    Belegung,
    Bewerbung,
    ISelectedFirma,
    Kind,
    KinderOrt,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import {Firma, isBewerbungsplanError, Kita} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {DayOfWeek, Persisted, SearchResultEntry} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbDateUtil, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {from, take, tap} from 'rxjs';
import type {DvbBewerbungsplanService} from '../../../common/service/dvbBewerbungsplanService';
import type {BewerbungenService} from '../../../common/service/rest/kind/bewerbungenService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import type {WochenplanService} from '../../../common/service/rest/wochenplanService';
import {TAGESINFO_STATE} from '../../kind-states';
import {AbstractBewerbungsplanController} from '../../models/AbstractBewerbungsplanController';
import {ZUWEISEN_MULTIPLE_SIMPLE_STATE} from '../../zuweisung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenplan: '<',
        kind: '<',
        expandAb: '<?',
        expandBis: '<?',
    },
    template: require('./dvb-kind-betreuung.html'),
    controllerAs: 'vm',
};

export class DvbKindBetreuung extends AbstractBewerbungsplanController implements angular.IController {
    public static override $inject: readonly string[] = [
        '$scope',
        '$state',
        '$q',
        'bewerbungenService',
        'kindService',
        'errorService',
        'dvbBewerbungsplanService',
        'fraktionService',
        'dialogService',
        'wochenplanService',
    ];

    private static readonly ERR_REQUIRED_PLATZ: string = 'ERRORS.ERR_REQUIRED_PLATZ';

    public kind!: Kind;

    public today: moment.Moment = DvbDateUtil.today();
    public expandAb?: moment.Moment | null;
    public expandBis?: moment.Moment | null;

    public bewerbung: Bewerbung | null = null;
    public zuweisungsKitas: KinderOrt[] = [];

    public showAustritt: boolean = false;

    public readonly TAGESINFO_STATE = TAGESINFO_STATE;

    private validBewerbung: Bewerbung | null = null;

    public constructor(
        private $scope: angular.IScope,
        private $state: StateService,
        private $q: angular.IQService,
        private bewerbungenService: BewerbungenService,
        private kindService: KindService,
        errorService: ErrorService,
        private dvbBewerbungsplanService: DvbBewerbungsplanService,
        private fraktionService: FraktionService,
        private dialogService: DialogService,
        private wochenplanService: WochenplanService,
    ) {
        super(errorService);
    }

    public override $onInit(): void {
        super.$onInit();

        if (!DvbDateUtil.isValidMoment(this.expandAb)) {
            this.expandAb = this.today;
        }

        if (!DvbDateUtil.isValidMoment(this.expandBis)) {
            this.expandBis = this.today;
        }

        if (this.kind.bewerbung) {
            this.bewerbung = this.kind.bewerbung;
            if (this.bewerbung.wochenplanId !== this.wochenplan.id) {
                this.wochenplanService.getWochenplanBewerbung(this.bewerbung)
                    .then(wochenplan => {
                        this.wochenplan = wochenplan;
                        this.$onInit();
                    });

                return;
            }
            this.validBewerbung = angular.copy(this.bewerbung);
            // der Wochenplan muss vorher initialisert worden sein!
            this.dvbBewerbungsplanService.setSelectionToZeitraumFelder(
                this.wochenplan,
                this.bewerbung.belegteEinheiten,
                this.zeitraumFelder,
            );
        }

        DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(this.kind.belegungen);
        this.initZuweisungsKitas();
    }

    public isValidBetreuungVonDatum(param: any): boolean {
        if (!param) {
            // allowing empty date
            return true;
        }
        const valid = DvbDateUtil.isValidMoment(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_BETREUUNG_VON');

        return valid;
    }

    public updateBewerbung(): void {
        const bewerbung = checkPresent(this.bewerbung);
        const valid = bewerbung.isValid();
        this.errorService.handleValidationError(bewerbung.isPlatzSelected(), DvbKindBetreuung.ERR_REQUIRED_PLATZ);

        if (!valid) {
            this.bewerbung = angular.copy(this.validBewerbung);

            return;
        }

        this.bewerbungenService.update(bewerbung).then(() => {
            this.validBewerbung = angular.copy(bewerbung);
            this.kind.bewerbung = bewerbung;
        }).catch(e => {
            // TODO wollen wir wirklich die ganze Bewerbung kopieren und wiederherstellen?
            this.bewerbung = angular.copy(this.validBewerbung);
            this.kind.bewerbung = this.bewerbung;
            console.error('Bewerbung kann nicht gespeichert werden', e);
        });
    }

    public confirmDeleteBewerbung(bewerbung: Bewerbung): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.BETREUUNGSWUNSCH',
            confirm: () => from(this.bewerbungenService.deleteBewerbung(checkPresent(bewerbung.id)))
                .pipe(take(1), tap(() => {
                    this.$scope.$emit('betreuungsfaktorChange');
                    this.bewerbung = null;
                    this.kind.bewerbung = null;

                    this.initZuweisungsKitas();
                })),
        });
    }

    // eslint-disable-next-line complexity
    public override handleZeitraumFeldToggle(zeitraumFeld: ZeitraumFeld): void {
        if (this.hasInvalidBelegteEinheit && zeitraumFeld.dayOfWeek !== this.invalidDay) {
            // Disable modification of other days
            zeitraumFeld.selected = !zeitraumFeld.selected;

            return;
        }

        this.clearBelegteEinheitenErrors(zeitraumFeld.dayOfWeek);

        const belegteEinheiten = angular.copy(this.bewerbung!.belegteEinheiten);
        try {
            const updatedBelegteEinheit = this.dvbBewerbungsplanService.handleToggledZeitraumFeld(zeitraumFeld, {
                zeitraumFelder: this.zeitraumFelder,
                wochenplan: this.wochenplan,
            });
            const existingBelegteEinheit = this.dvbBewerbungsplanService.findBelegteEinheit(belegteEinheiten,
                zeitraumFeld.dayOfWeek);
            if (existingBelegteEinheit === null && updatedBelegteEinheit.belegungsEinheitId) {
                belegteEinheiten.push(updatedBelegteEinheit);
                this.saveBetreuungsWuensche(belegteEinheiten);

                return;
            }

            if (existingBelegteEinheit &&
                !angular.equals(existingBelegteEinheit.belegungsEinheitId,
                    updatedBelegteEinheit.belegungsEinheitId)) {

                if (updatedBelegteEinheit.belegungsEinheitId === null) {
                    DvbUtil.removeFromArray(existingBelegteEinheit, belegteEinheiten);
                } else {
                    existingBelegteEinheit.belegungsEinheitId = updatedBelegteEinheit.belegungsEinheitId;
                }

                this.saveBetreuungsWuensche(belegteEinheiten);
            }
        } catch (err) {
            if (isBewerbungsplanError(err)) {
                this.addBelegteEinheitenError(err);

                return;
            }
            throw err;
        }
    }

    public saveBetreuungsGruende(): void {
        this.kindService.setBetreuungsGruendeByIds(checkPresent(this.kind.id),
            this.kind.betreuungsGruendeIds)
            .catch(e => {
                console.error('BetreuungsGruende konnten nicht gespeichert werden', e);
            });
    }

    public addKita(kitaItem: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = kitaItem.text;
        kita.id = kitaItem.id;
        const bewerbung = checkPersisted(this.bewerbung);
        if (!bewerbung.addKita(kita)) {
            return;
        }

        this.bewerbungenService.addKita(bewerbung.id, kita.id).then(() => {
            this.$scope.$emit('betreuungsfaktorChange');
            this.initZuweisungsKitas();
        });
    }

    public removeKita(kita: Persisted<KinderOrt>): void {
        const bewerbung = checkPersisted(this.bewerbung);
        this.bewerbungenService.removeKita(bewerbung.id, kita.id).then(() => {
            bewerbung.removeKita(kita);
            this.$scope.$emit('betreuungsfaktorChange');
            this.initZuweisungsKitas();
        }).catch(error => {
            console.error('Kita kann nicht entfernt werden', error);
        });
    }

    public addFirma(firmaItem: SearchResultEntry): void {
        const persistedFirma = checkPersisted(new Firma(firmaItem.id, firmaItem.text));
        const firma: ISelectedFirma = Object.assign(persistedFirma, {selected: true});

        if (checkPresent(this.bewerbung).addFirmaToSelection(firma)) {
            this.toggleFirma(firma);
        }
    }

    public toggleFirma(firma: ISelectedFirma): void {
        const bewerbung = checkPersisted(this.bewerbung);

        if (firma.selected) {
            this.errorService.clearErrorByMsgKey(DvbKindBetreuung.ERR_REQUIRED_PLATZ);
            if (bewerbung.addFirma(firma)) {
                this.bewerbungenService.addFirma(bewerbung.id, firma.id).catch(error => {
                    console.error('Firma kann nicht hinzugefügt werden', error);
                });
            }
        } else {
            bewerbung.removeFirma(firma);
            if (bewerbung.isPlatzSelected()) {
                this.bewerbungenService.removeFirma(bewerbung.id, firma.id).then(() => {
                    this.validBewerbung = angular.copy(this.bewerbung);
                }).catch(error => {
                    this.bewerbung = angular.copy(this.validBewerbung);
                    console.error('Firma kann nicht entfernt werden', error);
                });
            } else {
                this.errorService.addValidationError(DvbKindBetreuung.ERR_REQUIRED_PLATZ);
                this.bewerbung = angular.copy(this.validBewerbung);
            }
        }
    }

    public editBelegung(date: moment.Moment, belegung: Belegung): void {
        this.fraktionService.fetchKinderOrteForBelegung(belegung).then(kinderOrte => {
            this.zuweisenMultiple(kinderOrte, date);
        });
    }

    public zuweisen(kinderOrt: KinderOrt, date: moment.Moment): void {
        this.zuweisenMultiple([kinderOrt], date);
    }

    public assignAll(): void {
        const params = {
            kinderOrtIds: this.zuweisungsKitas.map(DvbUtil.mapToIdChecked),
            kindId: checkPresent(this.kind.id),
        };
        this.$state.go(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, params);
    }

    public onBelegungRemoved(): void {
        this.initZuweisungsKitas();
    }

    public kindDirektZuweisen(kita: KinderOrt): void {
        this.$state.go(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, {
            kinderOrtIds: [kita.id!],
            kindId: this.kind.id,
            direktzuweisung: true,
        });
    }

    protected override clearBelegteEinheitenErrors(dayOfWeek: DayOfWeek): void {
        super.clearBelegteEinheitenErrors(dayOfWeek);
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_UNKNOWN_WOCHENTAG');
    }

    private saveBetreuungsWuensche(belegteEinheiten: BelegteEinheit[]): void {
        const valid = Array.isArray(belegteEinheiten) && belegteEinheiten.length > 0 && !this.hasInvalidBelegteEinheit;
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_BELEGUNGSEINHEIT');
        if (valid && this.bewerbung) {
            this.bewerbung.belegteEinheiten = belegteEinheiten;
            this.bewerbungenService.setBetreuungsWuensche(this.bewerbung.id!, belegteEinheiten);
        }
    }

    private initZuweisungsKitas(): void {
        this.getBelegteKitas().then(kitas => {
            this.zuweisungsKitas = this.bewerbung ?
                DvbUtil.uniqueArray(kitas.concat(this.bewerbung.kitas), DvbUtil.mapToId) :
                kitas;
        });
    }

    private getBelegteKitas(): angular.IPromise<KinderOrt[]> {
        if (!this.kind || !Array.isArray(this.kind.belegungen)) {
            return this.$q.resolve([]);
        }

        return this.fraktionService.fetchKinderOrteForBelegungen(this.kind.belegungen);
    }

    private zuweisenMultiple(kinderOrte: KinderOrt[], date: moment.Moment): void {
        let params = {
            kinderOrtIds: kinderOrte.map(DvbUtil.mapToIdChecked),
            kindId: checkPresent(this.kind.id),
        };
        if (date) {
            params = Object.assign(params, {zuweisungAb: DvbRestUtil.momentToLocalDate(date)});
        }
        this.$state.go(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, params);
    }
}

componentConfig.controller = DvbKindBetreuung;
angular.module('kitAdmin').component('dvbKindBetreuung', componentConfig);
