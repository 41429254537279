<div class="row">
    <div class="col-md-7">
        <dv-crud-accordion [items]="items()"
                           [title]="'PERSONAL.DIENSTKONFIGURATION.TITLE' | transloco"
                           [isEmpty]="isEmpty()"
                           [emptyText]="'PERSONAL.DIENSTKONFIGURATION.NONE' | transloco"
                           [createMode]="createMode()"
                           [createText]="'PERSONAL.DIENSTKONFIGURATION.NEW' | transloco"
                           [showDeleteDialog]="showDeleteDialog()"
                           [entityName]="'PERSONAL.DIENSTKONFIGURATION.SINGULAR' | transloco"
                           [isLoading]="isLoading()"
                           [headerTemplate]="headerTemplate"
                           [editModeTemplate]="editModeTemplate"
                           [createModeTemplate]="createModeTemplate"
                           (toggleExpanded)="service.toggleExpanded($event)"
                           (editEntity)="service.setEditMode($event)"
                           (deleteEntity)="service.deleteDienst$.next($event.id)"
                           (confirmDelete)="service.confirmDeleteDienst$.next()"
                           (hideDeleteDialog)="service.hideDeleteDialog()"
                           (showCreateMode)="service.enableCreateMode()">
        </dv-crud-accordion>

        <ng-template [dvCrudAccordionTemplate]="items()" let-item #headerTemplate>
            <div class="full-width-block">
                <div class="row layout-row items-center">
                    <div class="col-xs-9 layout-row items-center flex-start">
                        <div class="badge"
                             [ngStyle]="{backgroundColor: item.entity.backgroundColor,
                                color: item.entity.textColor}">
                            {{ item.entity.kuerzel }}
                        </div>
                        <span class="small-abstand-left">{{ item.entity.name }}</span>
                    </div>
                    <div class="col-xs-3 tabular-nums text-right">
                        <span>{{
                                'COMMON.DATERANGE' | transloco:{
                                    von: item.entity.von!.format('HH:mm'),
                                    bis: item.entity.bis!.format('HH:mm')
                                }
                            }}</span>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template [dvCrudAccordionTemplate]="items()" let-item #editModeTemplate>
            <dv-dienst-administration-form [dienst]="item.entity"
                                           [isLoading]="item.isLoading"
                                           (save)="update(item.entity.id, $event)"
                                           (cancelEdit)="service.setReadonlyMode(item.entity)">
            </dv-dienst-administration-form>
        </ng-template>

        <ng-template #createModeTemplate>
            <dv-dienst-administration-form (save)="service.createDienst$.next($event)"
                                           (cancelEdit)="service.disableCreateMode()">
            </dv-dienst-administration-form>
        </ng-template>
    </div>
</div>
