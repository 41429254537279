/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass, NgStyle} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import type {Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {Dienst} from '../../../../../personal/konfiguration/Dienst';
import {defaultTerminColor, terminDragData} from '../service/personalplanung-termine.store';

@Component({
    selector: 'dv-zuweisbare-dienste',
    imports: [
        TranslocoModule,
        TooltipModule,
        NgStyle,
        NgClass,
    ],
    styleUrl: './zuweisbare-dienste.component.scss',
    templateUrl: './zuweisbare-dienste.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZuweisbareDiensteComponent {

    @Input({required: true}) public dienste: Persisted<Dienst>[] = [];
    @Input() public readonly = false;

    @Output() public readonly dragActive: EventEmitter<boolean> = new EventEmitter();

    public defaultTerminColor = defaultTerminColor;
    public terminDragData = terminDragData;

    public dragStart(event: DragEvent, data: string): void {
        event.dataTransfer?.setData('text/plain', data);
        this.dragActive.emit(true);
    }
}
