/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxDienst} from '@dv/shared/backend/model/jax-dienst';
import type {DayOfWeek, IDisplayable, IPersistable, IRestModel, IZeitraum, TagUndZeitraum} from '@dv/shared/code';
import {ColorUtil, DvbDateUtil, DvbRestUtil, TimeRangeUtil} from '@dv/shared/code';
import type moment from 'moment';
import {Pause} from './Pause';

export class Dienst implements IZeitraum, IPersistable, IDisplayable, IRestModel<JaxDienst> {
    public durationInMinutes: number = 0;
    public hasHighLuminance: boolean = false;

    public constructor(
        public id: string | null = null,
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
        public name: string | null = null,
        public pausen: Pause[] = [],
        public kuerzel: string | null = null,
        public backgroundColor: string | null = null,
        public textColor: string | null = null,
    ) {
        this.durationInMinutes = bis && von ? DvbDateUtil.getTimeDiff(von, bis) : 0;
        this.hasHighLuminance = this.backgroundColor ? ColorUtil.hasHighLuminance(this.backgroundColor) : false;
    }

    public static apiResponseTransformer(data: JaxDienst): Dienst {
        return new Dienst(
            data.id,
            DvbRestUtil.localeHHMMTimeToMoment(data.von),
            DvbRestUtil.localeHHMMTimeToMoment(data.bis),
            data.name,
            data.pausen.map(Pause.apiResponseTransformer),
            data.kuerzel,
            data.colors.background,
            data.colors.text);
    }

    public isSame(zeitraumFeld: TagUndZeitraum, dayOfWeek: DayOfWeek): boolean {
        return zeitraumFeld.zeitraum.id === this.id && zeitraumFeld.dayOfWeek === dayOfWeek;
    }

    public getDisplayName(): string {
        if (this.name === null) {
            return TimeRangeUtil.formatTimeRange(this);
        }

        return this.name;
    }

    public toRestObject(): JaxDienst {
        return {
            id: this.id ?? undefined,
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von)!,
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis)!,
            name: this.name!,
            pausen: this.pausen.map(pause => pause.toRestObject()),
            kuerzel: this.kuerzel!,
            colors: {
                background: this.backgroundColor!,
                text: this.textColor!,
            },
        };
    }
}
