<form class="dvb-form"
      #customRechnungPositionForm="ngForm"
      name="customRechnungPositionForm"
      (submit)="submit(customRechnungPositionForm)"
      *transloco="let t"
      novalidate>
    @for (position of customPositions; track position) {
        <div class="row">
            <div class="col-xs-4">
                <input type="text"
                       class="form-control"
                       [name]="'customPositionText' + $index"
                       [maxlength]="255"
                       [placeholder]="t('COMMON.POSITION')"
                       [(ngModel)]="position.text"
                       [tooltip]="t('COMMON.POSITION')"
                       [disabled]="position.notManualMandantCustomPos"
                       required>
            </div>
            <div class="col-xs-2 text-right text-nowrap">
                <input type="number"
                       class="form-control"
                       [name]="'customPositionCount' + $index"
                       placeholder="0.00"
                       [(ngModel)]="position.count"
                       pattern="^-{0,1}\d*(\.\d{0,2})?$"
                       [tooltip]="t('COMMON.ANZAHL')"
                       [disabled]="position.notManualMandantCustomPos"
                       step="0.01"
                       required>
            </div>
            <div class="col-xs-2 text-right text-nowrap">
                <input type="number"
                       class="form-control"
                       [name]="'customPositionCost'+$index"
                       placeholder="0.00"
                       [(ngModel)]="position.cost"
                       pattern="^-{0,1}\d*(\.\d{0,2})?$"
                       [tooltip]="t('COMMON.BETRAG')"
                       [disabled]="position.notManualMandantCustomPos"
                       step="0.01"
                       required>
            </div>
            <div class="col-xs-2 text-right text-nowrap">
                <input type="number"
                       class="form-control"
                       [name]="'customPositionTotal' + $index"
                       placeholder="0.00"
                       [value]="(position.cost || 0) * (position.count || 0) | number: '1.2-2'"
                       [tooltip]="t('COMMON.TOTAL')"
                       disabled>
            </div>
            <div class="col-xs-2 form-linker">
                <button type="button" class="btn btn-link" (click)="removePosition(position)">
                    {{ t('COMMON.LOESCHEN') }}
                </button>
            </div>
        </div>
    }
    <div class="row normal-abstand-top big-abstand-bottom">
        <div class="col-md-6">
            <button type="button" class="btn btn-link" (click)="addPosition()">
                + <span>{{ t('ADMINISTRATION.CUSTOM_RECHNUNG_POSITION_HINZUFUEGEN') }}</span>
            </button>
        </div>
    </div>
    <dv-submit-cancel-buttons [isLoading]="formContext().isLoading"
                              (cancelEvent)="onCancel.emit()">
    </dv-submit-cancel-buttons>
</form>
