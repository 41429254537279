/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {EntityCounts} from '@dv/shared/backend/model/entity-counts';
import {TranslocoDirective} from '@jsverse/transloco';

@Component({
    selector: 'dv-entity-counts',
    imports: [
        TranslocoDirective,
        DecimalPipe,
    ],
    templateUrl: './entity-counts.component.html',
    styles: `
        :host {
            display: block;
        }

        table th:nth-child(2),
        table td:nth-child(2) {
            text-align: right;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCountsComponent {
    public entityCounts = input.required<EntityCounts>();
}
