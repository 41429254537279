import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAngestellteZuweisungZeit} from '@dv/shared/backend/model/jax-angestellte-zuweisung-zeit';
import type {ITimeRange} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class AngestellteZuweisungZeit implements ITimeRange {

    public constructor(
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
        public kinderOrtId: EntityId | null = null,
        public fraktionId: EntityId | null = null,
        public kinderOrtDisplayName: string | null = null,
        public kinderOrtFraktionDisplayName: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxAngestellteZuweisungZeit): AngestellteZuweisungZeit {
        return new AngestellteZuweisungZeit(
            DvbRestUtil.localeHHMMTimeToMomentChecked(data.gueltigkeit.von),
            DvbRestUtil.localeHHMMTimeToMomentChecked(data.gueltigkeit.bis),
            data.kinderOrtId,
            data.fraktionId,
            data.kinderOrtDisplayName,
            data.kinderOrtFraktionDisplayName,
        );
    }
}
