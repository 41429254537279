/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {CollapsiblePanelComponent} from '@dv/kitadmin/ui';
import {DvbUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {AngestellteKorrespondenz} from '../../../../communication/models/AngestellteKorrespondenz';

@Component({
    selector: 'dv-angestellte-korrespondenz-timeline-details',
    imports: [TranslocoModule, CollapsiblePanelComponent],
    templateUrl: './angestellte-korrespondenz-timeline-details.component.html',
    styles: `:host {
        display: block;
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AngestellteKorrespondenzTimelineDetailsComponent {
    public korrespondenz = input.required<AngestellteKorrespondenz>();

    public headerContent = computed(() => this.korrespondenz().emailContentPlainText || this.korrespondenz().content);

    public showEmailContentHTML = computed(() => DvbUtil.isNotEmptyString(this.korrespondenz().emailContentHTML));
    public showEmailContentPlainText = computed(() => {
        return !this.showEmailContentHTML() && DvbUtil.isNotEmptyString(this.korrespondenz().emailContentPlainText);
    });
}
