/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import type {TarifParameter} from '@dv/kitadmin/models';
import {DvbUtil} from '@dv/shared/code';

@Pipe({
    name: 'tarifParameterBadge',
})
export class TarifParameterBadge implements PipeTransform {

    public transform(param: TarifParameter): string {
        return DvbUtil.orderByDisplayName(param.tarife.slice())
            .map(tarif => tarif.getDisplayName())
            .join('\n');
    }
}
