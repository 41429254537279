/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {formViewProvider} from '@dv/shared/angular';
import {AusbildungNodeCheckboxFormModel} from './ausbildung-node-checkbox.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-ausbildung-node-checkbox',
    imports: [
        FormsModule,
    ],
    templateUrl: './ausbildung-node-checkbox.component.html',
    viewProviders: [formViewProvider],
})

export class AusbildungNodeCheckboxComponent {
    @Input({required: true}) public ausbildung!: AusbildungNodeCheckboxFormModel;
}
