/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, inject, Input, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import type {DialogModel} from '@dv/shared/angular';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import type {ICustomField, Persisted} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {BsModalRef} from 'ngx-bootstrap/modal';
import type {Observable} from 'rxjs';
import {BehaviorSubject, take} from 'rxjs';

export interface CustomFieldLinkedDialogModel extends DialogModel {
    confirm: (customFieldId: string) => Observable<unknown>;
}

@Component({
    selector: 'dv-custom-field-linked',
    templateUrl: './custom-field-linked.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        LoadingButtonComponent,
        ButtonListComponent,
    ],
})
export class CustomFieldLinkedComponent implements CustomFieldLinkedDialogModel {
    private readonly bsModalRef = inject(BsModalRef);

    @Input() public customFields: Persisted<ICustomField>[] = [];
    @Input() public confirm!: (customFieldId: string) => Observable<unknown>;

    @ViewChild('customField') private customField!: ElementRef;

    private isLoadingSubject$ = new BehaviorSubject(false);
    public isLoading$ = this.isLoadingSubject$.asObservable();

    public submit(): void {
        if (!TypeUtil.isFunction(this.confirm)) {
            this.bsModalRef.hide();

            return;
        }

        this.isLoadingSubject$.next(true);

        this.confirm(this.customField.nativeElement.value).pipe(take(1)).subscribe({
            next: () => {
                this.isLoadingSubject$.next(false);
                this.bsModalRef.hide();
            },
            error: () => {
                this.isLoadingSubject$.next(false);
            },
        });
    }

    public hide(): void {
        this.bsModalRef.hide();
    }
}
