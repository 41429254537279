/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {WritableSignal} from '@angular/core';
import {ChangeDetectionStrategy, Component, inject, Input, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import {
    Rechnungsaufteilung,
    RechnungsAufteilungType,
    Rechnungsempfaenger,
    RelationshipWithKontaktperson,
} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, DisplayNamePipe} from '@dv/shared/angular';
import {KinderService} from '@dv/shared/backend/api/kinder.service';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxRelationshipsWithKontaktpersonen} from '@dv/shared/backend/model/jax-relationships-with-kontaktpersonen';
import {checkPresent, displayableComparator, DvbUtil, Persisted, restIncludes} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {Observable} from 'rxjs';
import {map, Subject, switchMap, tap} from 'rxjs';
import {RechnungsAufteilungModel} from './RechnungsAufteilungModel';

const MAX_PROZENT_TOTAL = 100;

export interface KindRechnungsAufteilungDialogModel extends CompletableDialogModel<null> {
    kind: Persisted<Kind>;
}

@Component({
    selector: 'dv-kind-rechnungsaufteilung',
    templateUrl: './kind-rechnungs-aufteilung.component.html',
    styleUrl: './kind-rechnungs-aufteilung.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        TooltipModule,
        DisplayNamePipe,
        ButtonListComponent,
    ],
})
export class KindRechnungsAufteilungComponent extends CompletableDialogBase<null>
    implements KindRechnungsAufteilungDialogModel {
    private readonly errorService = inject(ErrorService);
    private readonly kinderService = inject(KinderService);

    private _kind!: Persisted<Kind>;

    @Input()
    public set kind(value: Persisted<Kind>) {
        this._kind = value;
        this.kindChanged$.next(value);
    }

    public kontaktModel: WritableSignal<RechnungsAufteilungModel[]> = signal([]);

    public readonly RECHNUNG_AUFTEILUNG_TYPE = RechnungsAufteilungType;

    private kindChanged$: Subject<Persisted<Kind>> = new Subject();

    private kontaktpersonen$ = this.kindChanged$.pipe(
        map(kind => kind.id),
        switchMap(id => this.fetchKontaktpersonen$(id)),
        map(parseAufteilungModels),
    );

    public constructor() {
        super();
        this.kontaktpersonen$.pipe(
            tap(model => this.kontaktModel.set(model)),
            takeUntilDestroyed(),
        ).subscribe();
    }

    public onTypeChange(kontakt: RechnungsAufteilungModel): void {
        kontakt.value = kontakt.valueBeforeTypeChange[kontakt.type];
        this.recalculateProzentRest(kontakt);
    }

    public onValueChange(kontakt: RechnungsAufteilungModel): void {
        this.updateValue(kontakt, kontakt.value);

        this.recalculateProzentRest(kontakt);
    }

    public onSave(formValid: boolean | null): void {
        if (formValid === false) {
            return;
        }

        this.errorService.clearAll();

        let sum = 0;
        this.kontaktModel().forEach(kontakt => {
            if (kontakt.type === RechnungsAufteilungType.PROZENT) {
                sum += kontakt.value ?? 0;
            }
        });

        if (sum !== MAX_PROZENT_TOTAL) {
            this.errorService.addValidationError('KIND.KINDKONTAKT_RECHNUNGSAUFTEILUNG_ERROR_PROZENT_TOTAL', {
                total: sum,
                remainder: MAX_PROZENT_TOTAL - sum,
            });

            return;
        }

        const rechnungsaufteilung = new Rechnungsaufteilung();
        this.kontaktModel().forEach(kontakt => {
            if (kontakt.value) {
                const rempf = new Rechnungsempfaenger(kontakt.type);
                rempf.kindId = this._kind.id;
                rempf.kontaktpersonId = kontakt.kontaktperson.id;
                rempf.value = kontakt.value;
                rechnungsaufteilung.rechnungsempfaenger.push(rempf);
            }
        });

        this.kinderService.updateRechnungsaufteilung$({
            kindId: this._kind.id,
            jaxRechnungsAufteilungen: rechnungsaufteilung.toRestObject(),
        }).pipe(
            tap(() => this.submit(null)),
        ).subscribe();
    }

    public onCancel(): void {
        this.errorService.clearAll();
        this.hide();
    }

    private updateValue(kontakt: RechnungsAufteilungModel, value: number | null): void {
        kontakt.value = value;
        kontakt.valueBeforeTypeChange[kontakt.type] = value;
    }

    private recalculateProzentRest(changedKontakt: RechnungsAufteilungModel): void {
        const prozentKontakte = this.kontaktModel()
            .filter(k => k.type === RechnungsAufteilungType.PROZENT && k !== changedKontakt && k.value !== null);

        if (prozentKontakte.length !== 1) {
            return;
        }

        if (changedKontakt.type === RechnungsAufteilungType.PROZENT) {
            const restProzent = Math.max(0, MAX_PROZENT_TOTAL - (changedKontakt.value ?? 0));
            this.updateValue(prozentKontakte[0], restProzent === 0 ? null : restProzent);
        } else {
            this.updateValue(prozentKontakte[0], MAX_PROZENT_TOTAL);
        }
    }

    private fetchKontaktpersonen$(kindId: EntityId): Observable<JaxRelationshipsWithKontaktpersonen> {
        return this.kinderService.getAllRelationshipsWithKontaktpersonen$({
            kindId,
            kontakte: restIncludes('(kontaktperson.fields(adressen), relationship)'),
        });
    }
}

function parseAufteilungModels(data: JaxRelationshipsWithKontaktpersonen): RechnungsAufteilungModel[] {
    return data.relationshipsWithKontaktpersonen
        .filter(r => DvbUtil.isNotEmptyArray(r.kontaktperson?.adressen))
        .map(RelationshipWithKontaktperson.apiResponseTransformer)
        .map(r => toRechnungsAufteilungModel(r))
        .sort((a, b) => displayableComparator(a.kontaktperson, b.kontaktperson));
}

function toRechnungsAufteilungModel(entity: RelationshipWithKontaktperson): RechnungsAufteilungModel {
    let type = RechnungsAufteilungType.PROZENT;
    let value = null;
    if (entity.relationship.isRechnungsempfaenger()) {
        const rempf = checkPresent(entity.relationship.rechnungsempfaenger);
        type = rempf.type;
        value = rempf.value;
    }

    return new RechnungsAufteilungModel(checkPresent(entity.kontaktperson), type, value);
}
