/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseRechnungsPosition, MandantRechnungsPosition} from '@dv/kitadmin/models';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
    selector: 'dv-administration-mandant-rechnungs-position',
    imports: [
        TranslocoModule,
        DecimalPipe,
    ],
    templateUrl: './administration-mandant-rechnungs-position.component.html',
    styleUrl: './administration-mandant-rechnungs-position.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationMandantRechnungsPositionComponent implements OnInit {

    @Input({required: true}) public rechnungsPosition!: MandantRechnungsPosition | BaseRechnungsPosition;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public readonly onDelete = new EventEmitter<void>();

    public isDeletable: boolean = false;
    public totalAmount: number = 0;

    public ngOnInit(): void {
        this.isDeletable = !(this.rechnungsPosition instanceof MandantRechnungsPosition);
        this.totalAmount = this.rechnungsPosition.cost! * this.rechnungsPosition.count!;
    }
}
