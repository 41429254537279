<form (ngSubmit)="submit(null)" novalidate>
    <div class="modal-body">
        <h1 [transloco]="header"></h1>

        <div class="row text-left">
            <div class="col-sm-6">
                <h3 [transloco]="entityDataHeader"></h3>
                <p [tooltip]="'KIBON.KIND_MATCHING.KINDER_NAME' | transloco"
                   [ngClass]="{'not-equal': kindNameMismatch}">
                    {{ entity.kind | displayName }}
                </p>
                <p [tooltip]="'KIBON.KIND_MATCHING.GEBURTSDATUM_KIND' | transloco"
                   [ngClass]="{'not-equal': birthdateMismatch}"
                   [transloco]="'COMMON.GEBURTSTAG_MIT_DATUM'"
                   [translocoParams]="{date: entity.kind.geburtsdatum | dvLocalDateFormat: 'D. MMMM YYYY'}"></p>
                <p [tooltip]="'KIBON.KIND_MATCHING.GESUCHSTELLER_NAME' | transloco"
                   [ngClass]="{'not-equal': kontaktNameMismatch}">
                    {{ entity.gesuchsteller | displayName }}
                </p>
                <p [tooltip]="'COMMON.DATE_RANGE' | transloco"
                   [transloco]="'COMMON.GUELTIGKEIT_VON_BIS'"
                   [translocoParams]="{dateAb: entity.gueltigAb | dvLocalDateFormat, dateBis: entity.gueltigBis | dvLocalDateFormat}">
                </p>
                <p [tooltip]="'COMMON.KINDERORT.SINGULAR' | transloco">
                    {{ entity.institution.name }}
                </p>
            </div>
            <div class="col-sm-6">
                <h3 [transloco]="'KIBON.KIND_MATCHING.KINDERORT_DATEN'"></h3>
                <p [tooltip]="'KIBON.KIND_MATCHING.KINDER_NAME' | transloco"
                   [ngClass]="{'not-equal': kindNameMismatch}">
                    {{ kind | displayName }}
                </p>
                <p [tooltip]="'KIBON.KIND_MATCHING.GEBURTSDATUM_KIND' | transloco"
                   [ngClass]="{'not-equal': birthdateMismatch}"
                   [transloco]="'COMMON.GEBURTSTAG_MIT_DATUM'"
                   [translocoParams]="{date: kind.getDisplayGeburtsdatum()}"></p>
                <p [tooltip]="'KIBON.KIND_MATCHING.KONTAKT_NAME_MF' | transloco: {count: kontaktNameMismatch ? kind.kontakte.length : 1}"
                   [ngClass]="{'not-equal': kontaktNameMismatch}">
                    {{ kontaktNameMismatch ? mismatchingKontaktNames : (kinderKontakt! | displayName) }}
                </p>
            </div>
        </div>

        <div class="row normal-abstand-top">
            <div class="col-md-12">
                <p [transloco]="'KIBON.KIND_MATCHING.VALUES_NOT_EQUAL'"></p>
            </div>
        </div>

        <div class="modal-footer">
            <dv-submit-cancel-buttons [isLoading]="isLoading$ | async"
                                      [submitLabel]="'COMMON.ZUWEISEN'"
                                      (cancelEvent)="hide()">
            </dv-submit-cancel-buttons>
        </div>
    </div>
</form>
