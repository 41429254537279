<div class="dvb-work-time-violations-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.reportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <div class="row form-row">
            <div class="col-xs-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" ng-model="vm.allKinderOrte">
                        <span data-translate="COMMON.ALLE_KINDERORTE"></span>
                    </label>
                </div>
            </div>
        </div>

        <div ng-if="!vm.allKinderOrte" class="row">
            <div class="col-xs-12">
                <dvb-search-get-single-entity
                    as-col="true"
                    entity-to-search="KINDERORT"
                    ng-change="vm.updateKinderOrt()"
                    ng-model="vm.kinderOrt"
                    placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                    required>
                </dvb-search-get-single-entity>
            </div>
        </div>

        <dvb-datepicker-text-field ng-change="vm.updateState()"
                                   ng-model="vm.gueltigAb"
                                   dvb-begin-of-month="true"
                                   ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
        </dvb-datepicker-text-field>
        <dvb-datepicker-text-field ng-change="vm.updateState()"
                                   ng-model="vm.gueltigBis"
                                   dvb-end-of-month-parser="true"
                                   ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
        </dvb-datepicker-text-field>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
