/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Gebuehr} from '@dv/kitadmin/models';
import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {CONFIG} from '../../../../config';

@Injectable({
    providedIn: 'root',
})
export class KontaktpersonService {
    private http = inject(HttpClient);

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kontaktpersonen`;

    /**
     * @param params.kitaIds list of kitaIds to filter
     */
    public getUnsettledDepots$(
        kontaktpersonId: string,
        params?: RestInclude & { kitaIds?: string[] },
    ): Observable<Persisted<Gebuehr>[]> {
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/depot/unsettled`;

        return this.http.get<{ gebuehren: Persisted<Gebuehr>[] }>(url + DvbRestUtil.encodeMatrixParams(params))
            .pipe(
                map(values => DvbRestUtil.transformArray(values.gebuehren, Gebuehr).map(checkPersisted)),
            );
    }
}
