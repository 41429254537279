<div ng-class="{'dialog': vm.isInDialog(), 'login': !vm.isAuthenticated()}">
    <div class="message-container">
        <div class="dvb-error-messages" uib-collapse="vm.errors.length === 0">
            <div class="kitadmin-content container floating-text">
                <i ng-show="vm.errors.length > 0" class="alarm-icon fa fa-exclamation-circle"></i>
                <div>
                    <div ng-repeat="error in vm.errors | orderBy:'severity'">
                        <dvlib-error [error]="error"></dvlib-error>
                    </div>
                </div>
                <button class="btn btn-link"
                        aria-label="{{'ERRORS.CLOSE_ERRORBAR' | translate}}"
                        ng-show="vm.errors.length > 0"
                        ng-click="vm.onClear()">
                    <i class="close-icon fa fa-times"></i>
                </button>
            </div>
        </div>

        <div class="dvb-success-messages" uib-collapse="vm.successes.length === 0">
            <div class="kitadmin-content container floating-text">
                <i ng-show="vm.successes.length > 0" class="alarm-icon fa fa-exclamation-circle"></i>
                <div>
                    <div ng-repeat="success in vm.successes | orderBy:'severity'">
                        <dvlib-error [error]="success"></dvlib-error>
                    </div>
                </div>
                <button class="btn btn-link"
                        aria-label="{{'ERRORS.CLOSE_SUCCESSBAR' | translate}}"
                        ng-show="vm.successes.length > 0"
                        ng-click="vm.onClear()">
                    <i class="close-icon fa fa-times"></i>
                </button>
            </div>
        </div>
    </div>
</div>
