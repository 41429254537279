/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable} from '@angular/core';
import {Gebuehr, Rechnung, TempBlob} from '@dv/kitadmin/models';
import {KontaktpersonZahlungService} from '@dv/shared/backend/api/kontaktperson-zahlung.service';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {NoContent, Persisted} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil, isNullish} from '@dv/shared/code';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class KontaktpersonenZahlungService {
    private readonly api = inject(KontaktpersonZahlungService);

    public deleteVorauszahlung$(vorauszahlungId: EntityId): Observable<unknown> {
        return this.api.deleteVorauszahlung$({zahlungId: vorauszahlungId});
    }

    public deleteRueckerstattung$(rueckerstattungId: EntityId): Observable<unknown> {
        return this.api.deleteRueckerstattung$({zahlungId: rueckerstattungId});
    }

    public rueckerstattungAuszahlen$(
        rueckerstattungId: EntityId,
        auszahlungsDatum: moment.Moment,
        generatePainFile: boolean,
        zahlungszweck: string,
    ): Observable<TempBlob | NoContent> {
        return this.api.rueckerstattungAuszahlen$({
            zahlungId: rueckerstattungId,
            auszahlungsDatum: DvbRestUtil.momentToLocalDateChecked(auszahlungsDatum),
            jaxRueckerstattungAuszahlen: {
                generatePainFile,
                zahlungszweck,
            },
        }).pipe(
            map(response => isNullish(response) ? null : TempBlob.apiResponseTransformer(response)),
        );
    }

    public getVorauszahlungRechnung$(kontaktpersonZahlungId: EntityId): Observable<Rechnung | NoContent> {
        return this.api.getVorauszahlungRechnung$({zahlungId: kontaktpersonZahlungId}).pipe(
            map(v => v === null ? null : Rechnung.apiResponseTransformer(v)),
        );
    }

    public getRueckerstattungRechnung$(kontaktpersonZahlungId: EntityId): Observable<Rechnung | NoContent> {
        return this.api.getRueckerstattungRechnung$({zahlungId: kontaktpersonZahlungId}).pipe(
            map(v => v === null ? null : Rechnung.apiResponseTransformer(v)),
        );
    }

    public getGebuehr$(gebuehrId: EntityId): Observable<Persisted<Gebuehr>> {
        return this.api.getGebuehr$({gebuehrId}).pipe(
            map(Gebuehr.apiResponseTransformer),
            map(checkPersisted),
        );
    }

    public deleteGebuehr$(gebuehrId: EntityId): Observable<unknown> {
        return this.api.deleteGebuehr$({gebuehrId});
    }

    public updateGebuehr$(gebuehr: Persisted<Gebuehr>): Observable<unknown> {
        return this.api.updateGebuehr$({
            gebuehrIdMatrix: {},
            gebuehrId: gebuehr.id,
            jaxGebuehr: gebuehr.toRestObject(),
        });
    }

    public settleDepotWithRechnung$(
        depotId: EntityId,
        rechnungsId: EntityId,
        zahlungsDatum: moment.Moment,
    ): Observable<unknown> {
        const datum = DvbRestUtil.momentToLocalDateChecked(zahlungsDatum);

        return this.api.settleWithRechnung$({rechnungsId, depotId, zahlungsDatum: datum});
    }
}
