import type {WritableSignal} from '@angular/core';
import {Injectable, signal} from '@angular/core';
import {CalendarEvent} from '../../../../../calendar/timeline/model/CalendarEvent';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class PersonalplanungEditTimesStore {

    public open = signal(false);
    public target: WritableSignal<Element | undefined> = signal(undefined);
    public calendarEvent: WritableSignal<CalendarEvent | undefined> = signal(undefined);

    public openEditTimesOverlay(
        event: CalendarEvent,
        target: Element,
    ): void {
        this.open.set(true);
        this.target.set(target);
        this.calendarEvent.set(event);
    }

    public closeOverlay(): void {
        this.open.set(false);
        this.target.set(undefined);
        this.calendarEvent.set(undefined);
    }
}
