/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import type {CompletableDialogModel, ConfirmDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {KibonRefNrService} from '@dv/shared/backend/api/kibon-ref-nr.service';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxKibonExchangeKindIgnoredRefNr} from '@dv/shared/backend/model/jax-kibon-exchange-kind-ignored-ref-nr';
import {TranslocoModule} from '@jsverse/transloco';

export interface KibonIgnoreRefNrDialogModel<T = null> extends CompletableDialogModel<T> {
    title: string;
    kindId: EntityId;
    remainingRefNumbers: string[];
    ignoredRefNumbers: JaxKibonExchangeKindIgnoredRefNr[];
}

@Component({
    selector: 'dv-kibon-ignore-ref-nr-dialog',
    templateUrl: './kibon-ignore-ref-nr-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslocoModule,
        FormsModule,
    ],
})
export class KibonIgnoreRefNrDialogComponent extends CompletableDialogBase<null> implements ConfirmDialogModel<null> {

    @Input({required: true}) public title!: string;
    @Input({required: true}) public kindId!: EntityId;
    @Input() public remainingRefNumbers: string[] = [];
    @Input() public ignoredRefNumbers: JaxKibonExchangeKindIgnoredRefNr[] = [];

    private readonly refNrService = inject(KibonRefNrService);

    public ignoreRefNr(refNr: string): void {
        this.refNrService.ignoreRefNrForKind$({
            kindId: this.kindId,
            body: refNr,
        }).subscribe(() => {
            this.submit(null);
        });
    }
    public deleteIgnoredRefNr(refNr: JaxKibonExchangeKindIgnoredRefNr): void {
        this.refNrService.deleteIgnoredRefNr$({
            refId: refNr.id,
        }).subscribe(() => {
            this.submit(null);
        });
    }
}
