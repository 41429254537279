import {Pipe, PipeTransform} from '@angular/core';
import {DvMinutesFormatPipe} from '@dv/shared/angular';
import {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import {isPresent} from '@dv/shared/code';

@Pipe({
    name: 'workTimeModelDisplayName',
})
export class WorkTimeModelDisplayNamePipe implements PipeTransform {

    private dvMinutesFormatPipe = new DvMinutesFormatPipe();

    public transform(model: Pick<JaxReadWorkTimeModel, 'weeklyMinutes' | 'name'>): string {
        const expectedHoursAndMinutes = this.dvMinutesFormatPipe.transform(model.weeklyMinutes);

        return isPresent(model.name) && model.name !== '' ?
            `${model.name} ${expectedHoursAndMinutes}` :
            `${expectedHoursAndMinutes}`;
    }
}
