import type {JaxAngestellteZuweisungPause} from '@dv/shared/backend/model/jax-angestellte-zuweisung-pause';
import type {ITimeRange} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class AngestellteZuweisungPause implements ITimeRange {

    public constructor(
        public von: moment.Moment,
        public bis: moment.Moment,
        public paid: boolean,
    ) {
    }

    public static apiResponseTransformer(data: JaxAngestellteZuweisungPause): AngestellteZuweisungPause {
        return new AngestellteZuweisungPause(
            DvbRestUtil.localeHHMMTimeToMomentChecked(data.gueltigkeit.von),
            DvbRestUtil.localeHHMMTimeToMomentChecked(data.gueltigkeit.bis),
            data.paid ?? false,
        );
    }
}
