/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable} from '@angular/core';
import type {BankStatementEntryFilter} from '@dv/kitadmin/models';
import type {MemoizeFunction} from '@dv/shared/code';
import {Memoizer, stringUnion} from '@dv/shared/code';
import type {TaskFilter} from '../../../dashboard/models/TaskFilter';
import type {RechnungenFilter} from '../../../filter/RechnungenFilter';
import type {FilterProperties} from '../../../kinderort/service/kinderFilter-models/FilterProperties';
import {SessionCache} from './SessionCache';
import {UserSettingsMemoizer} from './UserSettingsMemoizer';

export const USER_SETTINGS = stringUnion(
    'BANK_STATEMENT_ENTRY_FILTER',
    'BANK_STATEMENT_ENTRY_ITEMS_PER_PAGE',
    'BANK_STATEMENT_ENTRY_ZUWEISEN_RECHNUNGEN_FILTER',
    'BANK_STATEMENT_ENTRY_ZUWEISEN_ITEMS_PER_PAGE',
    'KITA_KINDER_FILTER',
    'KITA_FAKTURA_RECHNUNGEN_FILTER',
    'KITA_FAKTURA_ITEMS_PER_PAGE',
    'KONTAKTPERSON_EMAILS_PER_PAGE',
    'TASK_FILTER');

export type UserSettingsKey = typeof USER_SETTINGS.type;

export type UserSettingsTypeMap = {
    BANK_STATEMENT_ENTRY_FILTER: BankStatementEntryFilter;
    BANK_STATEMENT_ENTRY_ITEMS_PER_PAGE: number;
    BANK_STATEMENT_ENTRY_ZUWEISEN_RECHNUNGEN_FILTER: RechnungenFilter;
    BANK_STATEMENT_ENTRY_ZUWEISEN_ITEMS_PER_PAGE: number;
    KITA_KINDER_FILTER: FilterProperties;
    KITA_FAKTURA_RECHNUNGEN_FILTER: Memoizer<any>;
    KITA_FAKTURA_ITEMS_PER_PAGE: number;
    KONTAKTPERSON_EMAILS_PER_PAGE: number;
    TASK_FILTER: TaskFilter;
};

/**
 * These settings are allowed to stay in the cache even after logout or principal change
 */
const INSENSITIVE: UserSettingsKey[] = [
    'BANK_STATEMENT_ENTRY_ITEMS_PER_PAGE',
    'BANK_STATEMENT_ENTRY_ZUWEISEN_RECHNUNGEN_FILTER',
    'BANK_STATEMENT_ENTRY_ZUWEISEN_ITEMS_PER_PAGE',
    'KITA_KINDER_FILTER',
    'KITA_FAKTURA_RECHNUNGEN_FILTER',
    'KITA_FAKTURA_ITEMS_PER_PAGE',
    'KONTAKTPERSON_EMAILS_PER_PAGE',
    'TASK_FILTER',
];

export type UserSettingsMemoizedTypeMap = {
    KITA_FAKTURA_RECHNUNGEN_FILTER: RechnungenFilter;
};

export type UserSettingsMemoizedKey = keyof UserSettingsMemoizedTypeMap;

export enum CacheStrategy {
    SESSION = 'SESSION',
}

@Injectable({
    providedIn: 'root',
})
export class UserSettingsStore {
    private sessionCache = inject(SessionCache);

    public put<T extends UserSettingsKey>(
        key: T,
        value?: UserSettingsTypeMap[T],
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): void {
        this.getCache(cacheStrategy).put(key, value);
    }

    public get<T extends UserSettingsKey>(
        key: T,
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): UserSettingsTypeMap[T] | undefined {

        return this.getCache(cacheStrategy).get(key);
    }

    public getOrDefault<T extends UserSettingsKey>(
        key: T,
        defaultSupplier: () => UserSettingsTypeMap[T],
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): UserSettingsTypeMap[T] {

        const cache = this.getCache(cacheStrategy);
        if (!cache.get(key)) {
            cache.put(key, defaultSupplier());
        }

        return cache.get(key)!;
    }

    public initMemoizer<T extends UserSettingsMemoizedKey>(
        key: T,
        func: MemoizeFunction<UserSettingsMemoizedTypeMap[T]>,
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): UserSettingsMemoizer<T> {

        return new UserSettingsMemoizer(() => this.initMemoizerInternal(key, func, cacheStrategy));
    }

    public getMemoized<T extends UserSettingsMemoizedKey>(
        key: T,
        param: string,
        func: MemoizeFunction<UserSettingsMemoizedTypeMap[T]>,
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): UserSettingsMemoizedTypeMap[T] {

        const memoizer = this.initMemoizerInternal(key, func, cacheStrategy);

        return memoizer.get(param);
    }

    public clearMemoized<T extends UserSettingsMemoizedKey>(
        key: T,
        param?: string,
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): void {
        const cache = this.getCache(cacheStrategy);

        const memoizedValues = cache.get(key);
        if (!memoizedValues) {
            return;
        }

        if (param === undefined) {
            memoizedValues.reset();
        } else {
            memoizedValues.clear(param);
        }
    }

    public reset(cacheStrategy?: CacheStrategy, all: boolean = true): void {
        if (!cacheStrategy) {
            this.resetAll();

            return;
        }

        if (cacheStrategy === CacheStrategy.SESSION) {
            this.resetSessionCache(all);
        } else {
            throw new Error(`No reset implemented for cacheStrategy ${String(cacheStrategy)}`);
        }
    }

    public resetAll(all: boolean = true): void {
        this.resetSessionCache(all);
    }

    public resetSessionCache(all: boolean = true): void {
        if (all) {
            this.sessionCache.reset();
        } else {
            const keysToRemove = USER_SETTINGS.values.filter(k => !INSENSITIVE.includes(k));
            this.sessionCache.clear(keysToRemove);
        }
    }

    private getCache(cacheStrategy: CacheStrategy = CacheStrategy.SESSION): SessionCache {
        if (cacheStrategy === CacheStrategy.SESSION) {
            return this.sessionCache;
        }

        throw new Error('Could not find cache strategy implementation');
    }

    // If the internal Memoizer is leeked outside this services, the cache clearing wont have any effect!
    // The calling code might still have a reference to the internal Memoizer, which would then not be destroyed &
    // reset. Thus, initMemoizer above uses UserSettingsMemoizer for the same convenience, but keeping the references
    // internal to the cache.
    private initMemoizerInternal<T extends UserSettingsMemoizedKey>(
        key: T,
        func: MemoizeFunction<UserSettingsMemoizedTypeMap[T]>,
        cacheStrategy: CacheStrategy = CacheStrategy.SESSION,
    ): Memoizer<UserSettingsMemoizedTypeMap[T]> {

        return this.getOrDefault(key, () => new Memoizer(func), cacheStrategy);
    }
}
