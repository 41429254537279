<div class="dvb-kinder-ort-schliesstage-verlauf">

    <div
        ng-repeat="kinderOrtSchliesstage in (sortedSchliesstage = (vm.kinderOrtSchliesstage | toArray:false | orderBy:'gueltigAb':true))">

        <!-- Beendete Schliesstage -->
        <dvb-collapsible-panel
            ng-show="sortedSchliesstage[$index-1].gueltigAb.diff(kinderOrtSchliesstage.gueltigBis, 'days') > 1 || $first && vm.isSchliesstageBeendet(kinderOrtSchliesstage)"
            is-first="$first"
            is-collapsed="true"
            dvb-auth
            require-permission="{{'shared:schliesstage_manage:' + kinderOrtSchliesstage.kitaId}}"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{kinderOrtSchliesstage.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{kinderOrtSchliesstage.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.revert(kinderOrtSchliesstage)">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="kinderOrtSchliesstage"
                               is-first="$first && !vm.isSchliesstageBeendet(kinderOrtSchliesstage)"
                               expand-ab="vm.today"
                               expand-bis="vm.today"
                               dvb-auth
                               require-permission="{{'shared:schliesstage_manage:' + kinderOrtSchliesstage.kitaId}}"
                               on-unauthorised-disable>
            <panel-header>
                <dvb-kinder-ort-schliesstage-heading kinder-ort-schliesstage="kinderOrtSchliesstage">
                </dvb-kinder-ort-schliesstage-heading>
            </panel-header>
            <panel-content>
                <dvb-kinder-ort-schliesstage-content kinder-ort-schliesstage="kinderOrtSchliesstage"
                                                     on-remove="vm.remove(kinderOrtSchliesstage)">
                </dvb-kinder-ort-schliesstage-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
