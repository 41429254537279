import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, Input, signal} from '@angular/core';
import {NonNullableFormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {EingewoehnungPosition} from '@dv/kitadmin/models';
import {SearchEntityComponent} from '@dv/kitadmin/search';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {DatepickerTextfieldComponent, DateSwitcherComponent} from '@dv/shared/angular';
import {KinderEingewoehnungPositionenService} from '@dv/shared/backend/api/kinder-eingewoehnung-positionen.service';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'dv-eingewoehnungposition-erfassen',
    imports: [
        ReactiveFormsModule,
        SearchEntityComponent,
        SubmitCancelButtonsComponent,
        TranslocoModule,
        DatepickerTextfieldComponent,
        TooltipModule,
        DateSwitcherComponent,
    ],
    templateUrl: './eingewoehnungposition-erfassen.component.html',
    styles: ':host { display: block; }',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EingewoehnungpositionErfassenComponent {
    private readonly errorService = inject(ErrorService);
    private readonly fb = inject(NonNullableFormBuilder);
    private readonly location = inject(Location);
    private readonly kinderEingewoehnungPositionenService = inject(KinderEingewoehnungPositionenService);

    @Input({required: true}) public kindId!: string;

    public isLoading = signal(false);

    public form = this.fb.group({
        kinderOrt: this.fb.control<SearchResultEntry | undefined>(undefined, Validators.required),
        gueltigAb: this.fb.control<BackendLocalDate | undefined>(undefined, Validators.required),
        gueltigBis: this.fb.control<BackendLocalDate | undefined>(undefined, Validators.required),
        leistungsMonatAb: this.fb.control<BackendLocalDate | undefined>(DvbDateUtil.todayAsLocalDate(),
            Validators.required),
        days: this.fb.control<number | undefined>(undefined),
        totalBetrag: this.fb.control<number | undefined>(undefined, [
            Validators.required,
            Validators.min(1),
        ]),
    });

    public submitForm(): void {
        this.errorService.clearAll();
        if (!this.form.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const formValue = this.form.value;

        const gueltigAb = DvbDateUtil.toMoment(formValue.gueltigAb);
        const gueltigBis = DvbDateUtil.toMoment(formValue.gueltigBis);
        if (!gueltigAb?.isValid() ||
            !gueltigBis?.isValid() ||
            gueltigAb.isAfter(gueltigBis)) {
            this.errorService.addValidationError('ERRORS.ERR_INVALID_DATES');

            return;
        }

        this.isLoading.set(true);

        const eingewoehnungPosition = new EingewoehnungPosition(
            null,
            this.kindId,
            formValue.kinderOrt!.id,
            DvbDateUtil.toMoment(formValue.gueltigAb),
            DvbDateUtil.toMoment(formValue.gueltigBis),
            formValue.days,
            DvbDateUtil.toMoment(formValue.leistungsMonatAb),
            formValue.totalBetrag,
        );

        this.kinderEingewoehnungPositionenService.createEingewoehnungPosition$({
            jaxEingewoehnungPosition: eingewoehnungPosition.toRestObject(),
        })
            .subscribe({
                next: () => {
                    this.isLoading.set(false);
                    this.location.back();
                },
                error: () => {
                    this.isLoading.set(false);
                },
            });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.location.back();
    }

    public leistungsMonatDateChanged(date: BackendLocalDate): void {
        this.form.patchValue({leistungsMonatAb: date});
    }
}
