<dvlib-dialog [open]="open()" (dialogClose)="hide()" *transloco="let t">
    <ng-template>
        <form class="kind-austritt-form"
              name="kindAustrittForm"
              #kindAustrittForm="ngForm"
              (ngSubmit)="submit(kindAustrittForm)">
            <div class="modal-body">
                <h1>{{ t('KIND.KIND_AUSTRITT_HEADING') }}</h1>

                <div class="row">
                    <div class="col-md-6 col-md-offset-3">
                        <dvlib-datepicker-textfield name="austrittsDatum"
                                                    [(ngModel)]="state().austrittsDatum"
                                                    required
                                                    autofocus
                                                    [placeholder]="t('KIND.KIND_AUSTRITT_AUSTRITTSDATUM')">
                        </dvlib-datepicker-textfield>
                    </div>
                    <div class="col-md-6 col-md-offset-3 normal-abstand-top">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       name="provisorisch"
                                       [(ngModel)]="state().provisorisch">
                                <span>{{ t('COMMON.PROVISORISCH') }}</span>
                            </label>
                        </div>
                        <div class="checkbox normal-abstand-top">
                            <label [tooltip]="hauptkontaktHasNoEmailAddress() ? t('KIND.KONTAKTPERSON_AUSTRITT_NO_EMAIL') : undefined">
                                <input type="checkbox"
                                       name="mailBestaetigung"
                                       value="false"
                                       [(ngModel)]="state().mailBestaetigung"
                                       [disabled]="hauptkontaktHasNoEmailAddress()">
                                <span>{{ t('COMMUNICATION.BULK_EMAILS.TERMINATION_MAIL') }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer big-abstand-top">
                <dv-submit-cancel-buttons [isLoading]="state().isLoading()"
                                          [submitLabel]="'KIND.AUSTRITT'"
                                          (cancelEvent)="hide()"/>
            </div>
        </form>
    </ng-template>
</dvlib-dialog>
