/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpErrorResponse} from '@angular/common/http';
import type {Signal, WritableSignal} from '@angular/core';
import {computed, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AnonymousService} from '@dv/shared/backend/api/anonymous.service';
import {JaxOidcIdentityProvider} from '@dv/shared/backend/model/jax-oidc-identity-provider';
import {DvbUtil, hasOwnPropertyGuarded, isPresent, LogFactory} from '@dv/shared/code';
import {OAuthService} from 'angular-oauth2-oidc';
import {filter, firstValueFrom} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {IDP_HINT_LOCAL_STORAGE, OIDC_CONFIG} from './oidc-config';

const LOG = LogFactory.createLog('OidcService');

@Injectable({
    providedIn: 'root',
})
export class OidcService {

    private oauthService = inject(OAuthService);
    private anonymousService = inject(AnonymousService);

    private oidcIdpFetched = signal(false);
    private oidcIdentityProvider: WritableSignal<string | undefined> = signal(undefined);

    public initialized: Signal<boolean> = computed(() => {
        return this.oidcIdpFetched();
    });
    public oidcEnabled: Signal<boolean> = computed(() => {
        const fetched = this.oidcIdpFetched();
        const provider = this.oidcIdentityProvider();

        return fetched && isPresent(provider);
    });

    public tokenReceived$ = this.oauthService.events.pipe(
        filter(e => e.type === 'token_received'),
    );

    public constructor(
    ) {
        this.oauthService.events.pipe(
            takeUntilDestroyed(),
        ).subscribe({
            next: value => LOG.trace(value),
            error: err => LOG.error(err),
        });
    }

    public isUnrecoverableIdpError(errorResponse: HttpErrorResponse): boolean {
        return this.isIdpError(errorResponse) && this.isExpiredCode(errorResponse);
    }

    private isIdpError(errorResponse: HttpErrorResponse): boolean {
        if (!this.oauthService.issuer) {
            return false;
        }

        return errorResponse.url?.startsWith(this.oauthService.issuer) ?? false;
    }

    private isExpiredCode(errorResponse: HttpErrorResponse): boolean {
        return errorResponse.error.message === 'invalid_grant' &&
            errorResponse.error.error_description === 'Code not valid';
    }

    /**
     * fetches the OIDC identity provider from the backend, if not already done, and initializes the OIDC service.
     */
    public fetchIssuerAndInit(idpHint: unknown): Promise<unknown> {
        if (!this.oidcIdpFetched()) {
            return firstValueFrom(this.anonymousService.getOidcIdentityProvider$().pipe(
                switchMap((provider: JaxOidcIdentityProvider) => {
                    this.oidcIdentityProvider.set(provider.provider as any);
                    this.oidcIdpFetched.set(true);

                    return this.initWithFetchedProvider(idpHint);
                })));
        }

        return this.initWithFetchedProvider(idpHint);
    }

    private initWithFetchedProvider(idpHint: unknown): Promise<unknown> {
        const provider = this.oidcIdentityProvider();
        if (!isPresent(provider)) {
            return Promise.resolve();
        }

        return this.init(provider)
            .then(() => this.setIdpHint(idpHint))
            .then(() => {
                if (this.hasIdpHint()) {
                    this.startIdpLogin();
                }
            });
    }

    public init(issuer: string): Promise<unknown> {
        const config = Object.assign(OIDC_CONFIG, {issuer});
        LOG.trace('init OIDC', config);
        this.oauthService.configure(config);

        return this.oauthService.loadDiscoveryDocumentAndTryLogin()
            .then(hasReceivedTokens => {
                LOG.trace('has received oidc config', hasReceivedTokens);
                const hasValidAccessToken: boolean = this.oauthService.hasValidAccessToken();
                LOG.trace('has valid access token ', hasValidAccessToken);

                return Promise.resolve();
            });
    }

    public setIdpHint(hint: unknown): void {
        const idpHint = DvbUtil.isNotEmptyString(hint) ? hint : undefined;

        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.oauthService.customQueryParams = isPresent(idpHint) ? {kc_idp_hint: idpHint} : {};

        if (isPresent(idpHint)) {
            localStorage.setItem(IDP_HINT_LOCAL_STORAGE, idpHint);
        } else {
            localStorage.removeItem(IDP_HINT_LOCAL_STORAGE);
        }
    }

    public clearTokens(): void {
        this.oauthService.logOut(true);
    }

    public logout(): void {
        this.setIdpHint(null);
        this.clearTokens();
    }

    public startIdpLogin(): void {
        this.oauthService.initLoginFlow();
    }

    public hasIdpHint(): boolean {
        return hasOwnPropertyGuarded(this.oauthService.customQueryParams, 'kc_idp_hint');
    }

    public getIdpHintFromLocalStorage(): string | null {
        return localStorage.getItem(IDP_HINT_LOCAL_STORAGE);
    }
}
