/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BetreuungsPerson} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted, SearchResultEntry} from '@dv/shared/code';
import {Adresse, checkPresent, DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import {BENUTZER_STATES} from '../../../../benutzer/benutzer-states';
import type {Benutzer} from '../../../../benutzer/model/Benutzer';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import type {BenutzerService} from '../../../../common/service/rest/benutzer/benutzerService';
import type {Angestellte} from '../../models/Angestellte';
import type {AngestellteService} from '../../service/angestellteService';
import {PERSONAL_STATES} from '../../../personal-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        angestellte: '<',
    },
    template: require('./dvb-angestellte-profil.html'),
    controllerAs: 'vm',
};

export class DvbAngestellteProfil implements angular.IController {
    public static $inject: readonly string[] = [
        'dialogService',
        'angestellteService',
        'benutzerService',
        'authStore',
        'dvbStateService',
        'errorService',
        '$state',
    ];

    public angestellte!: Persisted<Angestellte>;

    public isLoading: boolean = false;
    public isAdresseEditMode: boolean = false;
    public benutzerStates = BENUTZER_STATES;

    public benutzer: Benutzer | null = null;

    private newAddressCreated: boolean = false;

    public constructor(
        private dialogService: DialogService,
        private angestellteService: AngestellteService,
        private benutzerService: BenutzerService,
        private authStore: AuthStore,
        private dvbStateService: DvbStateService,
        private errorService: ErrorService,
        private $state: StateService,
    ) {
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (!(onChangesObj.angestellte && this.angestellte.benutzerId)) {
            return;
        }

        this.benutzerService.get(this.angestellte.benutzerId).then(benutzer => {
            this.benutzer = benutzer;
        });
    }

    public isRequired(param: string): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public editAdresse(): void {
        if (!this.authStore.hasPermission(PERMISSION.SHARED.MANAGE_USERS_ANY)) {
            return;
        }

        this.isAdresseEditMode = true;
    }

    public addAddress(): void {
        if (this.angestellte.adresse) {
            return;
        }

        this.angestellte.adresse = new Adresse();
        this.newAddressCreated = true;
        this.editAdresse();
    }

    public cancelEditAdresse(): void {
        if (this.newAddressCreated) {
            this.angestellte.adresse = null;
            this.newAddressCreated = false;
        }
    }

    public save(): angular.IPromise<unknown> {
        return this.angestellteService.update(this.angestellte);
    }

    public saveTelephone(): void {
        this.save()
            .then(() => this.angestellteService.get(this.angestellte.id))
            .then(angestellte => {
                this.angestellte.telefon = angestellte.telefon;
            });
    }

    public saveAdresse(adresse: Adresse): void {
        const origAdresse = this.angestellte.adresse;
        this.angestellte.adresse = adresse;
        this.save().catch(() => {
            this.angestellte.adresse = origAdresse;
        });
    }

    public delete(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'PERSONAL.ANGESTELLTE.SINGULAR',
            confirm: () => from(this.deleteAngestellte())
                .pipe(take(1), tap(() => this.$state.go(PERSONAL_STATES.ANGESTELLTE_LIST_STATE.name))),
        });
    }

    public assignUser(item: SearchResultEntry): void {
        this.angestellte.benutzerId = item.id;
        this.save().then(() => {
            this.$state.reload();
        });
    }

    public removeUser(): void {
        this.angestellteService.removeBenutzer(this.angestellte.id)
            .then(() => this.$state.reload());
    }

    public addBetreuungsPerson(betreuungspersonItem: SearchResultEntry): void {
        this.angestellteService.addBetreuungsPerson(this.angestellte.id, checkPresent(betreuungspersonItem.id))
            .then(() => this.$state.reload());
    }

    public removeBetreuungsPerson(betreuungsPerson: BetreuungsPerson): void {
        this.angestellteService.removeBetreuungsPerson(this.angestellte.id, checkPresent(betreuungsPerson.id))
            .then(() => this.$state.reload());
    }

    private deleteAngestellte(): angular.IPromise<unknown> {
        return this.angestellteService.delete(this.angestellte.id);
    }
}

componentConfig.controller = DvbAngestellteProfil;
angular.module('kitAdmin').component('dvbAngestellteProfil', componentConfig);
