/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, inject, input, signal} from '@angular/core';
import {ButtonComponent, handleResponse, IconComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {checkPresent} from '@dv/shared/code';
import {DownloadService} from '@dv/shared/download';
import {TranslocoDirective} from '@jsverse/transloco';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {mergeMap} from 'rxjs';
import {ITimelineEntry, TimelineBlob} from '../ITimelineEntry';
import {ITimelineService} from '../ITimelineService';

@Component({
    selector: 'dv-attachments-download',
    imports: [
        BsDropdownModule,
        LoadingButtonComponent,
        IconComponent,
        ButtonComponent,
        TranslocoDirective,
        TooltipModule,
    ],
    templateUrl: './attachments-download.component.html',
    styleUrl: './attachments-download.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsDownloadComponent<T extends ITimelineEntry> {
    public timelineService = input.required<ITimelineService<T>>();
    public timelineEntry = input.required<T>();
    public blobInfos = computed(() => this.timelineEntry().blobInfos);

    protected readonly isLoading = signal(false);
    readonly #downloadService: DownloadService = inject(DownloadService);

    public downloadAttachment(blobInfo: TimelineBlob): void {
        this.isLoading.set(true);

        this.timelineService().getAttachment$(checkPresent(this.timelineEntry().id), blobInfo.id).pipe(
            mergeMap(tempBlob => this.#downloadService.getBlob$(tempBlob).pipe(
                handleResponse({
                    next: blob => this.#downloadService.downloadBlob(blob, tempBlob.filename),
                    finalize: () => this.isLoading.set(false),
                }),
            )),
        ).subscribe();
    }
}
