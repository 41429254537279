/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgStyle} from '@angular/common';
import type {Signal} from '@angular/core';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import type {KinderOrt} from '@dv/kitadmin/models';
import {CrudAccordionComponent, CrudAccordionTemplateDirective, type ItemWithEntity} from '@dv/kitadmin/ui';
import {Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import type {Dienst} from '../../../../personal/konfiguration/Dienst';
import {DienstAdministrationFormComponent} from '../dienst-administration-form/dienst-administration-form.component';
import {DienstAdministrationService} from './dienst-administration.store';

@Component({
    selector: 'dv-dienst-administration',
    templateUrl: './dienst-administration.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslocoModule,
        DienstAdministrationFormComponent,
        NgStyle,
        CrudAccordionComponent,
        CrudAccordionTemplateDirective,
    ],
})
export class DienstAdministrationComponent {

    @Input({required: true})
    public set kinderOrt(value: Persisted<KinderOrt>) {
        this.service.store.setKinderOrtId(value.id);
    }

    public service = inject(DienstAdministrationService);

    public isLoading = toSignal(this.service.store.itemsIsLoading$, {requireSync: true});
    public isEmpty = toSignal(this.service.store.itemsIsEmpty$, {requireSync: true});
    public createMode = toSignal(this.service.store.itemsShowCreateMode$, {requireSync: true});
    public showDeleteDialog = toSignal(this.service.store.itemsShowDeleteDialog$, {requireSync: true});
    public items: Signal<ItemWithEntity<Persisted<Dienst>>[]> =
        toSignal(this.service.store.itemsAll$, {requireSync: true});

    public update(dienstId: string, dienst: Dienst): void {
        this.service.updateDienst$.next(Object.assign(dienst, {id: dienstId}));
    }
}
