/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import type {
    Betrieb,
    Controlling,
    Faktura,
    FrontendReportType,
    Personal,
    ReportType,
    RequiredPermission,
} from '@dv/shared/code';
import {REPORT_TYPE, StateConfiguration} from '@dv/shared/code';
import {GENERAL_SUFFIX} from '@dv/shared/roles';

/* eslint-disable @typescript-eslint/no-magic-numbers */
export class ReportState extends StateConfiguration<FrontendReportType> {

    public static readonly BETRIEB: { [k in Betrieb]: Readonly<ReportState> } = {
        ADRESSLISTE_KINDER_KONTAKTE: ReportState.betriebFactory('ADRESSLISTE_KINDER_KONTAKTE', 'adressliste', 2),
        BELEGUNGSPLAN: ReportState.betriebFactory('BELEGUNGSPLAN', 'belegungsplan-for-kitas', 2),
        BELEGUNGSPLAN_ANWESENHEITSZEITEN: ReportState.betriebFactory('BELEGUNGSPLAN_ANWESENHEITSZEITEN',
            'belegungsplan-anwesenheitszeiten', 2,
            PERMISSION.MODULE.ANWESENHEIT),
        BELEGUNGSPLAN_DAY_BASED: ReportState.betriebFactory('BELEGUNGSPLAN_DAY_BASED', 'belegungsplan-day-based', 5),
        GEBURTSTAGSLISTE_KINDER: ReportState.betriebFactory('GEBURTSTAGSLISTE_KINDER', 'geburtstage', 2),
        TELEFON_EMAIL_ADRESSLISTE: ReportState.betriebFactory('TELEFON_EMAIL_ADRESSLISTE', 'telefonliste', 5),
        AKTUELLE_AENDERUNGEN: ReportState.betriebFactory('AKTUELLE_AENDERUNGEN', 'controlling-for-kitas', 1),
        KINDERGARTENKINDER: ReportState.betriebFactory('KINDERGARTENKINDER', 'kindergartenkinder', 1),
        FRUEH_SPAET_DIENST: ReportState.betriebFactory('FRUEH_SPAET_DIENST', 'fruehspaetdienst', 1,
            PERMISSION.MODULE.ANWESENHEIT),
        ABWEICHUNGEN_KINDER: ReportState.betriebFactory('ABWEICHUNGEN_KINDER', 'abweichungen-kinder', 2,
            PERMISSION.MODULE.DYNAMISCHER_BELEGUNGSPLAN),
    };
    public static readonly CONTROLLING: { [k in Controlling]: Readonly<ReportState> } = {
        CONTROLLING: ReportState.controllingFactory('CONTROLLING', 'controlling', 1),
        CONTROLLING_MONTHLY: ReportState.controllingFactory('CONTROLLING_MONTHLY',
            'controlling-monthly',
            1,
            PERMISSION.FEATURE.CONTROLLING_REPORT_MONTHLY + GENERAL_SUFFIX),
        STUNDENBASIERTES_CONTROLLING: ReportState.controllingFactory('STUNDENBASIERTES_CONTROLLING',
            'stundenbasiertes-controlling',
            1),
        BELEGUNGSPLAN_PLATZART: ReportState.controllingFactory('BELEGUNGSPLAN_PLATZART', 'belegungsplan', 2),
        BELEGUNGSPLAN_MONTHLY: ReportState.controllingFactory('BELEGUNGSPLAN_MONTHLY', 'belegungsplan-monthly', 1),
        KENNZAHLEN: ReportState.controllingFactory('KENNZAHLEN', 'kennzahlen', 2),
        WARTELISTE: ReportState.controllingFactory('WARTELISTE', 'warteliste', 1),
        BETREUUNGSGUTSCHEINE: ReportState.controllingFactory('BETREUUNGSGUTSCHEINE', 'betreuungsgutscheine', 2),
        KIBON_REPORT: ReportState.controllingFactory('KIBON_REPORT', 'kibon-report', 3),
        LEISTUNGEN_KALKULIERT: ReportState.controllingFactory('LEISTUNGEN_KALKULIERT', 'kalkulierte-leistungen', 2),
        FINANZLASTENAUSGLEICH: ReportState.controllingFactory('FINANZLASTENAUSGLEICH', 'finanzlastenausgleich', 1),
        BELEGTE_PROZENT_PUNKTE: ReportState.controllingFactory('BELEGTE_PROZENT_PUNKTE', 'belegteprozentpunkte', 1),
        TARIFRECHNUNG_REPORTING_KALKULIERT: ReportState.controllingFactory(
            'TARIFRECHNUNG_REPORTING_KALKULIERT',
            'kalkuliertes-tarifreporting',
            2),
        STUNDENBLATT: ReportState.controllingFactory('STUNDENBLATT', 'stundenblatt', 1),
        MONATSBLATT_AUSZUG: ReportState.controllingFactory('MONATSBLATT_AUSZUG', 'monatsblatt-auszug', null),
        BETREUUNGS_STUNDEN_KONTROLLE: ReportState.controllingFactory(
            'BETREUUNGS_STUNDEN_KONTROLLE',
            'stundenkontingent',
            1),
        CORONA_REFUNDS: ReportState.controllingFactory('CORONA_REFUNDS', 'corona-refund', 1),
        CORONA_REFUNDS_BERN_PRIVAT: ReportState.controllingFactory('CORONA_REFUNDS_BERN_PRIVAT',
            'corona-refund-bern',
            1),
        CORONA_REFUNDS_LUZERN: ReportState.controllingFactory('CORONA_REFUNDS_LUZERN', 'corona-refund-luzern', 1),
        BSV_PRAESENZKONTROLLE: ReportState.controllingFactory(
            'BSV_PRAESENZKONTROLLE',
            'bsv-praesenzkontrolle',
            1,
            PERMISSION.MODULE.BSV),
        BSV_PRAESENZKONTROLLE_KG: ReportState.controllingFactory(
            'BSV_PRAESENZKONTROLLE_KG',
            'bsv-praesenzkontrolle-kg',
            1,
            PERMISSION.MODULE.BSV),
        ANWESENHEITSSOLL_VERBRAUCH: ReportState.controllingFactory(
            'ANWESENHEITSSOLL_VERBRAUCH',
            'anwesenheitssoll_verbrauch',
            1,
            {
                oneOf: [
                    PERMISSION.FEATURE.ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS + GENERAL_SUFFIX,
                    PERMISSION.FEATURE.ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA + GENERAL_SUFFIX,
                ],
            }),
        STUNDENVERGLEICH_SOLL_IST: ReportState.controllingFactory(
            'STUNDENVERGLEICH_SOLL_IST',
            'stundenbergleich',
            2,
            [
                PERMISSION.MODULE.GROUP_ADMIN,
                PERMISSION.MODULE.ANWESENHEIT,
            ]),
        EXTERNAL_ANMELDUNGEN: ReportState.controllingFactory('EXTERNAL_ANMELDUNGEN', 'external_anmeldungen', 1),
    };
    public static readonly FAKTURA: { [k in Faktura | 'AKTUELLE_OFFENE_POSTEN']: Readonly<ReportState> } = {
        ZAHLUNGS_EINGAENGE: ReportState.fakturaFactory(
            'ZAHLUNGS_EINGAENGE',
            'zahlungseingaenge',
            1,
            PERMISSION.MODULE.FAKTURA),
        LEISTUNGEN_FAKTURIERT: ReportState.fakturaFactory(
            'LEISTUNGEN_FAKTURIERT',
            'fakturierte-leistungen',
            2,
            PERMISSION.MODULE.FAKTURA),
        KONTAKTPERSONEN_ZAHLUNGEN: ReportState.fakturaFactory(
            'KONTAKTPERSONEN_ZAHLUNGEN',
            'vorauszahlungen-rueckerstattungen',
            4,
            PERMISSION.MODULE.FAKTURA),
        OFFENE_POSTEN: ReportState.offenePosten('OFFENE_POSTEN', 'offene-posten'),
        AKTUELLE_OFFENE_POSTEN: ReportState.offenePosten('AKTUELLE_OFFENE_POSTEN', 'aktuelle-offene-posten'),
        RECHNUNGS_GEBUEHREN: ReportState.fakturaFactory('RECHNUNGS_GEBUEHREN',
            'rechnungsgebuehren',
            1,
            PERMISSION.MODULE.FAKTURA),
        STEUERBESCHEINIGUNGEN: ReportState.fakturaFactory(
            'STEUERBESCHEINIGUNGEN',
            'steuerbescheinigungen',
            null,
            PERMISSION.MODULE.FAKTURA),
        DEPOTS: ReportState.fakturaFactory('DEPOTS', 'depots', 1, PERMISSION.MODULE.FAKTURA),
    };

    public static readonly PERSONAL: { [k in Personal]: Readonly<ReportState> } = {
        PERSONALPLANUNG: ReportState.personalFactory(
            'PERSONALPLANUNG',
            'personalplanung',
            1,
            PERMISSION.PERSONAL.VIEW_ANY),
        PERSONALPLANUNG_MONTHLY: ReportState.personalFactory(
            'PERSONALPLANUNG_MONTHLY',
            'personalplanung-monthly',
            1,
            PERMISSION.PERSONAL.VIEW_ANY),
        WORK_TIME_VIOLATIONS: ReportState.personalFactory(
            'WORK_TIME_VIOLATIONS',
            'worktime-violations',
            1,
            PERMISSION.MODULE.WORK_TIME_CONTROLLING),
    };

    public templateVersion: number | null = null;

    private static factory(
        keyPrefix: string,
        statePrefix: string,
        key: FrontendReportType,
        state: string,
        permission: RequiredPermission = 'module:basic',
        templateVersion: number | null,
    ): Readonly<ReportState> {

        const reportState = new ReportState(keyPrefix, statePrefix, key, state, permission);
        reportState.templateVersion = templateVersion;

        return reportState;
    }

    // eslint-disable-next-line sonarjs/no-identical-functions
    private static betriebFactory(
        key: FrontendReportType,
        state: string,
        templateVersion: number | null,
        permission?: string, // FIXME permission not consistent with state definition
    ): Readonly<ReportState> {
        return ReportState.factory('REPORT.',
            'base.report.category.betrieb.',
            key,
            state,
            permission,
            templateVersion);
    }

    // eslint-disable-next-line sonarjs/no-identical-functions
    private static controllingFactory(
        key: FrontendReportType,
        state: string,
        templateVersion: number | null,
        permission?: RequiredPermission,
    ): Readonly<ReportState> {
        return ReportState.factory('REPORT.',
            'base.report.category.controlling.',
            key,
            state,
            permission,
            templateVersion);
    }

    // eslint-disable-next-line sonarjs/no-identical-functions
    private static fakturaFactory(
        key: FrontendReportType,
        state: string,
        templateVersion: number | null,
        permission?: string,
    ): Readonly<ReportState> {
        return ReportState.reportFactory('base.report.category.faktura.', key, state, templateVersion, permission);
    }

    private static personalFactory(
        key: FrontendReportType,
        state: string,
        templateVersion: number | null,
        permission?: string,
    ): Readonly<ReportState> {
        return ReportState.reportFactory('base.report.category.personal.', key, state, templateVersion, permission);
    }

    private static reportFactory(
        prefix: string,
        key: FrontendReportType,
        state: string,
        templateVersion: number | null,
        permission?: string,
    ): Readonly<ReportState> {
        return ReportState.factory('REPORT.',
            prefix,
            key,
            state,
            permission,
            templateVersion);
    }

    // Das Template wird geshared. Backend-seitig gibt es nur den ReportType OFFENE_POSTEN
    private static offenePosten(key: 'OFFENE_POSTEN' | 'AKTUELLE_OFFENE_POSTEN', state: string): Readonly<ReportState> {
        return ReportState.fakturaFactory(key, state, 2, PERMISSION.MODULE.FAKTURA);
    }

    public asReportType(): ReportType {
        return REPORT_TYPE.check(this.key);
    }
}
