import {Directive, HostListener, inject, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[dvResourceHover]',
})
export class ResourceHoverDirective {
    private readonly renderer = inject(Renderer2);

    @Input() public dvResourceHoverId: string | undefined;

    private hoveredElements: NodeListOf<HTMLElement> | [] = [];

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        this.hoveredElements = document.querySelectorAll(`[data-resource-id="${this.dvResourceHoverId}"]`);
        this.hoveredElements.forEach(element => {
            this.renderer.addClass(element, 'resource-hover');
        });
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        this.hoveredElements.forEach(element => {
            this.renderer.removeClass(element, 'resource-hover');
        });
    }

}
