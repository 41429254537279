/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {checkPresent, TimeRangeUtil} from '@dv/shared/code';
import moment from 'moment';
import type {CalendarEvent} from '../../../../../calendar/timeline/model/CalendarEvent';
import {calendarEventIdGenerator} from '../../../../../calendar/timeline/model/CalendarEvent';
import type {Dienst} from '../../../../../personal/konfiguration/Dienst';
import type {AngestellteZuweisungZeit} from '../../../../../personal/model/AngestellteZuweisungZeit';
import type {ZuweisungPause} from '../../../../../personal/model/ZuweisungPause';
import {findMatchingDienst} from '../find-matching-dienst';
import {LayerType} from '../LayerType';

/**
 * @param angestellteZuweisungZeit the timerange to convert
 * @param zuweisungPausen used to find a matching dienst and assign its color
 * @param dienste used to find a matching dienst and assign its color
 * @param zuweisungEvents used to find a correlating zuweisung CalendarEvent and link it
 * @param kinderOrtId used to check if the kinderOrt matches the angestellteZuweisungZeit kinderOrt
 * @param selectedDate the date for which the event is created
 */
export function fraktionIndependentZeitToEvent(
    angestellteZuweisungZeit: AngestellteZuweisungZeit,
    zuweisungPausen: ZuweisungPause[],
    dienste: Persisted<Dienst>[],
    zuweisungEvents: CalendarEvent[],
    kinderOrtId?: string,
    selectedDate?: moment.Moment,
): CalendarEvent {
    const dienst = findMatchingDienst(dienste, angestellteZuweisungZeit, zuweisungPausen);
    const zuweisungEvent = zuweisungEvents
        .find(event => TimeRangeUtil.contains(angestellteZuweisungZeit, event));
    const vonBis = TimeRangeUtil.formatTimeRange(angestellteZuweisungZeit);
    const fraktionTooltip = angestellteZuweisungZeit.kinderOrtFraktionDisplayName ?
        `, ${angestellteZuweisungZeit.kinderOrtFraktionDisplayName}` : '';
    const isSameKinderOrt = kinderOrtId === angestellteZuweisungZeit.kinderOrtId;
    const tooltip = isSameKinderOrt ?
        vonBis : `${angestellteZuweisungZeit.kinderOrtDisplayName}${fraktionTooltip}: ${vonBis}`;

    return {
        id: calendarEventIdGenerator(),
        gueltigAb: checkPresent(moment(selectedDate)),
        gueltigBis: moment(selectedDate),
        von: angestellteZuweisungZeit.von,
        bis: angestellteZuweisungZeit.bis,
        tooltip,
        completeDuration: 0,
        spaceBefore: 0,
        spaceAfter: 0,
        backgroundColor: `${dienst?.backgroundColor ?? '#10BB6A'}80`,
        textColor: `${dienst?.textColor ?? '#FFFFFF'}`,
        hasHighLuminance: dienst?.hasHighLuminance ?? false,
        outline: `2px dashed ${dienst?.backgroundColor ?? '#10BB6A'}`,
        getDisplayName: () => '',
        layer: LayerType.FRAKTION_INDEPENDENT,
        subEvents: [],
        linkedEvent: isSameKinderOrt ? zuweisungEvent?.id : undefined,
    };
}
