/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, inject, Input, input, output, signal} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {RequirePermissionStructuralDirective} from '@dv/shared/angular';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import type {TerminWorkTimeControllingRelevance} from '@dv/shared/backend/model/termin-work-time-controlling-relevance';
import {checkPresent, isPresent} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'dv-termin-type-form',
    imports: [
        TooltipModule,
        TranslocoModule,
        SubmitCancelButtonsComponent,
        FormsModule,
        RequirePermissionStructuralDirective,
    ],
    templateUrl: './termin-type-form.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminTypeFormComponent {

    @Input()
    public set terminType(terminType: JaxTerminType) {
        this._terminType = terminType;

        this.name.set(terminType.name ?? '');
        this.deductFromBedarfsrechnung.set(terminType.deductFromBedarfsrechnung);
        this.relevantForWorkTimeControlling.set(terminType.workTimeControllingRelevance !== 'IRRELEVANT');
        this.workTimeControllingRelevance.set(terminType.workTimeControllingRelevance || 'IRRELEVANT');
        this.absenceTypeId.set(terminType.absenceTypeId ?? null);
        this.usePlannedTimesForAbsence.set(terminType.usePlannedTimesForAbsence ?? false);
    }

    public absenceTypes = input<JaxWorkTimeControllingAbsenceType[]>([]);
    public isLoading = input<boolean>(false);

    public readonly save = output<JaxTerminType>();
    public readonly cancelEdit = output<void>();

    private readonly errorService = inject(ErrorService);

    public _terminType?: JaxTerminType;

    public name = signal<string>('');
    public deductFromBedarfsrechnung = signal<boolean>(false);
    public relevantForWorkTimeControlling = signal<boolean>(false);
    public workTimeControllingRelevance = signal<TerminWorkTimeControllingRelevance>('IRRELEVANT');
    public absenceTypeId = signal<EntityId | null>(null);
    public usePlannedTimesForAbsence = signal<boolean>(false);

    private isEditMode = computed(() => isPresent(this._terminType?.id));
    public titleKey = computed(() => this.isEditMode() ? 'PERSONAL.TERMIN.TYPE.EDIT' : 'PERSONAL.TERMIN.TYPE.NEW');

    public showAbsenceTypeSelection = computed(() => {
        const relevantForControlling = this.relevantForWorkTimeControlling();
        const relevance = this.workTimeControllingRelevance();

        return relevantForControlling && relevance === 'ABSENCE';
    });

    public submitForm(form: NgForm): void {
        this.errorService.clearAll();
        if (!form.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        if (this.relevantForWorkTimeControlling() && this.workTimeControllingRelevance() === 'IRRELEVANT') {
            this.errorService.addValidationError('PERSONAL.TERMIN.TYPE.ERR_CHOOSE_RELEVANCE');

            return;
        }

        this.save.emit({
            id: this._terminType?.id,
            name: this.name(),
            deductFromBedarfsrechnung: this.deductFromBedarfsrechnung(),
            workTimeControllingRelevance: this.relevantForWorkTimeControlling() ?
                checkPresent(this.workTimeControllingRelevance()) :
                'IRRELEVANT',
            absenceTypeId: this.relevantForWorkTimeControlling() && this.workTimeControllingRelevance() === 'ABSENCE' ?
                this.absenceTypeId() ?? undefined :
                undefined,
            usePlannedTimesForAbsence: this.usePlannedTimesForAbsence(),
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancelEdit.emit();
    }
}
