<ng-container *transloco="let t">
    <div class="row">
        <div class="col-md-7">
            <dv-crud-accordion
                [items]="store.entities()"
                [title]="t('PERSONAL.TERMIN.TYPE.TITLE')"
                [isEmpty]="store.isEmpty()"
                [emptyText]="t('PERSONAL.TERMIN.TYPE.NONE')"
                [createMode]="store.showCreateMode()"
                [createText]="t('PERSONAL.TERMIN.TYPE.NEW')"
                [showDeleteDialog]="store.showDeleteDialog()"
                [entityName]="t('PERSONAL.TERMIN.TYPE.SINGULAR')"
                [isLoading]="store.isLoadingOrCreating()"
                [headerTemplate]="headerTemplate"
                [editModeTemplate]="editModeTemplate"
                [createModeTemplate]="createModeTemplate"
                [readonlyModeTemplate]="readonlyModeTemplate"
                (toggleExpanded)="store.toggleExpanded($event.id)"
                (confirmDelete)="store.confirmDelete()"
                (hideDeleteDialog)="store.hideDeleteDialog()"
                (showCreateMode)="store.enableCreateMode()">
            </dv-crud-accordion>
        </div>
    </div>

    <ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #headerTemplate>
        <div class="full-width-block">{{ item.entity.name }}</div>
    </ng-template>

    <ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #readonlyModeTemplate>
        <div class="normal-abstand-container">
            @if (item.entity.deductFromBedarfsrechnung) {
                <section>
                    <h3>{{ t('PERSONAL.TERMIN.TYPE.BEDARFSRECHNUNG_TITLE') }}</h3>
                    <p>{{ t('PERSONAL.TERMIN.TYPE.DEDUCT_FROM_BEDARFSRECHNUNG') }}</p>
                </section>
            }
            @if (item.entity.workTimeControllingRelevance !== 'IRRELEVANT') {
                <section *dvlibRequirePermission="'module:work_time_controlling'">
                    <h3>{{ t('PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE_TITLE') }}</h3>
                    <p>{{ t('PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE.' + item.entity.workTimeControllingRelevance) }}</p>
                </section>
            }

            <dvlib-button-list *dvlibRequirePermission="'personal:manage:*'">
                <button type="button"
                        class="btn btn-link"
                        (click)="store.setEditMode(item.entity.id)">
                    {{ t('COMMON.BEARBEITEN') }}
                </button>
                <button type="button"
                        class="btn btn-link"
                        (click)="store.setShowDeleteDialog(item.entity.id)">
                    {{ t('COMMON.LOESCHEN') }}
                </button>
                <dvlib-spinner [show]="item.isLoading"/>
            </dvlib-button-list>
        </div>
    </ng-template>

    <ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #editModeTemplate>
        <dv-termin-type-form
            [terminType]="item.entity"
            [absenceTypes]="store.absenceTypes()"
            [isLoading]="item.isLoading"
            (save)="store.updateType($event)"
            (cancelEdit)="store.setReadonlyMode(item.entity.id)">
        </dv-termin-type-form>
    </ng-template>

    <ng-template #createModeTemplate>
        <dv-termin-type-form
            [isLoading]="store.isCreating()"
            [absenceTypes]="store.absenceTypes()"
            (save)="store.createType($event)"
            (cancelEdit)="store.disableCreateMode()">
        </dv-termin-type-form>
    </ng-template>
</ng-container>
