import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {FormGroupSpec} from '@dv/shared/angular';
import {ButtonComponent, ButtonListComponent, ValidBisDirective, ValidVonDirective} from '@dv/shared/angular';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import {DvbRestUtil, ITimeRange, TimeRange} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CalendarEvent} from 'src/app/calendar/timeline/model/CalendarEvent';
import {ZuweisungPause} from '../../../../../personal/model/ZuweisungPause';
import {validPause} from '../../pause-form.util';
import {isAngestellteZuweisungEvent} from '../service/calendar-event-util';

interface ZuweisungEditPause {
    von: BackendLocalTimeHHMM | undefined;
    bis: BackendLocalTimeHHMM | undefined;
    paid: boolean;
}

interface ZuweisungEditTimeFormModel {
    von: BackendLocalTimeHHMM | undefined;
    bis: BackendLocalTimeHHMM | undefined;
    pausen: ZuweisungEditPause[];
}

@Component({
    selector: 'dv-zuweisung-edit-time-form',
    imports: [
        FormsModule,
        ValidVonDirective,
        ValidBisDirective,
        TranslocoModule,
        TooltipModule,
        ButtonComponent,
        ButtonListComponent,
        NgClass,
    ],
    templateUrl: './zuweisung-edit-time-form.component.html',
    styleUrl: './zuweisung-edit-time-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZuweisungEditTimeFormComponent {
    private errorService = inject(ErrorService);

    @Input({required: true})
    public set event(event: CalendarEvent) {
        if (!isAngestellteZuweisungEvent(event)) {
            throw new Error('Event is not an AngestellteZuweisungEvent');
        }

        this._event = event;

        this.model = {
            von: DvbRestUtil.momentTolocaleHHMMTime(event.von) ?? undefined,
            bis: DvbRestUtil.momentTolocaleHHMMTime(event.bis) ?? undefined,
            pausen: event.data.zuweisung.pausen.map(pause => ({
                von: DvbRestUtil.momentTolocaleHHMMTime(pause.von) ?? undefined,
                bis: DvbRestUtil.momentTolocaleHHMMTime(pause.bis) ?? undefined,
                paid: pause.paid,
            })),
        };
    }

    public get event(): CalendarEvent {
        return this._event;
    }

    private _event!: CalendarEvent;

    @Output() public readonly updateTimes: EventEmitter<{
        event: CalendarEvent;
        times: ITimeRange;
        pausen: ZuweisungPause[];
    }> = new EventEmitter();

    @Output() public readonly editFinished: EventEmitter<void> = new EventEmitter();

    public model: Partial<ZuweisungEditTimeFormModel> = {};

    public submitForm(ngForm: NgForm): void {
        const formGroup: FormGroupSpec<ZuweisungEditTimeFormModel> = ngForm.form;
        this.errorService.clearAll();

        if (!formGroup.valid || !this._event) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const pausen = this.model.pausen ?? [];

        const zuweisungPausen: ZuweisungPause[] = pausen
            .map(pause => new ZuweisungPause(
                DvbRestUtil.localeHHMMTimeToMoment(pause.von),
                DvbRestUtil.localeHHMMTimeToMoment(pause.bis),
                this._event.gueltigAb,
                this._event.gueltigBis,
                pause.paid,
            ));

        const zuweisungsZeit = new TimeRange(
            DvbRestUtil.localeHHMMTimeToMoment(this.model.von),
            DvbRestUtil.localeHHMMTimeToMoment(this.model.bis),
        );

        if (!validPause(zuweisungsZeit, zuweisungPausen)) {
            this.errorService.addValidationError('ERRORS.ERR_INVALID_PAUSE');

            return;
        }

        this.updateTimes.emit({
            event: this._event,
            times: zuweisungsZeit,
            pausen: zuweisungPausen,
        });

        this.errorService.clearAll();

        this.editFinished.emit();
    }

    public addPause(): void {
        this.model.pausen?.push({
            von: undefined,
            bis: undefined,
            paid: false,
        });
    }

    public removePause(index: number): void {
        this.model.pausen?.splice(index, 1);
    }

    public onPausePaidChanged(pause: ZuweisungEditPause): void {
        pause.paid = !pause.paid;
    }

    public cancel(): void {
        this.editFinished.emit();
    }
}
