/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {TempBlob} from '@dv/kitadmin/models';
import type {
    ReportingPersonalService,
    ReportingPersonalServiceGetWorkTimeViolationsRequestParams,
} from '@dv/shared/backend/api/reporting-personal.service';
import type {ReportTemplate, SearchResultEntry, SupportedDateTypes} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment/moment';
import {lastValueFrom, map} from 'rxjs';
import type {ReportState} from '../../models';
import {ERR_VALIDATION} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        gueltigAb: '<?',
        gueltigBis: '<?',
    },
    template: require('./dvb-work-time-violations-report.html'),
    controllerAs: 'vm',
};

export class DvbWorkTimeViolationsReport {
    public static $inject: readonly string[] = ['reportService', 'reportingPersonalService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public kinderOrt: SearchResultEntry | null = null;
    public gueltigAb: SupportedDateTypes | null = null;
    public gueltigBis: SupportedDateTypes | null = null;

    public reportTemplate: ReportTemplate | null = null;
    public allKinderOrte: boolean = false;

    public defaultGueltigAb: moment.Moment = DvbDateUtil.today().startOf('month');
    public defaultGueltigBis: moment.Moment = DvbDateUtil.today().endOf('month');

    public reportForm!: angular.IFormController;

    public constructor(
        private reportService: ReportService,
        private reportingPersonalService: ReportingPersonalService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public updateState(): void {
        this.reportService.updateStateParams({gueltigAb: this.gueltigAb, gueltigBis: this.gueltigBis});
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        this.errorService.clearAll();
        const isValid = this.reportForm.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    private getDownloadPromise(): angular.IPromise<TempBlob> {
        const params: ReportingPersonalServiceGetWorkTimeViolationsRequestParams = {
            jaxWorkTimeViolationReportParams: {
                gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb || this.defaultGueltigAb) || undefined,
                gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis || this.defaultGueltigBis) || undefined,
                allKinderOrte: this.allKinderOrte,
                kinderOrtId: this.kinderOrt?.id,
            },
            violations: {},
        };

        if (isPresent(this.reportTemplate)) {
            params.violations = {
                reportTemplate: this.reportTemplate.id!,
            };
        }

        return lastValueFrom(this.reportingPersonalService.getWorkTimeViolations$(params)
            .pipe(map(response => TempBlob.apiResponseTransformer(response))));
    }
}

componentConfig.controller = DvbWorkTimeViolationsReport;
angular.module('kitAdmin').component('dvbWorkTimeViolationsReport', componentConfig);
