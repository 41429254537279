import {computed, inject, Injectable, signal} from '@angular/core';
import {toObservable} from '@angular/core/rxjs-interop';
import {AuthStore, handleResponse} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {WorkTimeControllingConfigService} from '@dv/shared/backend/api/work-time-controlling-config.service';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import {checkPresent, DvbRestUtil, isNullish} from '@dv/shared/code';
import {shareState} from '@dv/shared/rxjs-utils';
import {Translator} from '@dv/shared/translator';
import {combineLatest, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {PersonalTimelineStore} from '../../service/personal-timeline.store';
import {PersonalplanungStore} from './personalplanung.store';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class PersonalplanungControllingStore {

    private authStore = inject(AuthStore);
    private translator = inject(Translator);
    private timelineStore = inject(PersonalTimelineStore);
    private personalplanungStore = inject(PersonalplanungStore);
    private workTimeControllingService = inject(WorkTimeControllingService);
    private controllingConfigApi = inject(WorkTimeControllingConfigService);

    public conversionDialogOpen = signal(false);
    public conversionIsLoading = signal(false);

    private readonly hasWorkTimeControllingPermission =
        this.authStore.hasPermission(PERMISSION.MODULE.WORK_TIME_CONTROLLING);
    private lockedUntil$ = this.hasWorkTimeControllingPermission ?
        this.controllingConfigApi.getLockDate$().pipe(
            shareState(),
            map(lockDate => DvbRestUtil.localDateToMoment(lockDate.lockDate)),
        ) : of(null);

    private ignoreLocksPermitted = computed(() => {
        const kinderOrtId = this.personalplanungStore.kinderOrt()?.id;

        return isNullish(kinderOrtId) ?
            false :
            this.authStore.hasPermission(PERMISSION.WORK_TIME_CONTROLLING.IGNORE_LOCKS + kinderOrtId);
    });

    private lockedUntilWithTimelineDates$ = combineLatest([
        this.lockedUntil$,
        toObservable(this.timelineStore.startDate),
        toObservable(this.timelineStore.endDate),
    ]);

    public conversionLockedUntil$ = this.lockedUntilWithTimelineDates$.pipe(
        map(([lockedUntil, _start, end]) => {
            if (this.ignoreLocksPermitted() || isNullish(lockedUntil)) {
                return null;
            }

            return lockedUntil.isSameOrAfter(end, 'day') ? lockedUntil : null;
        }),
    );

    public conversionLimitedInfo$ = this.lockedUntilWithTimelineDates$.pipe(
        map(([lockedUntil, start, end]) => {
            if (this.ignoreLocksPermitted()
                || isNullish(lockedUntil)
                || !lockedUntil.isBetween(start, end, 'day', '[)')) {
                return '';
            }

            return this.translator.instant(
                'PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING_LIMITED_INFO',
                {date: lockedUntil.format('D.M.YYYY')});
        }),
    );

    public convert(): void {
        const kinderOrtId = checkPresent(this.personalplanungStore.kinderOrt()).id;
        this.conversionIsLoading.set(true);

        this.workTimeControllingService.storePlannedAsActual$({
            kinderOrtId,
            kinderOrtIdMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.startDate()),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.endDate()),
            },
        }).pipe(handleResponse({
            next: () => this.conversionDialogOpen.set(false),
            finalize: () => this.conversionIsLoading.set(false),
        })).subscribe();
    }
}
