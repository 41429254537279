/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {WritableSignal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, inject, Input, signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormControl, Validators} from '@angular/forms';
import {EmailVersandProperties} from '@dv/kitadmin/models';
import {ERROR_CODE, Nullish, SHORT_DEBOUNCE_TIME} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {catchError, debounceTime, distinctUntilChanged, map, Observable, of, Subject, switchMap} from 'rxjs';
import {BulkEmailService} from '../../../communication/service/bulk-email.service';

@Component({
    selector: 'dv-spf-info',
    templateUrl: './spf-info.component.html',
    styleUrl: './spf-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        PopoverModule,
        TranslocoModule,
    ],
})
export class SpfInfoComponent {

    @Input()
    public set replyToAddress(replyToAddress: string | Nullish) {
        this.addressSource.next(replyToAddress);
    }

    public showPopoverSource: WritableSignal<boolean> = signal(false);

    private addressSource: Subject<string | Nullish> = new Subject();
    private spfError$ = this.addressSource.pipe(
        debounceTime(SHORT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        switchMap(value => this.isValidEmail(value) ? this.spfCheck$(value) : of(false)),
    );

    public spfError = toSignal(this.spfError$, {initialValue: false});
    public showPopover = computed(() => {
        const shouldShow = this.showPopoverSource();
        const hasError = this.spfError();

        return shouldShow && hasError;
    });

    private readonly bulkEmailService: BulkEmailService = inject(BulkEmailService);

    private spfCheck$(value: string | Nullish): Observable<boolean> {
        return this.bulkEmailService.validateSender$(new EmailVersandProperties(value)).pipe(
            map(() => false),
            catchError(err => of(err.errorCode === ERROR_CODE.EMAIL_ERROR)),
        );
    }

    private isValidEmail(value: string | Nullish): boolean {
        if (!value) {
            return false;
        }

        const control = new FormControl(value, Validators.email);
        const error = Validators.email(control);

        return error === null;
    }
}
