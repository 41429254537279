/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import type {BetreuungsPerson, KinderOrtFraktionId} from '@dv/kitadmin/models';
import type {DialogModel} from '@dv/shared/angular';
import {ButtonListComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import {
    BetreuungsPersonMonatlicheStundenErfassung,
} from '../../models/stundenerfassung/BetreuungsPersonMonatlicheStundenErfassung';
import {KindBetreuungErfassungService} from '../../service/kindBetreuungErfassungService';
import {
    AngestellteStundenerfassungSummaryEntryComponent,
} from '../angestellte-stundenerfassung-summary-entry/angestellte-stundenerfassung-summary-entry.component';

export interface AngestellteStundenerfassungSummaryDialogModel extends DialogModel {
    firstOfMonth: moment.Moment;
    monthlyData: BetreuungsPersonMonatlicheStundenErfassung;
    betreuungsPersonen: { [k in KinderOrtFraktionId]: BetreuungsPerson };
    angestellteId: string;
    dvbDownloadCtrl: DvbDownload;
    kindBetreuungErfassungService: KindBetreuungErfassungService;
}

@Component({
    selector: 'dv-angestellte-stundenerfassung-summary',
    templateUrl: './angestellte-stundenerfassung-summary.component.html',
    styleUrl: './angestellte-stundenerfassung-summary.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslocoModule,
        AngestellteStundenerfassungSummaryEntryComponent,
        ButtonListComponent,
    ],
})
export class AngestellteStundenerfassungSummaryComponent implements AngestellteStundenerfassungSummaryDialogModel {
    private bsModalRef = inject(BsModalRef);

    @Input() public firstOfMonth!: moment.Moment;
    @Input() public monthlyData!: BetreuungsPersonMonatlicheStundenErfassung;
    @Input() public betreuungsPersonen!: { [k in KinderOrtFraktionId]: BetreuungsPerson };
    @Input() public angestellteId!: string;
    @Input() public dvbDownloadCtrl!: DvbDownload;
    @Input() public kindBetreuungErfassungService!: KindBetreuungErfassungService;

    public downloadSummary(): void {
        this.kindBetreuungErfassungService.downloadMonatlicheStundenErfassungSummaryPdf(
            this.angestellteId, this.firstOfMonth, this.dvbDownloadCtrl);
    }

    public hide(): void {
        this.bsModalRef.hide();
    }
}
