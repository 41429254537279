/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import type {Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {CONFIG} from '../../../config';
import {AnwesenheitsSoll} from './model/AnwesenheitsSoll';
import {AnwesenheitsSollVerbrauch} from './model/AnwesenheitsSollVerbrauch';

@Injectable({
    providedIn: 'root',
})
export class AnwesenheitsSollService {
    private http = inject(HttpClient);

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/anwesenheitssoll`;

    public getForKind$(kindId: string): Observable<AnwesenheitsSoll[]> {
        const url = `${AnwesenheitsSollService.BASE_URL}/kind/${encodeURIComponent(kindId)}`;

        return this.http.get<AnwesenheitsSoll[]>(url).pipe(
            map((soll: any) => soll.items.map(AnwesenheitsSoll.apiResponseTransformer)),
        );
    }

    public getVerbrauch$(
        kindId: string,
        kinderOtId: string,
        stichtag: moment.Moment,
    ): Observable<AnwesenheitsSollVerbrauch> {
        const url = `${AnwesenheitsSollService.BASE_URL}/kind/${encodeURIComponent(kindId)}/verbrauch/kinderort/${
            encodeURIComponent(kinderOtId)}`;

        const matrixParams = {
            stichtag: DvbRestUtil.momentToLocalDate(stichtag)!,
        };

        return this.http.get<AnwesenheitsSollVerbrauch>(url + DvbRestUtil.encodeMatrixParams(matrixParams)).pipe(
            map(AnwesenheitsSollVerbrauch.apiResponseTransformer),
        );
    }

    public create$(kindId: string, soll: AnwesenheitsSoll): Observable<unknown> {
        const url = `${AnwesenheitsSollService.BASE_URL}/kind/${encodeURIComponent(kindId)}`;

        return this.http.post<unknown>(url, soll.toRestObject());
    }

    public update$(soll: Persisted<AnwesenheitsSoll>): Observable<unknown> {
        const url = `${AnwesenheitsSollService.BASE_URL}/${encodeURIComponent(soll.id)}`;

        return this.http.put<unknown>(url, soll.toRestObject());
    }

    public delete$(soll: Persisted<AnwesenheitsSoll>): Observable<boolean> {
        const url = `${AnwesenheitsSollService.BASE_URL}/${encodeURIComponent(soll.id)}`;

        return this.http.delete<boolean>(url, soll.toRestObject());
    }
}
