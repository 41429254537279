<ng-container *transloco="let t">
    <h2>{{ dienst?.id ? t('PERSONAL.DIENSTKONFIGURATION.EDIT') : t('PERSONAL.DIENSTKONFIGURATION.NEW') }}</h2>
    <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="submitForm()">
        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       formControlName="name"
                       [maxlength]="255"
                       [placeholder]="t('PERSONAL.DIENSTKONFIGURATION.NAME')"
                       [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.NAME')"
                       [attr.aria-label]="t('PERSONAL.DIENSTKONFIGURATION.NAME')">
            </div>
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       formControlName="kuerzel"
                       [placeholder]="t('PERSONAL.DIENSTKONFIGURATION.KUERZEL')"
                       [maxlength]="100"
                       [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.KUERZEL')"
                       [attr.aria-label]="t('PERSONAL.DIENSTKONFIGURATION.KUERZEL')">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input type="time"
                       class="form-control"
                       formControlName="von"
                       [placeholder]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.VON')"
                       [tooltip]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.VON')"
                       [attr.aria-label]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.VON')">
            </div>
            <div class="col-md-6">
                <input type="time"
                       class="form-control"
                       formControlName="bis"
                       [placeholder]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS')"
                       [tooltip]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS')"
                       [attr.aria-label]="t('PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS')">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 layout-row flex-start items-center">
                <ngx-colors ngx-colors-trigger
                            formControlName="backgroundColor"
                            colorPickerControls="no-alpha"
                            format="hex"
                            tabindex="0"
                            [palette]="colorPalette"
                            [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.BACKGROUND_COLOR')"
                            [acceptLabel]="t('COMMON.AUSWAEHLEN')"
                            [cancelLabel]="t('COMMON.ABBRECHEN')">
                </ngx-colors>
                <span class="smaller-abstand-left"
                      [ngClass]="{'error-message': ngForm.submitted && form.controls['backgroundColor'].invalid}">
                    {{ t('PERSONAL.DIENSTKONFIGURATION.BACKGROUND_COLOR') }}
                </span>
            </div>
            <div class="col-md-6 layout-row flex-start items-center">
                <ngx-colors ngx-colors-trigger
                            formControlName="textColor"
                            colorPickerControls="no-alpha"
                            format="hex"
                            tabindex="0"
                            [palette]="textColorPalette"
                            [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.TEXT_COLOR')"
                            [acceptLabel]="t('COMMON.AUSWAEHLEN')"
                            [cancelLabel]="t('COMMON.ABBRECHEN')">
                </ngx-colors>
                <span class="smaller-abstand-left"
                      [ngClass]="{'error-message': ngForm.submitted && form.controls['textColor'].invalid}">
                    {{ t('PERSONAL.DIENSTKONFIGURATION.TEXT_COLOR') }}
                </span>
            </div>
        </div>
        <h3 class="normal-abstand-top">{{ t('PERSONAL.DIENSTKONFIGURATION.PAUSE') }}</h3>

        <ng-container formArrayName="pausen">
            @for (pause of form.controls.pausen.controls; let index = $index; track pause) {
                <fieldset>
                    <legend class="sr-only">
                        {{ t('PERSONAL.DIENSTKONFIGURATION.PAUSE_LEGEND', {index: index + 1}) }}
                    </legend>
                    <div class="row highlight-input-fields-when-invalid" [formGroup]="pause">
                        <div class="col-md-4">
                            <input type="time"
                                   class="form-control"
                                   formControlName="pauseVon"
                                   [placeholder]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_VON')"
                                   [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_VON')"
                                   [attr.aria-label]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_VON')">
                        </div>
                        <div class="col-md-4">
                            <input type="time"
                                   class="form-control"
                                   formControlName="pauseBis"
                                   [placeholder]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS')"
                                   [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS')"
                                   [attr.aria-label]="t('PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS')">
                        </div>
                        <div class="col-md-2">
                            <div class="checkbox">
                                <label [tooltip]="t('PERSONAL.DIENSTKONFIGURATION.PAID_TOOLTIP')">
                                    <input type="checkbox" formControlName="paid">
                                    <span>{{ t('PERSONAL.DIENSTKONFIGURATION.PAID') }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-2 form-linker">
                            <button type="button"
                                    class="btn btn-link"
                                    (click)="removePause(index)">
                                {{ t('PERSONAL.DIENSTKONFIGURATION.REMOVE_PAUSE') }}
                            </button>
                        </div>
                    </div>
                </fieldset>
            }

            <div class="row">
                <div class="col-md-12">
                    <button type="button"
                            class="btn btn-link"
                            (click)="addPause()">
                        {{ t('PERSONAL.DIENSTKONFIGURATION.ADD_PAUSE') }}
                    </button>
                </div>
            </div>
        </ng-container>

        <dv-submit-cancel-buttons [isLoading]="isLoading" (cancelEvent)="handleCancel()"/>
    </form>
</ng-container>
