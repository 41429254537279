/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    inject,
    input,
    Output,
    signal,
} from '@angular/core';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {Belegung, KindKontakteUtil, RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import {DialogService, SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {DatepickerTextfieldComponent, DialogComponent, handleResponse} from '@dv/shared/angular';
import {KinderService} from '@dv/shared/backend/api/kinder.service';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {RestIncludes} from '@dv/shared/backend/model/rest-includes';
import {DvbRestUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {StateObject, StateService} from '@uirouter/core';
import type moment from 'moment';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {exhaustMap, map, Observable, switchMap} from 'rxjs';
import {BULK_EMAIL_MANAGEMENT_STATE} from 'src/app/communication/communication-states';

@Component({
    selector: 'dv-kind-austritt',
    templateUrl: './kind-austritt.component.html',
    styleUrl: './kind-austritt.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        TranslocoModule,
        DatepickerTextfieldComponent,
        TooltipModule,
        DialogComponent,
        SubmitCancelButtonsComponent,
    ],
})
export class KindAustrittComponent {

    private readonly kinderService = inject(KinderService);
    private readonly errorService = inject(ErrorService);
    private readonly dialogService = inject(DialogService);
    private readonly stateService = inject(StateService);

    public kindId = input.required<EntityId>();
    public belegungen = input.required<Belegung[]>();
    public open = input.required<boolean>();

    // angularjs cannot yet handle the output function
    @Output() public readonly closeDialog = new EventEmitter<void>();

    public state = computed(() => {
        // reset state when open changes
        this.open();

        return {
            austrittsDatum: signal<moment.Moment | null>(null),
            provisorisch: signal<boolean>(false),
            mailBestaetigung: signal<boolean>(false),
            isLoading: signal(false),
        };
    });

    private hauptkontakt$ = toObservable(this.kindId).pipe(
        switchMap(kindId => this.kinderService.getAllRelationshipsWithKontaktpersonen$({
            kindId, kontakte: {includes: new RestIncludes('(kontaktperson,relationship)')},
        })),
        map(k => k.relationshipsWithKontaktpersonen.map(RelationshipWithKontaktperson.apiResponseTransformer)),
        map(kontakte => KindKontakteUtil.findHauptkontaktRelationshipsWithKontaktperson(kontakte)[0].kontaktperson),
    );
    private hauptkontakt = toSignal(this.hauptkontakt$, {initialValue: null});

    public hauptkontaktHasNoEmailAddress = computed(() => (this.hauptkontakt()?.email ?? '').length === 0);

    public submit(form: NgForm): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(!form.controls.austrittsDatum.errors?.required,
            'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(!form.controls.austrittsDatum.errors?.bsDate,
            'ERRORS.ERR_INVALID_DATE');

        if (form.invalid) {
            return;
        }

        this.state().isLoading.set(true);
        if (this.hasBelegungenNachAustritt()) {
            this.dialogService.openConfirmDialog({
                title: 'KIND.KIND_AUSTRITT_CONFIRM_BELEGUNGEN',
                confirmActionText: 'KIND.AUSTRITT',
                confirm: () => this.austrittAction(),
                cancel: () => this.hide(),
            });

            return;
        }

        this.austrittAction().subscribe();
    }

    public hide(): void {
        this.closeDialog.emit();
    }

    private hasBelegungenNachAustritt(): boolean {
        return this.belegungen().some(belegung => belegung.gueltigAb?.isAfter(this.state().austrittsDatum()));
    }

    private austrittAction(): Observable<StateObject> {
        return this.kinderService.austritt$({
            kindId: this.kindId(), jaxAustritt: {
                austrittsDatum: DvbRestUtil.momentToLocalDateChecked(this.state().austrittsDatum()),
                provisorisch: this.state().provisorisch(),
            },
        }).pipe(
            exhaustMap(() => this.state().mailBestaetigung() ?
                this.stateService.go(BULK_EMAIL_MANAGEMENT_STATE.name, {kontaktpersonen: [this.hauptkontakt()]}) :
                this.stateService.reload(),
            ),
            handleResponse({
                next: () => this.hide(),
                finalize: () => {
                    this.state().isLoading.set(false);
                },
            }),
        );
    }
}
