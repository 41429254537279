<form name="planungCopyForm" #planungCopyForm="ngForm" (ngSubmit)="submitForm(planungCopyForm)" novalidate>
    <div class="modal-body">
        <h1 transloco="PERSONAL.ZUWEISUNG.COPY_TITLE"></h1>

        <h3 transloco="PERSONAL.ZUWEISUNG.COPY_SOURCE"></h3>
        <div class="week-pickers small-abstand-top">
            <dvlib-date-switcher [mode]="'week'"
                                 [(date)]="sourceVon"
                                 class="col-sm-6">
            </dvlib-date-switcher>
            <span transloco="COMMON.BIS"></span>
            <dvlib-date-switcher [mode]="'week'"
                                 [(date)]="sourceBis"
                                 class="col-sm-6">
            </dvlib-date-switcher>
        </div>

        <h3 transloco="PERSONAL.ZUWEISUNG.COPY_TARGET" class="big-abstand-top"></h3>
        <div class="week-pickers small-abstand-top">
            <dvlib-date-switcher [mode]="'week'"
                                 [(date)]="targetVon"
                                 class="col-sm-6">
            </dvlib-date-switcher>
            <span class="inline-block" transloco="COMMON.BIS"></span>
            <dvlib-date-switcher [mode]="'week'"
                                 [(date)]="targetBis"
                                 class="col-sm-6">
            </dvlib-date-switcher>
        </div>
    </div>
    <div class="modal-footer big-abstand-top">
        <dv-submit-cancel-buttons [submitLabel]="'PERSONAL.ZUWEISUNG.COPY'"
                                  (cancelEvent)="cancelEdit.emit()"/>
    </div>
</form>

