/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    viewChild,
} from '@angular/core';
import {isPresent} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {UIRouterModule} from '@uirouter/angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {CalendarEvent} from '../../model/CalendarEvent';
import {CalendarGroup} from '../../model/CalendarGroup';
import type {CalendarResizeEvent} from '../../model/CalendarResizeEvent';
import {CalendarResource} from '../../model/CalendarResource';
import {ROW_HEIGHT, TimelineCalendarService} from '../../service/timeline-calendar.service';
import {TimelineEventsComponent} from '../timeline-events/timeline-events.component';
import {TimelineGridComponent} from '../timeline-grid/timeline-grid.component';

@Component({
    selector: 'dv-timeline-calendar-resource',
    imports: [
        CommonModule,
        TimelineGridComponent,
        TimelineEventsComponent,
        UIRouterModule,
        TooltipModule,
        TranslocoModule,
    ],
    templateUrl: './timeline-calendar-resource.component.html',
    styleUrl: './timeline-calendar-resource.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineCalendarResourceComponent implements OnChanges, OnDestroy {
    public readonly timelineCalendarService = inject(TimelineCalendarService);
    private readonly ngZone = inject(NgZone);
    private readonly renderer = inject(Renderer2);

    @Input({required: true}) public group!: CalendarGroup;
    @Input({required: true}) public resource!: CalendarResource;
    @Input() public readonly: boolean = false;
    @Input() public dragMode: boolean = false;
    @Input() public calendarEventWithOpenedEditOverlay?: CalendarEvent;
    @Input() public calendarEventEditOverlay?: TemplateRef<Element>;

    @Output() public readonly resourceDrop: EventEmitter<DragEvent> = new EventEmitter();
    @Output() public readonly resizeEvent: EventEmitter<CalendarResizeEvent> = new EventEmitter();
    @Output() public readonly resizeComplete: EventEmitter<CalendarEvent> = new EventEmitter();
    @Output() public readonly resourceRemove: EventEmitter<void> = new EventEmitter();
    @Output() public readonly deleteEvent: EventEmitter<CalendarEvent> = new EventEmitter();
    @Output() public readonly editEvent: EventEmitter<{
        element: Element;
        event: CalendarEvent;
    }> = new EventEmitter();
    @Output() public readonly closeCalendarEventEditOverlay: EventEmitter<void> = new EventEmitter();

    public timelineElem = viewChild.required<ElementRef>('timeline');

    public timelineHeight = computed(() => {
        const events = this.timelineCalendarService.eventsByGroup();
        const resources = events[this.group.id];
        const layerEvents = isPresent(resources) ? resources[this.resource.id] : [];
        const layerRows = Object.values(layerEvents).flatMap(layer => Object.values(layer).length);

        return `${Math.max(...layerRows) * ROW_HEIGHT}px`;
    });

    private unlistenDragOver?: () => void;

    public ngOnChanges(changes: SimpleChanges): void {
        if (isPresent(changes.dragMode)) {
            if (changes.dragMode.currentValue === true) {
                this.listenToDrag();
            } else if (changes.dragMode.currentValue === false && isPresent(this.unlistenDragOver)) {
                this.unlistenDragOver();
                this.unlistenDragOver = undefined;
            }
        }
    }

    public listenToDrag(): void {
        this.ngZone.runOutsideAngular(() => {
            this.unlistenDragOver =
                this.renderer.listen(this.timelineElem().nativeElement, 'dragover', (event: DragEvent) => {
                    event.stopPropagation();
                    event.preventDefault();
                });
        });
    }

    public ngOnDestroy(): void {
        if (isPresent(this.unlistenDragOver)) {
            this.unlistenDragOver();
        }
    }

    public onResize(event: CalendarResizeEvent, layer: number): void {
        event.groupId = this.group.id;
        event.resourceId = this.resource.id;
        event.layer = layer;
        this.resizeEvent.emit(event);
    }
}
