import type { ITimeRange} from '@dv/shared/code';
import {isNullish, TimeRange, TimeRangeUtil} from '@dv/shared/code';

export function validPause(zuweisungsZeit: ITimeRange, pauseZeiten: ITimeRange[]): boolean {
    if (pauseZeiten.length === 0) {
        return true;
    }

    if (pauseZeiten.some(({von, bis}) => isNullish(von) || isNullish(bis))) {
        return false;
    }

    if (!pauseZeiten.every(pauseZeit => TimeRangeUtil.contains(zuweisungsZeit, pauseZeit))) {
        return false;
    }

    const timeRanges: TimeRange[] = pauseZeiten.map(pause => new TimeRange(pause.von, pause.bis));

    return !TimeRangeUtil.hasOverlappingTimeRanges(timeRanges);
}
