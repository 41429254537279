/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {DvbError, ERROR_CODE, ErrorLevel, ErrorType, hasOwnPropertyGuarded, LogFactory} from '@dv/shared/code';
import {errorToSentry} from '@dv/shared/sentry';
import type {HookResult, TargetState, Transition, TransitionService} from '@uirouter/core';
import {RejectType} from '@uirouter/core';
import type angular from 'angular';
import {map, tap} from 'rxjs';
import type {
    KitAdminAngularJsTranslationInitializer,
} from 'src/app/common/i18n/kitAdminAngularJsTranslationInitializer';
import type {AuthEventService} from '../../authentication/service/auth-event.service';
import type {DvbStateService} from '../../common/service/dvbStateService';
import type {NotificationService} from '../../common/service/notification/notificationService';
import {DASHBOARD_STATE} from '../../dashboard/dashboard-state';
import {authReadinessHook} from './state-hooks/auth-readiness.hook';
import {authenticationHook} from './state-hooks/authentication.hook';
import {authorisationHook} from './state-hooks/authorisation.hook';
import {clearErrorsHook} from './state-hooks/clear-errors.hook';
import {ANY_STATE} from './state-hooks/hook-match-criteria';
import {privacyConsentHook} from './state-hooks/privacy-consent.hook';
import {attemptTokenLoginHook} from './state-hooks/token-login.hook';
import {waitForTranslationsHook} from './state-hooks/translations.hook';

const LOG = LogFactory.createLog('init');

runBlock.$inject = [
    '$window',
    'errorService',
    'dvbStateService',
    '$transitions',
    'kitAdminAngularJsTranslationInitializer',
    'authEventService',
    'notificationService',
];

export function runBlock(
    $window: angular.IWindowService,
    errorService: ErrorService,
    dvbStateService: DvbStateService,
    $transitions: TransitionService,
    kitAdminAngularJsTranslationInitializer: KitAdminAngularJsTranslationInitializer,
    authEventService: AuthEventService,
    notificationService: NotificationService,
): void {

    init();

    function init(): void {
        kitAdminAngularJsTranslationInitializer.init();

        initTransitionHooks();
        registerNotificationListener();

        authEventService.notAuthorised$.pipe(
            map(() => notAuthorizedError()),
            tap(error => errorService.handleError(error)))
            .subscribe();
    }

    function initTransitionHooks(): void {
        waitForTranslationsHook($transitions);
        authReadinessHook($transitions);
        clearErrorsHook($transitions);
        attemptTokenLoginHook($transitions);
        authenticationHook($transitions);
        privacyConsentHook($transitions);
        authorisationHook($transitions);
        $transitions.onSuccess(ANY_STATE, stateChangeSuccess);
        $transitions.onError(ANY_STATE, stateChangeError);
    }

    function stateChangeSuccess(transition: Transition): HookResult {
        dvbStateService.updatePreviousState(transition.from(), transition.from().params);

        if (transition.from() !== transition.to()) {
            // Scroll to the top on every page load when the state changes
            $window.scrollTo(0, 0);
        }
    }

    function stateChangeError(transition: Transition): HookResult {
        const detail = transition.error().detail;
        if (detail && hasOwnPropertyGuarded(detail, 'redirectTo')) {
            const targetState: TargetState = detail.redirectTo;
            transition.router.stateService.go(targetState.name(), targetState.params(), targetState.options());

            return false;
        }

        if (transition.error().type === RejectType.ERROR && !transition.from()?.name) {
            LOG.trace('initial transition failed. Redirecting to dashboard');
            transition.router.stateService.go(DASHBOARD_STATE.name);

            return false;
        }

        if (transition.error().type === RejectType.INVALID) {
            // We want to know when an invalid state change was triggered.
            errorToSentry(new Error(JSON.stringify(transition.error())));
        }
    }

    function notAuthorizedError(): DvbError {
        return new DvbError(ErrorType.BADREQUEST,
            ErrorLevel.SEVERE,
            'ERRORS.ERR_AUTHORIZATION',
            ERROR_CODE.AUTHORIZATION);
    }

    function registerNotificationListener(): void {
        notificationService.addNotificationlistener(message => {
            errorService.handleError(new DvbError(ErrorType.SUCCESS_TRANSLATED, ErrorLevel.PERSISTENT, message, null));
        });
    }
}
