<div class="dvb-kita-faktura-massenaktion-error" *transloco="let t">
    <div class="modal-body normal-abstand-bottom">
        <h1>{{ t('FAKTURA.MASSENAKTIONEN.ERROR_TITLE') }}</h1>
        @for (error of errors; track $index) {
            <div class="small-abstand-bottom">
                <p>
                    <strong>{{ error.kontaktpersonDisplayName }}</strong>,
                    <a [uiSref]="'base.rechnung.uebersicht'"
                       [uiParams]="{rechnungId: error.rechnungsId}">
                        {{ t('FAKTURA.MASSENAKTIONEN.ERROR_RECH_NO', {rechnr: error.rechnungsNummer}) }}
                    </a>
                </p>
                <p>{{ error.errorText }}</p>
            </div>
        }
    </div>

    <div class="modal-footer">
        <dvlib-button-list class="normal-abstand-bottom">
            <button type="button"
                    class="btn btn-link"
                    (click)="hide()">
                {{ t('COMMON.ABBRECHEN') }}
            </button>
        </dvlib-button-list>
    </div>
</div>
