<ng-container *transloco="let t">
    @if (store.kinderOrt(); as kinderOrt) {
        <div class="personalplanung-header" #personalplanungHeader>
            <div class="menu-left">
                <dv-personalplanung-filter
                    [displayMode]="timelineStore.displayMode()"
                    (displayModeChange)="timelineStore.displayMode.set($event)"
                    [selectedDate]="timelineStore.selectedDate()"
                    (selectedDateChange)="timelineStore.selectedDate.set($event)"
                    [fraktionFilter]="store.fraktionFilterOptions()"
                    (filteredFraktionenChange)="store.selectedFraktionen.set($event)"
                    [filterBedarfsRelevanteTermine]="store.filterBedarfsRelevanteTermine()"
                    (filterBedarfsRelevanteTermineChange)="store.filterBedarfsRelevanteTermine.set($event)">
                </dv-personalplanung-filter>
            </div>
            <dvlib-spinner fillSpace [show]="store.isLoading()"/>
            <div class="menu-right">
                @if (!store.readonly()) {
                    <div class="actions">
                        <ng-container *dvlibRequirePermission="'work_time_controlling:kita_convert_planned_to_actual:' + kinderOrt.id">
                            @let lockedUntil = controllingStore.conversionLockedUntil$ | async;
                            <button dvlibButton="link-button"
                                    iconPosition="left"
                                    icon="clock"
                                    [disabled]="lockedUntil !== null"
                                    [tooltip]="lockedUntil === null ? undefined : t('WORK_TIME_CONTROLLING.CONFIG.LOCKED_UNTIL', {date: (lockedUntil! | dvLocalDateFormat)})"
                                    (click)="controllingStore.conversionDialogOpen.set(true)">
                                {{ t('PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING') }}
                            </button>
                        </ng-container>
                        <button dvlibButton="link-button"
                                iconPosition="left"
                                icon="copy"
                                (click)="copyStore.copyDialogOpen.set(true)">
                            {{ t('PERSONAL.ZUWEISUNG.COPY') }}
                        </button>
                        <button dvlibButton="link-button"
                                iconPosition="left"
                                icon="mailOpen"
                                (click)="emailStore.dialogOpen.set(true)">
                            {{ t('PERSONAL.ZUWEISUNG.EMAIL') }}
                        </button>
                        <a href
                           [uiSref]="'base.report.category.personal.personalplanung'"
                           [uiParams]="{gueltigAb: timelineStore.startDate(), gueltigBis: timelineStore.endDate()}">
                            <dvlib-icon iconName="download" class="smaller-abstand-right"></dvlib-icon>
                            <span>{{ t('PERSONAL.ZUWEISUNG.PERSONALPLAN_GENERIEREN') }}</span>
                        </a>
                    </div>
                }
                <dv-zuweisbare-dienste
                    [dienste]="store.dienste()"
                    [readonly]="store.readonly()"
                    (dragActive)="store.dragActive.set($event)">
                </dv-zuweisbare-dienste>
            </div>
        </div>
    }

    <dv-timeline-calendar
        [groups]="store.groups"
        [availableResources]="(store.angestellte$ | async) || []"
        [startDate]="timelineStore.startDate()"
        [endDate]="timelineStore.endDate()"
        [startHour]="store.arbeitszeitVon()"
        [endHour]="store.arbeitszeitBis()"
        [dragMode]="store.dragActive()"
        [layerConfig]="store.timelineLayerConfig()"
        [selectedDate]="timelineStore.selectedDate()"
        [calendarTranslation]="calendarTranslation"
        [showDayInfo]="true"
        [readonly]="store.readonly()"
        [dayInfo]="(store.tagesinfos$ | async) || []"
        [stickyHeaderPos]="calendarStickyTopPos()"
        [scrollBeforeSticky]="calendarScrollBeforeSticky()"
        [calendarEventWithOpenedEditOverlay]="store.editTimesStore.calendarEvent()"
        [calendarEventEditOverlay]="editZuweisungTemplate"
        (closeCalendarEventEditOverlay)="store.editTimesStore.closeOverlay()"
        (sortGroup)="store.sortPersonal($event)"
        (resourceDrop)="store.assignDienstOrTermin($event)"
        (resizeComplete)="store.updateZuweisungOrTermin($event)"
        (resizeEvent)="store.resizing($event)"
        (resourceAdd)="store.resourceAdd($event)"
        (resourceRemove)="store.deleteZuweisungen($event)"
        (deleteEvent)="store.deleteZuweisungOrTermin($event)"
        (editEvent)="store.editZuweisungOrTermin($event)"
        (editDayInfoEvent)="store.editDayInfo($event)"
        (selectDate)="selectDate($event)">
    </dv-timeline-calendar>

    <dvlib-dialog
        [open]="copyStore.copyDialogOpen()"
        (dialogClose)="copyStore.copyDialogOpen.set(false)">
        <ng-template>
            <dv-personalplanung-copy-form
                [kinderOrt]="store.kinderOrt()!"
                [sourceVon]="timelineStore.startDate()"
                [sourceBis]="timelineStore.endDate()"
                (copyZuweisungen)="store.copyZuweisungen($event)"
                (cancelEdit)="copyStore.copyDialogOpen.set(false)"/>
        </ng-template>
    </dvlib-dialog>

    <dvlib-dialog
        [open]="store.copyStore.copyConflictDialogOpen()"
        (dialogClose)="closeConflicDialog()">
        <ng-template>
            <div class="modal-body">
                <h1 [innerHTML]="t('PERSONAL.ZUWEISUNG.COPY_CONFLICT_TITLE')"></h1>
                <h2 [innerHTML]="t('PERSONAL.ZUWEISUNG.COPY_CONFLICT_SUBTITLE')"></h2>
            </div>
            <div class="modal-footer big-abstand-top">
                <dvlib-button-list>
                    <dvlib-loading-button
                        type="submit"
                        [disabled]="copyStore.copyLoading()"
                        [isLoading]="copyStore.copyLoading()"
                        (clickEvent)="store.copyWithConflict(false)">
                        {{ t('PERSONAL.ZUWEISUNG.COPY_CONFLICT_KEEP') }}
                    </dvlib-loading-button>
                    <button
                        type="button"
                        class="btn btn-link"
                        [disabled]="copyStore.copyLoading()"
                        (click)="store.copyWithConflict(true)">
                        {{ t('PERSONAL.ZUWEISUNG.COPY_CONFLICT_DELETE') }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-link"
                        (click)="closeConflicDialog()">
                        {{ t('COMMON.ABBRECHEN') }}
                    </button>
                </dvlib-button-list>
            </div>
        </ng-template>
    </dvlib-dialog>

    <dvlib-dialog [open]="emailStore.dialogOpen()" (dialogClose)="emailStore.dialogOpen.set(false)">
        <ng-template>
            <dv-personalplanung-email-form
                [kinderOrt]="store.kinderOrt()!"
                [gueltigAb]="timelineStore.startDate()"
                [gueltigBis]="timelineStore.endDate()"
                (cancel)="emailStore.dialogOpen.set(false)"/>
        </ng-template>
    </dvlib-dialog>

    <dv-termin-form-dialog
        [termin]="terminStore.newTermin"
        [dialogOpen]="terminStore.terminDialog().open"
        [terminTypes]="terminStore.terminTypes()"
        (save)="terminStore.addOrUpdateTermin($event.termin)"
        (cancelEdit)="terminStore.terminDialog.set({open: false})">
    </dv-termin-form-dialog>

    <dvlib-dialog
        [open]="terminStore.terminUpdateModeDialog().open"
        (dialogClose)="terminStore.closeUpdateModeDialog()">
        <ng-template>
            <dv-termin-update-mode-dialog
                [title]="terminStore.terminUpdateModeDialog().title!"
                [submitLabel]="terminStore.terminUpdateModeDialog().submitLabel"
                (submitMode)="terminStore.submitUpdateMode($event)"
                (cancelEdit)="terminStore.closeUpdateModeDialog()">
            </dv-termin-update-mode-dialog>
        </ng-template>
    </dvlib-dialog>

    <dvlib-dialog
        [open]="terminStore.terminDeleteDialog().open"
        (dialogClose)="terminStore.closeTerminDeleteDialog()">
        <ng-template>
            <dv-termin-delete-dialog
                [dialogData]="terminStore.terminDeleteDialog()"
                (delete)="terminStore.deleteTermin($event)"
                (cancelDelete)="terminStore.closeTerminDeleteDialog()">
            </dv-termin-delete-dialog>
        </ng-template>
    </dvlib-dialog>

    <ng-template #editZuweisungTemplate>
        @if (store.editTimesStore.open()) {
            <dv-zuweisung-edit-time-form
                [event]="store.editTimesStore.calendarEvent()!"
                (updateTimes)="store.updateZuweisungZeitWithTimes($event)"
                (editFinished)="store.editTimesStore.closeOverlay()">
            </dv-zuweisung-edit-time-form>
        }
    </ng-template>

    <dvlib-dialog
        [open]="store.personalSortDialog().open"
        (dialogClose)="store.closePersonalSortDialog()">
        <ng-template>
            @if (store.personalSortDialog().group; as group) {
                <dv-personal-sort-dialog
                    [group]="group"
                    [sortOrder]="store.personalSortDialog().sortOrder!"
                    [angestellte]="(store.angestellte$ | async) || []"
                    (submitOrder)="store.updateSortOrder($event)"
                    (cancelSort)="store.closePersonalSortDialog()">
                </dv-personal-sort-dialog>
            }
        </ng-template>
    </dvlib-dialog>

    <dvlib-confirm-dialog
        [open]="controllingStore.conversionDialogOpen()"
        [title]="t('PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING')"
        [subtitle]="t('PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING_INFO', {range: timelineStore.displayRange()}) + (controllingStore.conversionLimitedInfo$ | async)"
        [isLoading]="controllingStore.conversionIsLoading()"
        [confirmActionText]="t('COMMON.BESTAETIGEN')"
        [cancelActionText]="t('COMMON.ABBRECHEN')"
        (confirm)="controllingStore.convert()"
        (cancelConfirmation)="controllingStore.conversionDialogOpen.set(false)"/>
</ng-container>
